/**
 *  Media queries. Include styles depend on a screen width.
 *
 *  Example:
 *
 *  .you-classname {
 *    @include mq-xs-up {
 *      // put your styles here
 *    }
 *  }
 *
 */
@import "../base/var_legacy";

//xxs
@mixin mq-xxs-down {
  @media (max-width: map-get($breakpoints, xxs-screen) - 1) {
    @content;
  }
}

@mixin mq-xxs {
  @media (min-width : 0) and (max-width: map-get($breakpoints, xxs-screen) - 1 ) {
    @content;
  }
}

@mixin mq-xxs-up {
  @media (min-width : map-get($breakpoints, xxs-screen)) {
    @content;
  }
}


//xs
@mixin mq-xs-down {
  @media (max-width: map-get($breakpoints, xs-screen) - 1) {
    @content;
  }
}

@mixin mq-xs {
  @media (min-width : 0) and (max-width: map-get($breakpoints, xs-screen) - 1 ) {
    @content;
  }
}

@mixin mq-xs-up {
  @media (min-width : map-get($breakpoints, xs-screen)) {
    @content;
  }
}

// sm
@mixin mq-sm-down {
  @media (max-width: map-get($breakpoints, sm-screen) - 1) {
    @content;
  }
}

@mixin mq-sm {
  @media (min-width : map-get($breakpoints, xs-screen)) and (max-width: map-get($breakpoints, sm-screen) - 1 ) {
    @content;
  }
}

@mixin mq-sm-up {
  @media (min-width : map-get($breakpoints, sm-screen)) {
    @content;
  }
}

// md
@mixin mq-md-down {
  @media (max-width: map-get($breakpoints, md-screen) - 1) {
    @content;
  }
}

@mixin mq-md {
  @media (min-width : map-get($breakpoints, sm-screen)) and (max-width: map-get($breakpoints, md-screen) - 1 ) {
    @content;
  }
}

@mixin mq-md-up {
  @media (min-width : map-get($breakpoints, md-screen)) {
    @content;
  }
}

// lg
@mixin mq-lg-down {
  @media (max-width: map-get($breakpoints, lg-screen) - 1) {
    @content;
  }
}

@mixin mq-lg {
  @media (min-width : map-get($breakpoints, md-screen)) and (max-width: map-get($breakpoints, lg-screen) - 1 ) {
    @content;
  }
}

@mixin mq-lg-up {
  @media (min-width : map-get($breakpoints, lg-screen)) {
    @content;
  }
}

// xl
@mixin mq-xl-down {
  @media (max-width: map-get($breakpoints, xl-screen) - 1) {
    @content;
  }
}

@mixin mq-xl {
  @media (min-width : map-get($breakpoints, lg-screen)) and (max-width: map-get($breakpoints, xl-screen) - 1 ) {
    @content;
  }
}

@mixin mq-xl-up {
  @media (min-width : map-get($breakpoints, xl-screen)) {
    @content;
  }
}

// xxl
@mixin mq-xxl-down {
  @media (max-width: map-get($breakpoints, xxl-screen) - 1) {
    @content;
  }
}

@mixin mq-xxl {
  @media (min-width : map-get($breakpoints, xl-screen)) and (max-width: map-get($breakpoints, xxl-screen) - 1 ) {
    @content;
  }
}

@mixin mq-xxl-up {
  @media (min-width : map-get($breakpoints, xxl-screen)) {
    @content;
  }
}

/** Placeholder for inputs
*
*   A mixin to style placeholders in HTML5 form elements.
*
*   Example:
*
*   input {
*     @include placeholder {
*       // styles for placeholder here
*     }
*   }
*
*/
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-moz-placeholder {  /* Firefox 18- */
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin highlight_on_hover($main_color, $hover_color) {
  color: $main_color;

  &:hover, &:focus {
    text-decoration: none;
    text-shadow: none;
    transition: 300ms linear 0s;
    outline: 0 none;
    color: $hover_color;
    background: none;
  }
}

@mixin button-hover($main_color, $hover_color) {
  background: $main_color !important;
  background-color: $main_color !important;

  &:hover, &:focus {
    background: $hover_color !important;
    background-color: $hover_color !important;
  }
}

@mixin highlight-color($color) {
  color: $color;
  transition: 300ms ease-in-out 300ms;
}

@mixin highlight-color-linear($color) {
  color: $color;
  transition: 300ms linear 0s;
  outline: 0 none;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  background: rgba($color, $opacity);
}

@mixin box-shadow() {
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

@mixin highlight_item_on_hover($main_color, $hover_color) {
  background-color: $main_color;

  -o-transition: 500ms ease-out, 100ms ease-in;
  -ms-transition: 500ms ease-out, 100ms ease-in;
  -moz-transition: 500ms ease-out, 100ms ease-in;
  -webkit-transition: 500ms ease-out, 100ms ease-in;
  transition: 500ms ease-out, 100ms ease-in;

  &:hover, &:focus {
    background-color: $hover_color !important;
  }
}

@mixin clearfix {
  &:after {
    content : '';
    display : table;
    clear   : both;
  }
}

@mixin text-crop {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($milsec, $prop) {
  @if $prop {
    transition: $prop #{$milsec}ms ease-in-out;
  } @else {
    transition: all #{$milsec}ms ease-in-out;
  }
}

/**
 * Override for mat-icon-button alike base and hover style of simple non-material buttons.
 * See also the themes for the color overriding.
 */
@mixin five-f-mat-icon-button {
  border: none;
  appearance: none;
  background: transparent;
  cursor: pointer;

  mat-icon {
    color: var(--five-f-icon-button-color);

    &:hover {
      color: var(--five-f-icon-button-color-hover);
    }
  }
}
