@import 'src/lib/styles/fivef/mixins/icon';

/**
 * Mixin for a small button used for example for the comments menu
 * without material mat-icon-button overhead.
 */
@mixin fivef-button-common() {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  color: var(--fivef-color-button-secondary);
}

@mixin fivef-button-s() {
  @include fivef-button-common;

  height: 28px;
  width: 28px;
  padding: 10px;

  .mat-icon {
    @include fivef-icon-s;
  }

  &:hover .mat-icon {
    color: var(--fivef-color-action);
  }
}


@mixin fivef-button-xs() {
  @include fivef-button-common;

  &.mat-mdc-icon-button.mat-mdc-button-base {
    height: 20px;
    width: 20px;
    padding: 1px;
  }

  .mat-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    line-height: 10px;
    padding: 3px 0;
  }

  &:hover .mat-icon {
    color: var(--fivef-color-action);
  }
}

/**
 * Produces an inverted icon button with round background
 * like the add_circle icon. Can be used on mat-icon-button.
 * It shrinks the icon first to place it centered.
 * Examples: responsible dropdown at Collecto table cells.
 *
 * NOTE: This mixin is only intended to be used on tables.
 *       Use regular items at normal views and sidebars.
 * The style is used at material and non-material buttons
 * with icon inside.
 */
@mixin fivef-button-inverted() {
  @include fivef-button-common;

  height: 28px;
  width: 28px;

  // Corrects inverted edit icon inside fivef-item-selector and other
  // components using mat-icon-button in combination with fivef-icon and inverted design.
  // Examples: Responsible selection at Collecto and tasks.
  .fivef-icon {
    padding-left: 5px;
  }

  .mat-icon {
    display: block;
    color: var(--fivef-color-text-inverse);
    background-color: var(--fivef-color-button-secondary);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    font-size: 12px;
    line-height: 20px;
    transition: var(--fivef-color-transition-m);
  }

  &:hover .mat-icon {
    color: var(--fivef-color-action);
  }

  &.mat-mdc-icon-button.mat-mdc-button-base {
    //padding: 10px;

    .mat-icon {
      display: block;
      color: var(--fivef-color-text-inverse);
      background-color: var(--fivef-color-button-secondary);
      border-radius: 50%;
      height: 20px;
      width: 20px;
      font-size: 12px;
      line-height: 20px;
      transition: var(--fivef-color-transition-m);
    }

    &:hover .mat-icon {
      background-color: var(--fivef-color-action);
    }
  }
}

@mixin fivef-button-m() {
  @include fivef-button-common;

  height: 32px;
  width: 32px;

  .mat-icon {
    @include fivef-icon-m;
  }

  &:hover .mat-icon {
    color: var(--fivef-color-action);
  }
}
