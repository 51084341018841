/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
@import "../base/var_legacy";
@import "../base/mixins";

.fivef-file__legacy-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &__name {
    @include text-crop();
    max-width: 100px;
  }

  &__clear {
    cursor: pointer;
    .mat-icon {
      color: map-get($dvtx-color-palette, danger);
      vertical-align: middle;
    }
  }

  .mat-icon {
    font-size: inherit;
    width: auto;
    height: auto;

    &.selected {
      color: $dvtx-green-300;
    }
  }
}
