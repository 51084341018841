@import "src/lib/styles/fivef/mixins/disable_input_hints_wrapper";

/**
 * Filter definitions for the inbox.
 *
 * Aligns all items to the top, allows a wrap of
 * elements and defines paddings.
 */
.fivef-notification-listing-filters--container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: var(--fivef-spacing-s);

  &--filters {
    display: flex;

    // Disable the hint area on the filter inputs.
    @include fivef-disable-input-hints-wrapper();
  }

  &--search {
    display: flex;
    flex-grow: 1;
  }
}
