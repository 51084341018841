/**
 * Migrated styling with 5f prefix.
 */
.fivef-csv-import--expected-structure {
  &__table {
    width: 100%;

    & td {
      border: 1px solid $dvtx-blue-light;
      padding: $content-gap*0.5;
    }
  }
}
