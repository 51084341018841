
/**
 * Sync settings dialog.
 */
.fivef-gt-sync-dialog--selection {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}

.fivef-gt-sync-dialog--stepper {
  margin-right: 2rem;
  border-radius: 50%;
  background-color: rgb(114,87,153);
  font-weight: bold;
  padding: 6px;
  width: 34px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;

  &-disabled {
    background-color: rgb(185,171,204);
  }

  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: white !important;
  }
}
