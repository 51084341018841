// Text truncate
// Requires inline-block or block for proper styling
@mixin fivef-text-truncate() {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ff-truncate {
  @include fivef-text-truncate;
}
