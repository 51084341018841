@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * 5F tree node design.
 * Core concepts: truncation, hover effect, centered alignment, icon size.
 */
.fivef-tree-node {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .fivef-tree-node--content,
  .fivef-tree-node--title {
    color: var(--fivef-color-text-primary);

    @include fivef-text-truncate;
  }

  // Align the content center (flex), set the border and hover
  // give width 100% to allow text truncation.
  // Note: To take effect, the cdk-virtual-scroll-content-wrapper
  //       Has to be set to width 100%.
  // NOTE: Don't put the inner title span to flex or the truncation
  //       will not work.
  .fivef-tree-node--content {
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: var(--fivef-border-radius-s);
    padding: 0 var(--fivef-spacing-xs) 0 var(--fivef-spacing-xxs);
    cursor: pointer;

    &:hover {
      background-color: var(--fivef-color-surface-primary-highlight);
    }
  }

  mat-icon.fivef-tree-node--icon,
  fivef-icon.fivef-tree-node--icon .mat-icon {
    color: var(--fivef-color-text-secondary);
    width: 18px;
    height: 18px;
    font-size: 18px;
    line-height: 18px;
    margin-right: var(--fivef-spacing-xs);

    svg {
      font-size: 18px;
      height: 18px;
      width: 18px;
      line-height: 18px;
    }
  }
}

/**
 * 5F tree container design.
 *
 * The tree is a virtual scrolling container.
 * The width of the wrapper must be set to 100% to allow text truncation in sub elements.
 */
.fivef-tree .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  min-width: 100%;
  width: 100%;
}

.fivef-tree {
  display: block;
  height: 100%;

  &--container {
    height: 100%;
    overflow: auto;
  }

  &--node {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;

    // Padding fix during auto scrolling.
    // Note: The necessary fixed height is given inside the component as input.
    min-height: auto !important;

    // Left arrow for node expansion.
    button.fivef-tree--toggle {
      -webkit-appearance: none;
      border: none;
      background: transparent;
      border-radius: 50%;
      display: flex;
      flex: 0 0 24px;
      width: 24px;
      height: 24px;
      padding: 0;
      margin: 0;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  &--node-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

/**
 * Selection themes.
 */

/**
 * Primary theme with action font color and bold weight.
 */
.fivef-tree--theme-primary .fivef-tree--node--active {
  .fivef-tree-node--content,
  .fivef-tree-node--content:hover {
    mat-icon.fivef-tree-node--icon,
    fivef-icon.fivef-tree-node--icon .mat-icon,
    .fivef-tree-node--title {
      font-weight: 600;
      color: var(--fivef-color-button-primary);
    }
  }
}

/**
 * Background theme with inverted color and rounded background color.
 */
.fivef-tree--theme-background .fivef-tree--node--active {
  .fivef-tree-node--content,
  .fivef-tree-node--content:hover {
    background-color: var(--fivef-color-surface-inverse);
    color: var(--fivef-color-text-inverse);

    mat-icon.fivef-tree-node--icon,
    .fivef-tree-node--title {
      background-color: var(--fivef-color-surface-inverse);
      color: var(--fivef-color-text-inverse);
    }
  }
}
