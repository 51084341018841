/**
 * Link defaults.
 */
a {
  color: var(--fivef-link-color);
  text-decoration: var(--fivef-link-text-decoration);

  cursor: pointer;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;

  // Change color on hover smoothly
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    text-decoration: var(--fivef-link-text-decoration-hover);
    color: var(--fivef-link-color-hover);
  }
}
