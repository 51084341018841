/**
 * Subtext for headlines.
 * Shown below with decent color.
 * e.g. <sub class="ff-sub">...
 */
.ff-sub {
  display: flex;
  font-size: var(--fivef-font-size-s);
  color: var(--fivef-color-text-tertiary);
  margin-top: var(--fivef-spacing-xxs);
  margin-bottom: var(--fivef-spacing-m);
}
