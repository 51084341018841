@import "src/lib/styles/fivef/mixins/truncate";

.fivef-workflow-info {
  display: block;
  margin: var(--fivef-spacing-m) 0;

  &--title,
  &--subtitle {
    @include fivef-text-truncate();
  }

  &--title {
    margin-bottom: 0;
  }

  &--subtitle {
    color: var(--fivef-color-text-tertiary);
  }
}
