.fivef-snackbar {
  animation-name: fivef-snackbar;
  animation-duration: 1200ms;

  .mat-mdc-snack-bar-label {
    color: var(--fivef-notification-color-label) !important;
  }

  a.snackbar-close-button {
    margin-left: 20px;

    &:hover, &:focus {
      text-decoration: none;
      text-shadow: none;
      transition: 300ms linear 0s;
      outline: 0 none;
      color: var(--fivef-color-warn);
      background: none;
    }
  }

  &.fivef-snackbar-success {
    .mdc-snackbar__surface {
      background-color: var(--fivef-notification-color-bg-info) !important;
    }

    .mat-mdc-snack-bar-label {
      color: var(--fivef-notification-color-info) !important;
    }
  }

  &.fivef-snackbar-info {
    .mdc-snackbar__surface {
      background-color: var(--fivef-notification-color-bg-info) !important;
    }

    .mat-mdc-snack-bar-label {
      color: var(--fivef-notification-color-info) !important;
    }
  }

  &.fivef-snackbar-warning {
    .mdc-snackbar__surface {
      background-color: var(--fivef-notification-color-bg-warning) !important;
    }

    .mat-mdc-snack-bar-label {
      color: var(--fivef-notification-color-warning) !important;
    }
  }

  &.fivef-snackbar-error {
    .mdc-snackbar__surface {
      background-color: var(--fivef-notification-color-bg-error) !important;
    }

    .mat-mdc-snack-bar-label {
      color: var(--fivef-notification-color-error) !important;
    }
  }

  &.fivef-snackbar-default {
    .mdc-snackbar__surface {
      background-color: var(--fivef-notification-color-bg-default) !important;
    }

    .mat-mdc-snack-bar-label {
      color: var(--fivef-notification-color-default) !important;
    }
  }
}

/*  Just for testing from snackbar doc.
 * @keyframes fivef-snackbar {
 *   0% { background: #00f; }
 *   10% { background: #8e44ad; }
 *   20% { background: #1abc9c; }
 *   30% { background: #d35400; }
 *   40% { background: #00f; }
 *   50% { background: #34495e; }
 *   60% { background: #00f; }
 *   70% { background: #2980b9; }
 *   80% { background: #f1c40f; }
 *   90% { background: #2980b9; }
 *   100% { background: #0ff; }
 * }
 */

@-webkit-keyframes fivef-snackbar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fivef-snackbar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fivef-snackbar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
