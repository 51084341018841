@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * Global fivef-nav-link configuration.
 *
 * Nav links are mostly used at the navigation sidebar.
 * These definitions add correct width behavior, styling for an alternative vertical
 * icon/text orientation and the right border bar on hover.
 */
.fivef-nav-link-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin: var(--fivef-spacing-xxs) 0;
}

.fivef-nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex: 1 1 100%;
  padding: 0;

  background-position: center;

  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  // transition: background 0s, color 0.2s , border-color 0.2s, letter-spacing 0.2s, background-color 0.2s;

  border-right: 3.5px solid transparent;
  color: var(--fivef-nav-link-color);
  text-decoration: none;

  &--active,
  &--active:active,
  &--active:focus {
    color: var(--fivef-nav-link-color-current);
    border-color: var(--fivef-nav-link-border-color-current);
  }

  &--icon {
    margin: 0 var(--fivef-spacing-xxs);

    mat-icon {
      width: var(--fivef-font-size-xxl);
      height: var(--fivef-font-size-xxl);
      font-size: var(--fivef-font-size-xxl);

      svg {
        width: var(--fivef-font-size-xxl);
        height: var(--fivef-font-size-xxl);
      }
    }
  }

  // Text part of the link.
  &--content {
    font-weight: 400;
    white-space: normal;
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    font-size: var(--fivef-font-size-l);
    text-decoration: none;

    padding: 0 var(--fivef-spacing-xxs);
    // Required to recenter text with larger icon.
    margin-bottom: var(--fivef-spacing-xxs);
  }

  &:hover {
    color: var(--fivef-nav-link-color-hover);
    border-color: var(--fivef-nav-link-border-color-hover);
    text-decoration: none;
  }

  &:active,
  &:focus,
  .fivef-nav-link--active {
    color: var(--fivef-nav-link-color-active);
    border-color: var(--fivef-nav-link-border-color-active);
  }
}

// Vertical button text below design with smaller text.
.fivef-nav-link.fivef-nav-link--horizontal .fivef-nav-link--content {
  @include fivef-text-truncate;

  // Right aligned mat badge e.g. to show task count.
  .mat-badge {
    display: flex;
    align-items: center;
    width: 100%;

    .mat-badge-title {
      width: calc(100% - 22px);
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 8px;
    }

    .mat-badge-content {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin: auto;
      font-weight: 300;
      font-size: 10px;
      text-align: center;
      width: 22px;
      height: 22px;
    }
  }
}

// Vertical button text below design with smaller text.
.fivef-nav-link.fivef-nav-link--vertical {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  .fivef-nav-link--content {
    font-size: var(--fivef-font-size-xs);
  }

  // Centered text below icon.
  span {
    width: auto;
    text-align: center;
  }

  // The badge displaces the text if container is flex instead block.
  .mat-badge {
    display: block;
  }
}
