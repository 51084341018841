$content-indent: 120px;

/**
 * TODO: Task: Remove.
 *       1 What does below code, where it is used for which target?
         2. Refactor to component style.
 *       3 Remove.
 */
.container {
  min-height: 100%;
  padding: ($content-indent * 0.5);
  overflow: hidden;
  @include mq-sm-down {
    padding: $content-indent * 0.125;
  }

  @include mq-sm-up {
    padding: $content-indent * 0.25;
  }

  @include mq-md-up {
    padding: $content-indent * 0.5;
  }
}

.container {
  width: 100%;
  max-width: 1200px;
}

.md-container {
  max-width: 960px;
  margin: 0 auto;
}
