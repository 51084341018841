/**
 * @deprecated.
 *
 * Use new 5F typogrphy setup instead.
 * These are very basic stylings so these are kept in base/
 */
.margin-left-20 {
  margin-left: 20px;
}

.margin-20 {
  margin: 0 20px;
}

// Repeatable patterns
.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

/**
 * 1/3 relative space of outer container. Analogous to w-100.
 */
.w-33 {
  width: 33% !important;
}

/**
 * 1/2 relative space of outer container. Analogous to w-100.
 */
.w-50 {
  width: 50% !important;
}

.dvtx-inline-valign {
  display: inline-flex;
  vertical-align: middle;
}
