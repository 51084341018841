/**
 * Deactivated styles.
 * NOTE: It is not the correct way to restyle mat-toggle to behave like a tab group.
 *       Use a tab group instead ;).
 */
//.mat-button-toggle-group five-f-mat-button-toggle-group ng-valid mat-button-toggle-group-appearance-standard {
//  border-bottom: 1 px solid var(--fivef-color-separater);
//}
//
//.mat-button-toggle-group {
//  width: 100%;
//  margin: var(--fivef-spacing-m) 0;
//  border: none;
//  border-radius: 0;
//  background: var(--fivef-color-surface-primary);
//  border-bottom: 1px solid var(--fivef-color-separater);
//}
//
//
//.cdk-overlay-container {
//  z-index: map-get($z-index-set, z-modal) !important;
//
//  .five-f-sidebar-dialog {
//    .mat-button-toggle-group {
//      position: sticky;
//      z-index: 99999;
//      padding-top: 0;
//      background: var(--fivef-color-surface-primary);
//      border: none;
//      border-bottom: 1px solid var(--fivef-color-separater);
//      border-radius: 0;
//
//      .mat-button-toggle-checked {
//        background-color: transparent;
//      }
//
//      .mat-button-toggle.mat-button-toggle-appearance-standard {
//        background-color: transparent;
//        border: none;
//        border-radius: 0;
//      }
//
//      .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked button {
//        background-color: transparent;
//        border: none;
//        border-bottom: 7px solid var(--fivef-color-text-primary);
//      }
//    }
//  }
//}
//
//.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
//  border-left: none;
//}
