@import 'src/lib/styles/fivef/mixins/menu';

/**
 * Collector status header cell with status overview.
 *
 * NOTE: Styles migrated from problematic list--container class that is overridden any
 *       many places (many duplications).
 */
.fivef-collector-category-stats {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &--button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border: 0;
    outline: none;
    background-color: var(--fivef-color-surface-primary);
  }

  &--status-entity {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;

    .mat-icon {
      font-size: 22px;
      height: 22px;
      width: 22px;
    }

    small {
      font-size: 80%;
      font-weight: bold;
    }
  }

  &--menu {
    // List container migration
    background-color: var(--fivef-color-surface-primary);
    min-width: 240px;
    max-width: 360px;
    overflow: auto;

    @include fivef-menu;
    padding: var(--fivef-spacing-xxs);

    h4 {
      // Align header with bottom cells.
      padding: var(--fivef-spacing-xxs);
      margin: 0;
    }
  }

  &--status-listing {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &--status-list-item {
    padding: var(--fivef-spacing-xxs);
    flex: 0 0 50%;
    display: inline-flex;
  }

  &--status {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--fivef-color-text-inverse);
    cursor: pointer;
    text-align: center;
    padding: 6px;
    position: relative;

    .mat-icon {
      margin-right: var(--fivef-spacing-xxs);
    }

    &:hover {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}
