.fivef-status-selector {
  // Flex display is required for link version of selector to align properly with other content.
  // Do not set width to not break the link version.
  display: flex;
  background-color: transparent;

  // Button integration.
  .fivef-status-selector--button {
    border: 0;
    width: 100%;
    height: 100%;

    // CSS button requirement to center content.
    padding: 0;
    margin: 0;

    color: inherit;
    background: transparent;
  }

  // Link version of selector used at sidebars.
  // To be used if button is not appropriate.
  .fivef-status-selector--link {
    padding: 0.25rem 0;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.42);
    width: 140px;
    height: 30px;
  }

  // Wrapper of all status types and content itself with icon beside text.
  .fivef-status-selector--content-wrapper,
  .fivef-status-selector--content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    flex: 1 1 100%;
  }

  // Content with icon beside text.
  .fivef-status-selector--content span {
    display: flex;
    align-items: center;
    font-size: var(--fivef-font-size-s);
    font-weight: 500;
    text-transform: uppercase;

    mat-icon {
      width: var(--fivef-font-size-xxl);
      height: var(--fivef-font-size-xxl);
      font-size: var(--fivef-font-size-xxl);
      line-height: var(--fivef-font-size-xxl);
      margin-right: var(--fivef-spacing-xxs);
    }

    // Disabled text, e.g. on small width. Remove text margin.
    mat-icon.fivef-status-selector--icon-only {
      margin-right: 0;
    }
  }

  .fivef-status-selector--theme-status {
    color: inherit;
    background: transparent;
  }

  .fivef-status-selector--content {
    color: var(--fivef-color-surface-primary);
  }

  // Inverted color theme: Text has status color, background is transparent.
  // Used at document listing.
  .fivef-status-selector--content.fivef-status-selector--theme-status-inverse {
    font-size: var(--fivef-font-size-s);
    flex-wrap: nowrap;

    .fivef-status-selector--content-title {
      font-size: var(--fivef-font-size-xs);
      font-weight: 400;
      white-space: nowrap;
    }

    .mat-icon {
      font-size: 18px;
    }
  }
}

// Basic menu style. The themed versions below as overrides.
.fivef-status-selector--menu {
  background: white;
  overflow: auto;
  padding: var(--fivef-spacing-xs);

  ul {
    margin: 0;
  }

  li {
    flex: 0 0 50%;
    display: inline-flex;
    padding: var(--fivef-spacing-xxs);

    dvtx-tooltip {
      width: 100%;
    }

    span {
      cursor: pointer;
      width: 100%;
      text-align: center;
      padding: 6px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// Status block item style with background color.
.fivef-status-selector--menu .fivef-status-selector--menu--theme-status {
  min-width: 240px;
  max-width: 360px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  li {
    color: white;

    span {
      padding: 6px;
      position: relative;
      justify-content: center;

      &:hover {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(255, 255, 255, 0.15);
        }
      }

      &.fivef-status-selector--menu-item--active {
        border: 2px solid black;
      }
    }
  }
}

// Priority listing menu style with left border.
.fivef-status-selector--menu .fivef-status-selector--menu--theme-priority {
  max-height: 360px;
  width: 160px;

  li {
    span {
      padding: 6px 20px;
      border-left: 6px solid;

      &.fivef-status-selector--menu-item--active,
      &:hover {
        border-width: 12px;
        padding-left: 14px;
        font-weight: bold;
      }

      mat-icon {
        width: var(--fivef-font-body-m);
        height: var(--fivef-font-body-m);
        font-size: var(--fivef-font-body-m);
        margin-right: 6px;
        line-height: 1;
      }
    }
  }
}
