@import 'src/lib/styles/fivef/mixins/button';
@import 'src/lib/styles/fivef/mixins/menu';

.fivef-labels-selector {
  &--indicator {
    position: absolute;
    top: 3px;
    right: -10px;
    z-index: 999;
    background: var(--fivef-color-indicator-background);
    color: var(--fivef-color-indicator);
    font-size: 9px;
    padding: 4px;
    height: 1.1rem;
    width: 1.1rem;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    border-radius: 50%;
    font-weight: 700;
  }

  &--action-button {
    @include fivef-button-m;
  }

  &--menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 300px;
    overflow: hidden;
    padding: var(--fivef-spacing-xxs) var(--fivef-spacing-s);
  }

  &--title {
  }

  &--search {
  }

  &--listing {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  &--list-item {
    display: flex;
    align-items: center;
  }

  &--list-item--checkbox {
    display: flex;
    align-items: center;

    label {
      margin-bottom: 0;
    }
  }
}
