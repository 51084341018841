/**
 * Active filter bar styling in label look and feel.
 *
 * Usage: INBOX and thirdparty.
 */
.fivef-active-filter-bar {
  display: flex;
  align-items: center;
  min-height: 2rem;

  &--label {
    margin-right: var(--fivef-spacing-xs);
    margin-bottom: 0;
    text-wrap: nowrap;
  }

  > div {
    display: inline-block;
  }

  .fivef-label--container {
    &--item {
      margin-right: var(--fivef-spacing-xs);
    }
  }
}
