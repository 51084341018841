/**
 * 5F icon picker from labels.
 *
 * Refactored from five-f library and unified.
 * TODO: Contains duplicated styles with color picker.
 */
.fivef-icon--container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
}

.fivef-icon-picker--menu {
  margin-top: 42px;
  box-shadow: 0 10px 20px -10px #c9c9c9 !important;
  border-radius: 2px !important;
  max-height: calc(100% - 56px) !important;
  max-width: 100%;
  min-width: 240px;
  padding: 0.25rem 1rem !important;

  .mat-mdc-menu-content {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    max-width: 320px;
    padding-bottom: 1rem !important;

    .fivef-icon-picker--listing {
      margin: 0;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden !important;

      > li {
        height: 40px;
        display: inline-flex;
        align-items: center;

        > span {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba(255, 255, 255, 0.15);
            }
          }
        }
      }
    }
  }
}
