/**
 * @deprecated
 * Former version from vendors/angular-material/components
 + and layout/form
 * Kept for documentation until fully migrated.
 */
//input,
//textarea,
//select {
//  &:-webkit-autofill {
//    -webkit-box-shadow: 0 0 0 300px white inset;
//    transition: none;
//  }
//}
//
//$label-color: $dvtx-fivef-blue-300;
//.label {
//  font-family: inherit;
//  font-size: 1rem;
//  line-height: 1.35;
//  color: $label-color;
//}
//
//// checkbox
//$input-checkbox__border-color: $dvtx-fivef-blue-100;
//$input-checkbox__border-color--active: $dvtx-action;
//$input-checkbox--bgc: #fff;
//
//[type="checkbox"] {
//
//  + label {
//    $dimensions: 20px;
//    height: $dimensions;
//    line-height: $dimensions;
//
//    &:after {
//      top: auto;
//      bottom: 0;
//      background-color: $input-checkbox--bgc !important;
//      border: 1px solid $input-checkbox__border-color !important;
//      border-radius: 2px;
//    }
//
//    &:before {
//      top: auto;
//      bottom: 50%;
//      margin-bottom: 5px;
//    }
//  }
//
//  &:checked + label {
//    &:after {
//      background-color: $input-checkbox__border-color--active !important;
//      border-color: $input-checkbox__border-color--active !important;
//    }
//  }
//}
//
//.btn.btn-primary {
//  background-color: $dvtx-action;
//}
//
