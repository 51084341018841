@mixin fivef-disable-input-hints-wrapper() {
  /**
   * Removes the hints and error indication area of mat-input
   * below the input field.
   */
  .mat-mdc-form-field.mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}
