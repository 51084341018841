/**
 * Folder theme for private folders
 */
.fivef-tree-node--content.fivef-folder-tree-node--theme-private-folder {
  .fivef-tree-node--icon {
    color: var(--fivef-color-surface-private);
  }
}

.fivef-tree-node--content.fivef-folder-tree-node--theme-datev-folder {
  .fivef-tree-node--icon {
    color: var(--fivef-color-datev);
  }
}
