$fivef-container-spacing: var(--fivef-spacing-m);

.fivef-node-settings {
  display: block;
  padding-right: var(--fivef-spacing-m);

  &--title-container {
    margin-bottom: $fivef-container-spacing;
  }

  &--title {
    display: flex;
    align-items: center;

    > div {
      margin-left: var(--fivef-spacing-m);
    }

    &--action {
      display: flex;
      cursor: pointer;
      color: transparent;
      text-shadow: 0 0 0 var(--fivef-color-text-disabled);
      font-size: 0.9rem;

      &:hover {
        text-shadow: 0 0 0 var(--fivef-color-action);
      }

      &--important {
        font-weight: 600;
      }

      &--important-active {
        opacity: 1;
        color: var(--fivef-color-warn);
        text-shadow: unset;
      }

      &--important-low_hanging_fruit {
        opacity: 1;
        color: var(--fivef-color-success-alt);
        text-shadow: unset;
      }

      &--important-active:hover,
      &--important-low_hanging_fruit:hover {
        opacity: 0.7;
      }
    }
  }

  &--status-container {
    display: flex;
    align-items: center;
    margin-bottom: $fivef-container-spacing;

    > div {
      display: flex;
      align-items: center;
      width: 33%;
    }
  }

  &--labels-container {
    margin-bottom: $fivef-container-spacing;
  }

  &--progress-values {
    display: flex;
    margin-bottom: $fivef-container-spacing;

    > div {
      display: flex;
      align-items: center;
      width: 33%;
      padding-right: var(--fivef-spacing-m);
    }
  }

  &--assessment-period {
    display: flex;
    margin-bottom: $fivef-container-spacing;

    &--month {
      width: 200px;
    }

    &--year {
      width: 120px;
      padding-right: var(--fivef-spacing-m);
    }
  }

  &--dates-container {
    display: flex;
    margin-bottom: $fivef-container-spacing;

    > div {
      display: flex;
      flex-direction: column;
      width: 33%;
      padding-right: var(--fivef-spacing-m);
    }
  }
}
