/**
 * Menu item configuration for the quickstart bar at the dashboard.
 */
.mat-mdc-menu-panel.fivef-menu,
.mat-mdc-menu-panel.ff-menu {
  margin-top: 10px;
  max-width: 350px;
  padding: 0 var(--fivef-spacing-xs);
}

/*
 * Context menu layout class for mat-menu.
 * E.g. used at Collecto item and group action menus.
 */
.mat-mdc-menu-panel.ff-context-menu {
  margin-top: 10px;
  max-width: unset;
  padding: 0 var(--fivef-spacing-xs);
}
