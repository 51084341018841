// Text truncate
// Requires inline-block or block for proper styling
@mixin fivef-menu() {
  background-color: var(--fivef-menu-color-surface);
  border-radius: var(--fivef-menu-border-radius);
  box-shadow: var(--fivef-menu-elevation);
  padding: var(--fivef-spacing-xs) var(--fivef-spacing-s);

  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
    overflow-x: hidden;
  }
}

@mixin ff-context-menu() {
  background-color: var(--fivef-menu-color-surface);
  box-shadow: var(--fivef-menu-elevation);
  border-radius: var(--fivef-menu-border-radius);
}
