.five-f-unset-width {
  width: unset;
}

.dvtx-text-icon-button {
  font-weight: 500;
}

.mat-icon {
  overflow: visible !important;
}

.five-f-card {
  @extend .five-f-elevation-z-1;
  border-radius: var(--fivef-border-radius-s) !important;
  padding: 0 !important;
  background: var(--fivef-color-surface-primary);
}

.five-f-icon,
.fife-f-icon {
  &-color-default {
    color: #7b8490;
  }

  &-color-dark {
    color: #384658;
  }
}

.five-f-overlay-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: map-get($z-index-set, z-modal) !important;
}

// Component: menu items
.five-f-button-group {
  width: auto !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  background: none !important;
  display: flex;
  align-items: center;
  height: 36px;
  overflow: unset !important;

  > button {
    height: 36px !important;
    box-shadow: none !important;
  }
}

.five-f-transparent-background {
  background-color: transparent !important;

  .mat-mdc-dialog-container {
    background-color: transparent !important;
  }
}

.five-f-transparent-background-w100-max-width {
  min-height: 80vh;

  background-color: transparent !important;

  .mat-mdc-dialog-container {
    background-color: transparent !important;
    // For testing remove if not used.
    // max-width: 850px !important;
    // margin: auto;
  }
}

.fivef-dialogue-fullScreen {
  .fivef-preview-browser {
    height: 100vh !important;

    .fivef-dialog {
      max-height: unset !important;
    }

    &--document-image-preview .mat-mdc-dialog-content {
      max-height: 88vh !important;
    }

    &--metadata-container {
      max-height: calc(100vh - 48px) !important;
    }

    .mat-mdc-dialog-content {
      max-height: calc(100vh - 64px) !important;
    }
  }
}

.five-f-borderless-editor {
  .fr-box.fr-basic.fr-top .fr-wrapper .fr-element.fr-view {
    border: 1px solid transparent !important;
  }
}

.five-f-editor-no-height {
  .fr-wrapper {
    height: auto !important;
    max-height: unset;
  }
}

.five-f-growing-editor {
  .fr-wrapper {
    min-height: 120px !important;
    height: auto !important;
    max-height: unset;

    .fr-element {
      min-height: 120px !important;
      height: auto !important;
      padding: 10px !important;
    }

    .fr-placeholder {
      padding-top: 10px;
      padding-left: 10px;
    }
  }
}

/**
 * === Example
 * Taken from move documents dialog to show selected result as tag-alike item.
 *
 * <mat-form-field style="width: 400px">
 *   <!-- Show selected process if set including remove button -->
 *   <div class="w-100 d-flex align-items-center justify-content-between five-f-tag-item"
 *        *ngIf="selectedTargetProcess">
 *       <div class="text-truncate">
 *       {{inputText(selectedTargetProcess)}}
 *       </div>
 *     <div class="pl-2">
 *       <button mat-button
 *               mat-icon-button
 *               matSuffix
 *               color="gray"
 *               [disableRipple]="true"
 *               aria-label="Clear"
 *               (click)="resetSearch(''); searchFieldRef.value = ''; $event.stopPropagation()">
 *         <mat-icon>close</mat-icon>
 *       </button>
 *     </div>
 *   </div>
 *   <input matInput ...
 *   ...
 */
.five-f-tag-item {
  position: absolute;
  top: -4px;
  // margin-left: 5px;
  padding: 2px 4px;
  border: 2px solid $dvtx-fivef-blue-100;
  border-radius: 3px;
  background-color: $dvtx-fivef-blue-25;
  font-size: 1.2rem;
  box-shadow: 0 4px 4px -4px #c9c9c9 !important;

  &:hover {
    background-color: $dvtx-fivef-blue-50;
    box-shadow: 0 8px 8px -8px #c9c9c9 !important;
  }

  button {
    line-height: 21px;
    height: 1.5em;
    width: 1.5em;

    mat-icon {
      width: 1em;
      height: 1.125em;
      line-height: 1.125 !important;
      font-size: 14px;
    }
  }
}
