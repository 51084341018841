/**
 * Remove padding in container, e.g. for dialogs.
 * Content can be easier padded use case based.
 */
.mat-stepper-horizontal .mat-horizontal-content-container {
  padding: 0 0 0 var(--fivef-spacing-l);
}

.fivef-mat-stepper {
  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: var(--fivef-color-app-background);
  }

  .mat-horizontal-stepper-header-container {
    background: var(--fivef-color-app-background);
  }

  .mat-horizontal-content-container {
    overflow: visible;
  }

  .mat-stepper-horizontal-line {
    height: 1px;
  }

  .mat-horizontal-stepper-header .mat-step-icon, .mat-horizontal-stepper-header .mat-step-icon-not-touched {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}
