/**
 * Combined styling for Collecto element and group datepicker
 * inside material table (overview).
 */
.fivef-cell-datepicker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  // Hides the input.
  .mat-mdc-input-element {
    height: 0;
    padding: 0 !important;
    visibility: hidden;
    margin: 0 !important;
  }

  &--value-placeholder {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &--toggle {
    position: absolute;

    .mat-mdc-icon-button.mat-mdc-button-base {
      padding: 0;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
      width: 24px;
      height: 24px;
    }
  }

  .mat-icon {
    opacity: 0.9;

    &.fivef-cell-datepicker--button {
      color: #7b8490;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      font-size: 18px;
      line-height: 20px;
      margin-top: 2px;
      margin-bottom: 2px;
      padding: 0;

      &:hover {
        background: var(--fivef-color-action);
        color:white
      }
    }
  }
}

.fivef-item-cell-datepicker {
  .fivef-cell-datepicker--toggle {
    margin-left: 80px;
    margin-bottom: 3px;
  }
}

.fivef-group-cell-datepicker {
  .fivef-cell-datepicker--toggle {
    margin-left: 40px;
  }

  .fivef-cell-datepicker--button-right-spacing {
    margin-right: 40px;
  }
}
