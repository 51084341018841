.fivef-icon-message-block {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  flex-direction: column;

  &--wrapper {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
    padding: 2rem;
  }

  &.ff-py-zero .fivef-icon-message-block--wrapper {
    padding: 0;
  }

  fivef-icon {
    color: var(--fivef-info-color-icon);
  }

  .fivef-icon-message-block--icon mat-icon,
  fivef-icon.fivef-icon-message-block--icon {
    height: 5rem;
    width: 5rem;
    font-size: 5rem;
    padding: 0;
    line-height: 5rem;

    svg {
      height: 5rem;
      width: 5rem;
    }
  }

  h3 {
    color: var(--fivef-info-color-text);
    font-weight: 400;
    margin-top: var(--fivef-spacing-xs);
  }

  &--horizontally {
    flex-direction: row !important;
    padding: 1rem;

    .fivef-icon-message-block--icon mat-icon,
    fivef-icon.fivef-icon-message-block--icon {
      height: 24px;
      width: 24px;
      font-size: 24px;
      line-height: 24px;

      svg {
        height: 24px;
        width: 24px;
      }
    }

    fivef-icon {
      margin-bottom: 0;
    }

    fivef-icon {
      //height: 3.3rem;
      //width: 3rem;
      //font-size: 2.5rem;
      margin-bottom: 0;
      margin-right: var(--fivef-spacing-xxs);
      color: var(--fivef-color-text-disabled);
      padding-bottom: 0;
      display: inline-flex;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.1rem !important;
      color: var(--fivef-color-text-disabled);
    }

    h3.fivef-icon-message-block--headline {
      margin-top: 0;
      font-size: var(--fivef-font-size-xl) !important;
      color: var(--fivef-color-text-disabled);
    }
  }
}
