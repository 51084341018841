/**
 * Headline / content setup used in Third party and other sidebars.
 */
.five-f-details-content-container {
  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__headline {
    margin: 10px 45px 10px 30px;
  }

  &__list-item__property-container {
    display: flex;
    flex: 0 0 170px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &__list-item__property {
    width: 170px;

    &__col-3 {
      width: 234px !important;
    }

    &__col-2 {
      width: 50% !important;
    }

    &__col-1 {
      width: 100% !important;
    }
  }

  label {
    color: rgba(0, 0, 0, 0.54)
  }

  &__element-nav-btn {
    margin-left: auto;
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
  }

  &__headline-title {
    // margin: 5px 45px;
  }

  &__headline-group-title {
    // margin: 10px 45px;
  }

  &__headline-border {
    border-bottom: 2px inset;
  }
}
