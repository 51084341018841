/**
 * 5F input label.
 *
 * Example:
 *    <fivef-input-label [title]='CONTACTS.FIRST_NAME'></fivef-input-label>
 *    <mat-form-field>
 *      <input matInput formControlName="firstName"/>
 *    </mat-form-field>
 */
.fivef-input-label {
  display: flex;
  align-items: center;

  // The min height corresponds to the info icon to keep headlines aligned.
  min-height: 32px;

  font-weight: var(--fivef-font-weight-input-label);
  font-size: var(--fivef-font-size-input-label);
  margin-bottom: var(--fivef-spacing-xxs);
}
