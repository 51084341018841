.fivef-message-panel {
  display: block;

  /**
   * Header area with references.
   */
  &--reference-bar {
    position: relative;
    font-weight: 500;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    mat-icon.fivef-message-panel--collector-reference-icon {
      font-size: 1.3rem;
      width: 1.3rem;
      line-height: 1;
      height: 1.3rem;
      vertical-align: middle;
      margin-bottom: 2px;
      color: var(--fivef-color-text-secondary);
    }

    mat-icon.fivef-message-panel--artifact-reference-icon {
      font-size: 1.3rem;
      width: 1.3rem;
      line-height: 1;
      height: 1.3rem;
      vertical-align: middle;
      margin-bottom: 2px;
      color: var(--fivef-color-text-tertiary);
    }

    span.fivef-message-panel--collector-grouping {
      flex-shrink: 3;
      text-decoration: none;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 250px;
    }

    &--collector-item {
      margin-left: 3px;

      a:before {
        color: var(--fivef-color-text-secondary);
        content: "▸";
        padding-right: 4px;
        position: relative;
        top: -1px;
      }
    }

    &.fivef-message-panel--active-artifact-link:hover {
      color: var(--five-f-link-color-hover);
      cursor: pointer;

      mat-icon {
        color: var(--five-f-link-color-hover);
        cursor: pointer;
      }
    }
  }

  /**
   * 3-dots-menu.
   */
  &--actions-menu {
    display: flex;
  }

  /**
   * Created at beside performer.
   */
  &--created-at {
    font-size: 13px;
    color: var(--fivef-color-text-secondary);
  }

  &--panel {
    display: flex;
    width: 100%;

    &--performer {}

    &--container {
      position: relative;
      width: calc(100% - 40px - 1rem);

      margin-left: 1rem;
      border-radius: 10px;
      box-shadow: var(--fivef-box-shadow-xl) !important;

      &:before {
        content: "";
        position: absolute;
        top: 1rem;
        left: -7px;
        width: 1rem;
        height: 1rem;
        background: white;
        transform: rotate(45deg);
        transform-origin: center center;
        z-index: 99;
      }
    }

    &--inner-wrapper {
      position: relative;
      min-height: 70px;
      background-color: var(--fivef-color-surface-primary);
      border-radius: 10px;
    }

    // header info
    &--main {
      padding: 10px 15px 5px;

      li {
        margin-bottom: 0.5rem;
        word-break: break-word;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .mat-icon {
        height: 1.4rem;
        font-size: 1.4rem;
        width: 1.4rem;
        line-height: 1;
      }
    }
  }

  &--section {
    padding: 5px var(--fivef-spacing-m);
    font-size: 1rem;
    position: relative;

    strong.fivef-message-panel--active-artifact-link:hover {
      color: var(--fivef-color-action);
      cursor: pointer;
    }

    &-title {
      cursor: pointer;
      color: var(--fivef-color-text-tertiary);
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
  }

  &--header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &--left {
      width: 50%;
    }

    &--right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  /**
   * Reply to container above user message.
   */
  &--reply-to {
    border-left: 4px solid var(--fivef-color-text-tertiary);
    padding: 5px 0 5px 10px;
    background-color: var(--fivef-color-surface-tertiary);
    border-radius: 3px;

    &--headline {
      color: var(--fivef-color-text-tertiary);
    }
  }

  /**
   * Seen by overlay at footer.
   */
  &--seen-by-overlay {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-height: calc(100vh - 130px);
  }

  /**
   * Footer area.
   */
  &--footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px var(--fivef-spacing-m);
    background-color: var(--fivef-color-surface-primary);
    border-top: 1px solid var(--fivef-color-separater);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    + .message-comment-area {
      padding: 5px var(--fivef-spacing-m);
    }

    .fivef-message-panel--statistics {
      display: flex;
      align-items: center;

      .mat-icon {
        height: 1.4rem;
        font-size: 1.4rem;
        width: 1.4rem;
        line-height: 1.5rem;
        margin-right: 4px;
      }
    }
  }
}
