@import '../../../../../../node_modules/@angular/material/theming';

/**
 * Color palettes of Grant Thornton.
 *
 * Contrast colors are taken from official light blue theme.
 */
$fivef-color-primary-grant-thornton: (
  50:   #DCD5E5,
  100:  #DCD5E5,
  200:  #B9ABCC,
  300:  #B9ABCC,
  400:  #9581B2,
  500:  #9581B2,
  600:  #9581B2,
  700:  #725799,
  800:  #725799,
  900:  #725799,
  A100: #DCD5E5,
  A200: #B9ABCC,
  A400: #9581B2,
  A700: #725799,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$fivef-color-accent-grant-thornton: (
  50:   #CCEDF0,
  100:  #CCEDF0,
  200:  #99DCE1,
  300:  #99DCE1,
  400:  #66CAD3,
  500:  #66CAD3,
  600:  #33B9C4,
  700:  #33B9C4,
  800:  #33B9C4,
  900:  #33B9C4,
  A100:  #CCEDF0,
  A200:  #99DCE1,
  A400:  #66CAD3,
  A700:  #33B9C4,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$fivef-palette-primary-grant-thornton: mat-palette($fivef-color-primary-grant-thornton, A700);
$fivef-palette-accent-grant-thornton: mat-palette($fivef-color-accent-grant-thornton);
$fivef-palette-warn-grant-thornton: mat-palette($mat-red, A700);

/*
 * Create the light theme.
 * (Sass map containing all of the palettes).
 */
$fivef-theme-grant-thornton: mat-light-theme($fivef-palette-primary-grant-thornton, $fivef-palette-accent-grant-thornton, $fivef-palette-warn-grant-thornton);

@include angular-material-theme($fivef-theme-grant-thornton);

// Former theme setup.
// $primary-grantThorntonTheme: mat-palette($primary-grantThornton);
// $accent-grantThorntonTheme: mat-palette($accent-grantThornton, A200, A100, A400);
// $theme-grantThornton: mat-light-theme($primary-grantThorntonTheme, $accent-grantThorntonTheme);
// $background-color: rgb(229, 225, 221);
// // $theme-link-hover-color: #de002e;
// $theme-link-hover-color: rgb(155, 215, 50);
// $dvtx-action-blue: mat-palette($primary-grantThornton);
// $background: map-get($theme-grantThornton, background);
// $background: map_merge($background, (background: $background-color));
// $theme-grantThornton: map_merge($theme-grantThornton, (background: $background));
// $theme-grantThornton-font-color: rgb(56, 70, 88);
// $theme-grantThornton-default-icon-color: $theme-grantThornton-font-color;
// $theme-grantThornton-gray: #74767a;
// $theme-grantThornton-light-gray: rgb(229, 225, 221);
