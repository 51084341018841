/**
 * 5F Typographie setup.
 *
 * All typography based on rem with 14px base size.
 */
:root {
  /**
   * Example 16px setup.
   * Unused in favor of 14px setup below.
   * Setup 1rem = 16px;
   */
  //--fivef-font-family-display: 'Nunito Sans', sans-serif;
  //--fivef-font-family-body: Inter, serif;
  //--fivef-font-family-code: 'Roboto Mono', monospace;
  //--fivef-font-size-xxs: 0.625rem; // 10px
  //--fivef-font-size-xs: 0.75rem; // 12px
  //--fivef-font-size-s: 0.875rem; // 14px
  //--fivef-font-size-m: 1rem; // 16px
  //--fivef-font-size-l: 1.125rem; // 18px
  //--fivef-font-size-xl: 1.25rem; // 20px
  //--fivef-font-size-xxl: 1.5rem; // 24px

  /**
   * 14px setup.
   * Setup 1rem = 14px; This is the layout before 2024.
   */
  --fivef-font-family-display: 'Roboto', 'Nunito Sans', sans-serif;
  --fivef-font-family-body: Inter, serif;
  --fivef-font-family-code: 'Roboto Mono', monospace;
  --fivef-font-size-xxs: 0.625rem; // 10px
  --fivef-font-size-xs: 0.75rem; // 12px
  --fivef-font-size-s: 0.875rem; // 14px
  --fivef-font-size-m: 1rem; // 14px
  --fivef-font-size-l: 1.142857143rem; // 16px
  --fivef-font-size-xl: 1.285714286rem; // 18px
  // --fivef-font-size-xxl: 1.428571429rem; // 20px
  --fivef-font-size-xxl: 1.714285714rem; // 24px

  // <Tokens>
  // Heading
  --fivef-font-heading-xxs: bold var(--fivef-font-size-xs) var(--fivef-font-family-display); // 12px
  --fivef-font-heading-xs: bold var(--fivef-font-size-m) var(--fivef-font-family-display); // 16px
  --fivef-font-heading-s: bold var(--fivef-font-size-l) var(--fivef-font-family-display); // 18px
  --fivef-font-heading-m: bold var(--fivef-font-size-xl) var(--fivef-font-family-display); // 20px
  --fivef-font-heading-l: bold var(--fivef-font-size-xxl) var(--fivef-font-family-display); // 24px
  --fivef-font-heading-xl: bold var(--fivef-font-size-xxl) var(--fivef-font-family-display); // 24px
  --fivef-font-heading-xxl: bold var(--fivef-font-size-xxl) var(--fivef-font-family-display); // 24px

  // Body
  --fivef-font-body-xs: var(--fivef-font-size-xs) var(--fivef-font-family-body); // 12px
  --fivef-font-body-s: var(--fivef-font-size-s) var(--fivef-font-family-body); // 14px
  --fivef-font-body-m: var(--fivef-font-size-m) var(--fivef-font-family-body); // 16px

  // UI(Buttons, ...)
  --fivef-font-ui-s: medium var(--fivef-font-size-xs) var(--fivef-font-family-body); // 12px
  --fivef-font-ui-s-bold: bold var(--fivef-font-size-xs) var(--fivef-font-family-body); // 12px
  --fivef-font-ui-m: medium var(--fivef-font-size-s) var(--fivef-font-family-body); // 14px
  --fivef-font-ui-m-bold: bold var(--fivef-font-size-s) var(--fivef-font-family-body); // 14px

  // Code
  --fivef-font-code: var(--fivef-font-size-s) var(--fivef-font-family-code); // 14px

  // 5F input label
  --fivef-font-size-input-label: var(--fivef-font-size-m);
  --fivef-font-weight-input-label: 500;

  // </Tokens>
}
