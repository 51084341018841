/**
  * General Font Setup.
  *
  * $grantThornton-typography: mat-typography-config(
  *   $font-family: "Arial, sans-serif",
  * );
  * @include angular-material-typography($grantThornton-typography);
  */
@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Light-Oblique.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Regular-Oblique.ttf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Medium-Oblique.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Bold-Oblique.ttf) format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Bold-Oblique.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Black.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Arial, sans-serif !important;
  src: url(../../../../../assets/fonts/gt_walsheim_lc/GT-Walsheim-LC-Black-Oblique.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
}

*:not(mat-icon) {
  font-family: Arial, sans-serif !important;
}
