/**
 * Used at FAQ section.
 */
.fivef-expansion-panel {
  &.mat-expansion-panel,
  .mat-expansion-panel,
  .mat-expansion-panel-header {
    // expansion panels without white background:
    // Note:
    // The background color is essential: It removes render artifacts because fresh
    // loaded panels are loaded on top.
    background-color: var(--fivef-color-app-background) !important;
    box-shadow: none !important;
  }

  &.mat-expansion-panel:hover,
  .mat-expansion-panel:hover,
  &.mat-expansion-panel:hover .mat-expansion-panel-header,
  .mat-expansion-panel:hover .mat-expansion-panel-header,
  &.mat-expansion-panel:hover .mat-expansion-panel-body,
  .mat-expansion-panel:hover .mat-expansion-panel-body,
  .mat-expansion-panel-header:hover,
  .mat-expansion-panel-body:hover {
    background-color: var(--fivef-color-surface-primary) !important;
    border-radius: var(--fivef-border-radius-s);
  }
}
