// Base size
$base-size: 14px;
$content-gap: 15px;
$base-gap: $content-gap*2;

/* Base Bootstrap breakpoints
*
* Web-sites: v4-alpha.getbootstrap.com, mdbootstrap.com.
*
*/
$breakpoints: (
  xxs-screen: 480px,
  xs-screen: 576px,
  sm-screen: 768px,
  md-screen: 992px,
  lg-screen: 1200px,
  xl-screen: 1366px,
  xxl-screen: 1920px
);

/* Base color pallet
*
* Color scheme based on the UX/UI styleguide made by
* Andreas Wünsche.
*
*/
$dvtx-color-palette: (
  "cool-grey-500": #3c3c3c,
  "cool-grey-450": #4f4f4f,
  "cool-grey-400": #626262,
  "cool-grey-300": #8a8a8a,
  "cool-grey-200": #b1b1b1,
  "cool-grey-100": #d8d8d8,
  "cool-grey-50": #ebebeb,
  "cool-grey-25": #f5f5f5,
  "green-600": #007834,
  "green-500": #009641,
  "green-400": #009641,
  "green-300": #009641,
  "green-200": #99d5b3,
  "green-100": #cbeada,
  "green-50": #009641,
  "fir-green-500": rgb(0, 60, 60),
  "fir-green-450": rgb(25, 80, 80),
  "fir-green-400": rgb(51, 99, 99),
  "fir-green-300": rgb(102, 138, 138),
  "fir-green-200": rgb(164, 174, 183),
  "fir-green-100": rgb(153, 177, 177),
  "fir-green-50": rgb(230, 236, 236),
  "fir-green-25": rgb(242, 245, 245),
  "fivef-blue-600": #3a81c3,
  "fivef-blue-500": #233246,
  "fivef-blue-450": #384658,
  "fivef-blue-400": #4e5a6a,
  "fivef-blue-300": #7b8490,
  "fivef-blue-200": #a7adb5,
  "fivef-blue-100": #d3d6da,
  "fivef-blue-50": #e9eaec,
  "fivef-blue-40": #EFF0F1,
  "fivef-blue-25": #f4f5f6,
  "fivef-skyblue-500": #4b9bb4,
  "fivef-skyblue-450": #5da4bb,
  "fivef-skyblue-400": #6fafc3,
  "fivef-skyblue-300": #93c3d2,
  "fivef-skyblue-200": #b7d7e1,
  "fivef-skyblue-100": #dbebf0,
  "fivef-skyblue-50": #edf5f7,
  "fivef-skyblue-25": #f5fafb,
  "fivef-font-gray-40": #a7adb5,
  "action-blue": #007aff,
  "action-green": #00ca5c,
  "blue-600": #0090cf,
  "blue-200": #99d9f5,
  "blue-100": #ccecfa,
  "blue-50": #e5f5fc,
  "blue-light": #5bc4f1,
  "yellow-500": #ffdc00,
  "yellow-light": #ffe864,
  "danger": #db0000,
  "danger-light": #f7cccc,
  "danger-action": #c50000,
  "warning": #ff8800,
  "warning-light": #ffe7cc,
  "fivef-dark-blue": #243347,
  "fivef-teal": #4db6ac,
  "fivef-teal-highlight": #60e6df,
  "fivef-lime": #9cc766,
  "datev": #90d032,
  "violett": #3f2346,
);

$dvtx-corp-secondary: (
  A500: rgb(103, 147, 197),
  A200: rgb(25, 80, 80),
  B500: rgb(44, 170, 175),
  B200: rgb(171, 221, 223),
  C500: rgb(105, 174, 103),
  C200: rgb(195, 222, 194),
  D500: rgb(153, 103, 128),
  D200: rgb(214, 194, 204),
);

$dvtx-graph-colors: (
  A500: rgb(51, 99, 99),
  B500: rgb(44, 170, 175),
  C500: rgb(234, 164, 232),
);

$dvtx-semantic-colors: (
  'action': map-get($dvtx-color-palette, action-blue),
  'action-light': rgb(230, 246, 253),
  'action-hover': rgb(0, 144, 207),
  'success': rgb(0, 210, 0),
  'success-light': rgb(230, 251, 230),
  'success-hover': rgb(0, 189, 0),
  'danger': rgb(219, 0, 0),
  'danger-light': rgb(248, 204, 204),
  'danger-hover': rgb(197, 0, 0),
  'warning': rgb(255, 136, 0),
  'warning-light': rgb(255, 231, 204),
  'warning-hover': rgb(229, 112, 0),
  'focus-state': rgb(0, 0, 0),
  'background-gray': rgb(230, 236, 236),
  "white-color": #ffffff,
  "black-color": #000000,
);

/* Container's size
*
* Based on the UX/UI styleguide
*
*/
$layout-sizes: (
  sidebar: 300px,
  content: 960px,
  container: 1200px
);

/* Base indents
*
* Indents are based on the UX/UI styleguide and
* useful dimensions
*
*/
$indents: (
  xs: 5px,
  sm: 10px,
  md: 15px,
  lg: 20px,
  xl: 25px
);

/**
 * Create classes for background/foreground coloring.
 *
 * Usage:
 * ... class="dvtx-bg-color dvtx-bg-cool-grey-500" ...
 * ... class="dvtx-fg-color dvtx-fg-cool-grey-500" ...
 */
@each $color_id, $color in $dvtx-color-palette {
  .dvtx-bg-color.dvtx-bg-#{$color_id} {
    background-color: $color !important;
  }

  .dvtx-fg-color.dvtx-fg-#{$color_id} {
    color: $color !important;
  }
}

/* variables */
/* dvtx-colors grey */
$dvtx-cool-grey-500: map-get($dvtx-color-palette, cool-grey-500);
$dvtx-cool-grey-450: map-get($dvtx-color-palette, cool-grey-450);
$dvtx-cool-grey-400: map-get($dvtx-color-palette, cool-grey-400);
$dvtx-cool-grey-300: map-get($dvtx-color-palette, cool-grey-300);
$dvtx-cool-grey-200: map-get($dvtx-color-palette, cool-grey-200);
$dvtx-cool-grey-100: map-get($dvtx-color-palette, cool-grey-100);
$dvtx-cool-grey-50: map-get($dvtx-color-palette, cool-grey-50);
$dvtx-cool-grey-25: map-get($dvtx-color-palette, cool-grey-25);

/* dvtx-colors green */
$dvtx-green-600: map-get($dvtx-color-palette, green-600);
$dvtx-green-500: map-get($dvtx-color-palette, green-500);
$dvtx-green-400: map-get($dvtx-color-palette, green-400);
$dvtx-green-300: map-get($dvtx-color-palette, green-300);
$dvtx-green-200: map-get($dvtx-color-palette, green-200);
$dvtx-green-100: map-get($dvtx-color-palette, green-100);
$dvtx-green-50: map-get($dvtx-color-palette, green-50);

/* dvtx-colors blue */
$dvtx-blue-600: map-get($dvtx-color-palette, blue-600);
$dvtx-blue-200: map-get($dvtx-color-palette, blue-200);
$dvtx-blue-100: map-get($dvtx-color-palette, blue-100);
$dvtx-blue-50: map-get($dvtx-color-palette, blue-50);
$dvtx-blue-light: map-get($dvtx-color-palette, blue-light);

$dvtx-yellow-500: map-get($dvtx-color-palette, yellow-500);
$dvtx-yellow-light: map-get($dvtx-color-palette, yellow-light);

// corporate primary colors
$dvtx-fir-green-500: map-get($dvtx-color-palette, fir-green-500);
$dvtx-fir-green-450: map-get($dvtx-color-palette, fir-green-450);
$dvtx-fir-green-400: map-get($dvtx-color-palette, fir-green-400);
$dvtx-fir-green-300: map-get($dvtx-color-palette, fir-green-300);
$dvtx-fir-green-200: map-get($dvtx-color-palette, fir-green-200);
$dvtx-fir-green-100: map-get($dvtx-color-palette, fir-green-100);
$dvtx-fir-green-50: map-get($dvtx-color-palette, fir-green-50);
$dvtx-fir-green-25: map-get($dvtx-color-palette, fir-green-25);

$dvtx-fivef-blue-600: map-get($dvtx-color-palette, fivef-blue-600);
$dvtx-fivef-blue-500: map-get($dvtx-color-palette, fivef-blue-500);
$dvtx-fivef-blue-450: map-get($dvtx-color-palette, fivef-blue-450);
$dvtx-fivef-blue-400: map-get($dvtx-color-palette, fivef-blue-400);
$dvtx-fivef-blue-300: map-get($dvtx-color-palette, fivef-blue-300);
$dvtx-fivef-blue-200: map-get($dvtx-color-palette, fivef-blue-200);
$dvtx-fivef-blue-100: map-get($dvtx-color-palette, fivef-blue-100);
$dvtx-fivef-blue-50: map-get($dvtx-color-palette, fivef-blue-50);
$dvtx-fivef-blue-40: map-get($dvtx-color-palette, fivef-blue-40);
$dvtx-fivef-blue-25: map-get($dvtx-color-palette, fivef-blue-25);
$dvtx-fivef-skyblue-500: map-get($dvtx-color-palette, fivef-skyblue-500);
$dvtx-fivef-skyblue-450: map-get($dvtx-color-palette, fivef-skyblue-450);
$dvtx-fivef-skyblue-400: map-get($dvtx-color-palette, fivef-skyblue-400);
$dvtx-fivef-skyblue-300: map-get($dvtx-color-palette, fivef-skyblue-300);
$dvtx-fivef-skyblue-200: map-get($dvtx-color-palette, fivef-skyblue-200);
$dvtx-fivef-skyblue-100: map-get($dvtx-color-palette, fivef-skyblue-100);
$dvtx-fivef-skyblue-50: map-get($dvtx-color-palette, fivef-skyblue-50);
$dvtx-fivef-skyblue-25: map-get($dvtx-color-palette, fivef-skyblue-25);
$dvtx-fivef-font-gray-40: map-get($dvtx-color-palette, fivef-font-gray-40);
$dvtx-action-blue: map-get($dvtx-color-palette, action-blue);
$dvtx-action-green: map-get($dvtx-color-palette, action-green);

$dvtx-fivef-dark-blue: map-get($dvtx-color-palette, fivef-dark-blue);
$dvtx-fivef-teal: map-get($dvtx-color-palette, fivef-teal);
$dvtx-fivef-teal-highlight: map-get($dvtx-color-palette, fivef-teal-highlight);
$dvtx-fivef-lime: map-get($dvtx-color-palette, fivef-lime);

// corporate secondary colors
$dvtx-corporate-A500: map-get($dvtx-corp-secondary, A500);
$dvtx-corporate-A200: map-get($dvtx-corp-secondary, A200);
$dvtx-corporate-B500: map-get($dvtx-corp-secondary, B500);
$dvtx-corporate-B200: map-get($dvtx-corp-secondary, B200);
$dvtx-corporate-C500: map-get($dvtx-corp-secondary, C500);
$dvtx-corporate-C200: map-get($dvtx-corp-secondary, C200);
$dvtx-corporate-D500: map-get($dvtx-corp-secondary, C500);
$dvtx-corporate-D200: map-get($dvtx-corp-secondary, C200);

// corporate secondary colors
$dvtx-graph-A500: map-get($dvtx-graph-colors, A500);
$dvtx-graph-B500: map-get($dvtx-graph-colors, B500);
$dvtx-graph-C500: map-get($dvtx-graph-colors, C500);

/* dvtx-colors warn */
$dvtx-action: map-get($dvtx-semantic-colors, action);
$dvtx-action-light: map-get($dvtx-semantic-colors, action-light);
$dvtx-action-hover: map-get($dvtx-semantic-colors, action-hover);
$dvtx-success: map-get($dvtx-semantic-colors, success);
$dvtx-success-light: map-get($dvtx-semantic-colors, success-light);
$dvtx-success-hover: map-get($dvtx-semantic-colors, success-hover);
$dvtx-danger: map-get($dvtx-semantic-colors, danger);
$dvtx-danger-light: map-get($dvtx-semantic-colors, danger-light);
$dvtx-danger-hover: map-get($dvtx-semantic-colors, danger-hover);
$dvtx-warning: map-get($dvtx-semantic-colors, warning);
$dvtx-warning-light: map-get($dvtx-semantic-colors, warning-light);
$dvtx-warning-hover: map-get($dvtx-semantic-colors, warning-hover);

$dvtx-app-bg: map-get($dvtx-color-palette, cool-grey-25);
$dvtx-bg: map-get($dvtx-color-palette, fir-green-25);

$dvtx-white: map-get($dvtx-semantic-colors, white-color);
$dvtx-black: map-get($dvtx-semantic-colors, white-black);
$dvtx-violett: map-get($dvtx-color-palette, violett);

$dvtx-datev: map-get($dvtx-color-palette, datev);

/*  common bg colors */
$bg-colors: (
  bg-warning: $dvtx-warning-light,
  bg-info:$dvtx-action-light,
  bg-success:$dvtx-success-light,
  bg-danger: $dvtx-danger-light,
  bg-primary: $dvtx-bg
);

$z-index-set: (
  z-modal: 100000,
  z-navbar: 10000,
  z-quickstart: 9999,
  z-sidenav-toggler: 800,
  z-sidenav: 700
);



// STATUS COLORS
$allTasks: #3c3c3c;
$newTasks: #fd9013;
$openTasks: map-get($dvtx-color-palette, blue-light);
$overduedTasks: #dc4444;
$closedTasks: #00BD00;

$dvtx-status-sent: #9A9696;
$dvtx-status-open: $newTasks;
$dvtx-status-overdue: $overduedTasks;
$dvtx-status-in-progress: map-get($dvtx-color-palette, blue-light);
$dvtx-status-closed: map-get($dvtx-color-palette, action-green);
