/**
 * Mat toggle buttons override.
 * TODO: Toggle group is currently only used at preview.
 *       Replace this by a tab navigation and remove this override to keep this functionality free
 *       for alternate applications.
 */
.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: unset !important;
  border-bottom: 2px solid var(--fivef-color-action);
  color: var(--fivef-color-action) !important;
  border-radius: 0 !important;
  padding-right: 0 !important;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
  border-radius: 0 !important;
}
