/**
 * Document export dialog.
 */
dvtx-grant-thornton-sync-export-dialog {
  .mat-dialog-actions {
    border-top: 1px solid #d8d8d8 !important;
    flex: 0 0 auto;
    margin: 0!important;
    padding: 1rem 2rem !important;
  }

  .mat-dialog-inner-wrap {
    border-left: 10px solid;

    .mat-checkbox-label {
      text-overflow: unset;
      white-space: normal;
    }

    .dialog-top-icon {
      font-size: 5rem;
      height: auto;
      width: auto;
    }
  }

  .mat-dialog-content-height {
    .mat-dialog-content {
      min-height: calc(100% - 125px);
      max-height: 65vh !important;
    }
  }
}

.dvtx-grant-thornton-sync-export-dialog {
  // Gives the table some boundaries to prevent resizing on search.
  &--document-listing-container {
    width: 100% !important;
    max-width: 800px !important;
    display: block;
  }

  &--progress-container {
    width: 100% !important;
    max-width: 800px !important;
    padding: 0 30px;
  }

  hr {
    margin: 0;
  }

  &--superscript {
    color: var(--fivef-color-text-tertiary);
    padding-left: 1rem;
  }

  &--menu {
  }

  &--headline {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }

  &--icon {
    width: 46px;
  }

  &--container {
    cursor: pointer;
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    flex: 1 1 100%;
    padding: 0 1rem;
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow: hidden;
    gap: 3px;
  }

  &--title,
  &--subtitle {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: 0.9rem;
  }

  &--label {
    margin-bottom: 0 !important;
  }

  &--auto-sync-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem 0 53px;
    color: var(--fivef-color-text-tertiary);
  }

  &--badge {
    padding: 0 7px;
    border-radius: 7px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--channel {
      background-color: rgb(114,87,153);
      color: white;
    }

    &--folder {
      background-color: rgb(185,171,204);
    }
  }
}
