/**
 * Mat table overrides and default values.
 * TODO: Convert to ff-class.
 */
.fivef-mat-table {
  background-color: transparent !important;

  .mat-mdc-table {
    background-color: transparent !important;
    width: 100% !important;
  }

  .mat-mdc-header-row {
    background-color: transparent !important;
  }

  .mat-mdc-header-row,
  .mat-mdc-row {
    border: none;
  }

  .mat-mdc-header-row.mat-mdc-table-sticky {
    top: -40px !important;
    background-color: rgb(245 245 245 / 80%);
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell {
    flex: 1 0 160px;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-mdc-header-cell {
    background: transparent !important;
    border-bottom: none;
    color: var(--fivef-color-text-secondary);
    font-size: var(--fivef-font-size-s);
  }

  .mat-mdc-cell {
    border-bottom-color: var(--fivef-table-border-color);
    border-bottom-width: 1px;
  }

  .mat-mdc-cell:not(:last-child) {
    border-right: 1px solid var(--fivef-table-border-color);
  }

  .mat-mdc-row {
    background: transparent;
    padding: 0;

    .mat-mdc-cell {
      background: var(--fivef-table-cell-bg-color);
    }

    &.active,
    &:hover {
      background: var(--fivef-table-cell-bg-color-hover);

      .mat-mdc-cell {
        background: var(--fivef-table-cell-bg-color-hover) !important;
      }
    }
  }

  table {
    width: 100% !important;

    tr {
      width: 100%;
      height: 54px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    thead {
      background: transparent;

      tr {
        height: 40px !important;
        // Scrollbar at right border
        padding-right: 6px;
      }
    }

    tbody {
      background: transparent;

      tr {
        background-color: white;
        border-bottom: 1px solid var(--fivef-table-border-color);
        height: 55px !important;
        transition: background-color 0.1s ease-in-out;

        &:hover {
          background-color: var(--fivef-table-cell-bg-color-hover);
        }
      }
    }

    th {
      flex: 1;
      background: transparent;
      text-align: center;
      font-size: 0.75rem;
      font-weight: 400;
      color: #797b7b;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    td {
      height: 100%;
      border-top: 1px solid var(--fivef-table-border-color);
      border-left: 1px solid var(--fivef-table-border-color);
      border-right: 1px solid var(--fivef-table-border-color);
      vertical-align: middle;

      padding-left: 5px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .hide-row {
    display: none;
  }

  .cdk-column,
  .five-f-column {
    &-icon,
    &-dropdown {
      flex: 0 0 58px;
      padding: 0 0 0 4px;
      position: relative;
    }

    &-icon {
      .ff-color-bar--default-color {
        background-color: var(--fivef-color-bar-default);
      }

      .ff-color-bar {
        position: absolute;
        width: 4px;
        border-radius: 2px;
        top: 2px;
        left: 2px;
        bottom: 2px;
      }
    }

    &-name,
    &-worker-name,
    &-title,
    &-email,
    &-path {
      //flex: 1 0 400px;
      justify-content: flex-start;
      padding: 0 1rem;
      min-width: 180px;
      flex-grow: 1;
      padding-left: 1rem;
    }

    &-id {
      justify-content: flex-start;
      padding: 0 1rem;
      min-width: 150px;
      max-width: 230px;
      flex-grow: 1;
      padding-left: 1rem;
    }

    &-ident {
      flex: 0 0 150px;
      padding: 0 1rem;
      justify-content: flex-start;
      padding-left: 1rem;
    }

    &-favorite,
    &-notifications {
      flex: 0 0 40px;
      width: 40px;
      justify-content: center;
    }

    &-first-name,
    &-last-name,
    &-username {
      flex: 1 1 200px;
      padding-left: 1rem;
      justify-content: flex-start;
    }

    &-count,
    &-process-count {
      flex: 0 0 80px;
    }

    &-avatar {
      padding-left: 0;
      flex: 0 0 60px;
    }

    &-creator {
      flex: 0 0 80px;
    }

    &-upload,
    &-template {
      flex: 0 0 120px;
      width: 120px;
      padding: 0;
    }

    &-applicable {
      flex: 0 0 95px;
      width: 95px;
      padding: 0;
      justify-content: center;
    }

    &-updated-at,
    &-created-at,
    &-locked-at,
    &-last-sign-in-at,
    &-due-date {
      flex: 0 0 150px;
    }

    &-licence-type {
      flex: 0 0 100px;
    }

    &-actions {
      flex: 0 0 90px;
      padding: 0 0.5rem !important;
      justify-content: flex-end;
      left: unset !important;
      right: 0 !important;
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
    }

    &-invitation-actions {
      flex: 0 0 100px;
      padding: 0 0.5rem !important;
      justify-content: flex-end;
      left: unset !important;
      right: 0 !important;
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
      @supports (-ms-ime-align:auto) {
        position: relative !important;
      }
    }
  }
}

/**
 * Multi type row table setup with upper fivef-mat-table design.
 * Setup taken form DMS Document table.
 */
.fivef-mat-table-multi-row {
  background-color: transparent;

  .mat-mdc-table {
    background-color: transparent;
  }

  table {
    width: 100%;

    td, th {
      padding-left: 20px;
      word-break: break-all;
    }
  }

  .mat-mdc-header-row,
  .mat-mdc-row {
    border: none;
  }

  .mat-mdc-header-row.mat-mdc-table-sticky {
    top: -40px !important;
    background-color: rgb(245 245 245 / 80%);
  }

  .mat-mdc-cell:not(:last-child) {
    border-right: 1px solid var(--fivef-table-border-color);
  }

  .mat-mdc-row {
    background: white;
    // border-left: 4px solid;
    // cursor: pointer;

    .mat-mdc-cell {
      background: white;
    }

    &.active,
    &:hover {
      background: var(--fivef-table-cell-bg-color-hover);

      .mat-mdc-cell {
        background: var(--fivef-table-cell-bg-color-hover);
      }
    }
  }

  .expanded-details {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  tr.collapsed-detail-row {
    height: 0;
  }

  .mat-mdc-row .mat-mdc-cell {
    padding: 0 0 0 15px !important;
  }

  .mat-mdc-header-row .mat-mdc-header-cell {
    padding: 0 0 0 15px !important;
    background-color: transparent;
  }

  .mat-mdc-header-row .mat-mdc-header-cell:first-child {
    padding: 0 0 0 15px !important;
  }

  td.mat-mdc-cell, td.mat-mdc-footer-cell, th.mat-mdc-header-cell {
    padding: 0;
    border-bottom-width: 1px;
    border-bottom-color: var(--fivef-table-border-color);
    border-bottom-style: solid;
  }

  .cdk-column {
    &-type {
      flex: 0 0 120px;
      max-width: 120px;
    }

    &-select {
      flex: 0 0 50px;
      width: 50px;
      align-items: center;
      justify-content: center;
    }
  }
}
