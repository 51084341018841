/**
 * Node dialog stylings.
 * Node dialog is used as Collector sidebar.
 */
.fivef-node-dialog {
  /**
   * Small comment area FIX. Background the tabs do have a top margin that
   * leads to a leak of scrolled comments on top of the editor.
   */
  .fivef-comments--editor-wrapper {
    top: -13px;
  }
}
