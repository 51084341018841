.fivef-login-theme-fivef-blue {
  @import "layout";
  @import "material";
  @import "session";
  @import "session_login";
  @import "session_password_reset";
  @import "session_password_reset_form";
  @import "carousel";
  @import "tfa";

  .fivef-session-registration {
    &--terms-checkbox {
      .mdc-checkbox__background {
        border-color: var(--fivef-color-text-inverse) !important;
      }
    }
  }

  .fivef-session-tfa {
    .mat-step-label:not(.mat-step-label-selected) {
      display: none;
    }

    .mat-stepper-horizontal-line {
      margin: 0 14px;
      border-top-color: #fff;
    }
  }

  .fivef-session-login,
  .fivef-session-tfa,
  .fivef-session-password-reset,
  .fivef-session-password-reset-form,
  .fivef-session-registration {
    // color: var(--fivef-color-surface-primary);
    min-width: unset;
    max-width: unset;

    &--welcome-logo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    &--headline {
      align-self: center;
      text-align: center;
      margin-top: 13px;
      margin-bottom: var(--fivef-spacing-m);
    }
  }
}
