/**
 * Small (mat-)icon setup.
 */
@mixin fivef-icon-s() {
  font-size: 20px;
  width: 20px;
  height: 20px;
}

@mixin fivef-icon-m() {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
