.fivef-color-status-default {
  color: var(--fivef-color-button-secondary);
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
}

.fivef-color-status-default-light {
  color: var(--fivef-color-fivef-blue-500);
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-mdc-icon-button .fivef-color-status-default:hover {
  color: var(--fivef-color-button-secondary-hover);
}

.fivef-color-status-warn,
.fivef-color-status-warning,
.fivef-color-status-open {
  color: var(--fivef-color-status-open) !important;
}

.fivef-color-status-overdue {
  color: var(--fivef-color-overdue) !important;
}

.fivef-color-status-danger,
.fivef-color-status-error {
  color: var(--fivef-color-warn) !important;
}

.fivef-color-status-info {
  color: var(--fivef-color-action) !important;
}

.fivef-color-status-in-progress,
.fivef-color-status-in_progress {
  color: var(--fivef-color-status-in-progress) !important;
}

.fivef-color-status-success,
.fivef-color-status-completed,
.fivef-color-status-closed {
  color: var(--fivef-color-success) !important;
}

.fivef-color-status-disabled {
  color: var(--fivef-color-button-disabled) !important;
}

.fivef-color-status-five-f-teal {
  color: var(--fivef-color-teal) !important;
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
}

.mat-mdc-icon-button .fivef-color-status-five-f-teal:hover {
  color: var(--fivef-color-teal-highlight) !important;
}
