/**
 * Material setup.
 */
@import '../../../../node_modules/@angular/material/theming';
@import "palette_legacy";
@import "var_legacy";

/**
 * Core Material styling import.
 * ATTENTION: Must only be imported once.
 */
@include mat-core();

/**
 * Coloring.
 * Info from documentation:
 * "Define the palettes for your theme using the Material Design palettes available in palette.scss
 *  (imported above). For each palette, you can optionally specify a default, lighter, and darker
 *  hue.
 */
$fivef-app-primary: mat-palette($mat-light-blue, A700);
$fivef-app-primary: map_merge($fivef-app-primary, (default: $dvtx-action-blue));
$fivef-app-accent: mat-palette($fivef-green-palette, A400); // $mat-light-green
$fivef-app-warn: mat-palette($mat-red, A700);

/*
 * Create the light theme.
 * (Sass map containing all of the palettes).
 */
$fivef-app-theme: mat-light-theme($fivef-app-primary, $fivef-app-accent, $fivef-app-warn);

/*
 * Dark theme.
 * Currently not used. Examples:
 *
 * $primary-dark: mat-palette($mat-deep-purple);
 * $accent-dark:  mat-palette($mat-green, A200, A100, A400);
 *
 * // Use mat-dark-theme instead of mat-light-theme
 * $dark-theme: mat-dark-theme($primary-dark, $accent-dark);
 *
 * $primary-indigo: mat-palette($mat-indigo);
 * $accent-indigo: mat-palette($mat-pink, A200, A100, A400);
 * $theme-indigo: mat-light-theme($primary-indigo, $accent-indigo);
 *
 * $primary-purple: mat-palette($mat-deep-purple);
 * $accent-purple: mat-palette($mat-amber, A200, A100, A400);
 * $theme-purple: mat-light-theme($primary-purple, $accent-purple);
 */

/**
 * Finally create theme.
 *
 * Info from documentation:
 * "Includes theme styles for core and each component used in your app.
 *  Alternatively, you can import and @include the theme mixins for each component
 *  that you are using."
 */
@include angular-material-theme($fivef-app-theme);
