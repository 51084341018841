.fivef-notification-bell {
  width: 40px;

  mat-icon {
    font-size: 30px !important;
    height: 30px !important;
    width: 30px !important;
  }

  .mat-badge-content {
    position: absolute;
    background: var(--fivef-indicator-background-color-unread);
    color: var(--fivef-indicator-color-unread);
    font-size: 10px;
    letter-spacing: -0.5px;
    line-height: 1;
    font-weight: normal;
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    top: auto !important;
    bottom: -2px !important;
    right: -2px !important;
    width: 14px;
    height: 14px;
    padding: 2px;
  }
}
