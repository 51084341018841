/**
 * Footer with action buttons for menus, sidebars and dialogs.
 */
@mixin fivef-action-footer() {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;

  // The min-height ensures a more centered padded content
  // even if footer is unused.
  min-height: 32px;

  // Keeps small padding below buttons if available.
  padding: var(--fivef-spacing-xs) 0;

  button {
    // Button row spacing.
    margin-left: var(--fivef-spacing-m);
  }
}
