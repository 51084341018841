/**
 * Fivef session container default.
 */
.fivef-session {
  /**
   * Material outline form field override for smaller appearance.
   */
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  /**
   * Mat-label default: On top of all inputs but slightly indented for smoother layout.
   */
  &--label {
    padding: var(--fivef-spacing-xs);
  }

  /**
   * Page container setup containing the main and header araa.
   */
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /**
   * Alternative language switcher below login area.
   * Used at themes, deactivated by default.
   */
  &--alternative-language-switcher {
    display: none;
  }

  /**
   * Secondary and alternative logo.
   * Only available in themes, deactivated by default.
   */
  &--logo-header {
    display: none;
  }

  &--content {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  /**
   * Carousel of login themes.
   * Deactivated by default.
   */
  &--carousel {
    display: none;
  }

  &--footer {
    min-width: 500px;
    max-width: 620px;
  }
}
