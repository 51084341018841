/**
 * Custom CSS reboot mainly taken of removed MDB code to reestablish
 * a sane state.
 *
 * This file is a migration path to remove MDB and bootstrap.
 * Migrated components can be removed from mdb.scss and bootstrap.scss inside mdb/.
 */

/**
 * Default button setup.
 */
button:focus {
  outline: none !important;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: none;

  // Disabled to avoid !important overrides. Most used non-material buttons have a border or
  // are entirely overridden to be invisible as native buttons.
  // border: 0;

  outline: none;
}

/**
 * Resets mdb bootstrap margin default which displaces mat-checkbox.
 */
label {
  margin-bottom: unset;
}

/**
 * a-tag/Link.
 */
a {
  // color: $link-color;
  text-decoration: none;
  cursor: pointer;

  // Gets rid of tap active state
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

ul {
  padding: 0;
  list-style-type: none;
  li {
    list-style-type: none;
  }
}

figure {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: unset;
}

section {
  width: 100%;
}

strong {
  white-space: normal;
}

sub {
  color: var(--fivef-color-text-tertiary);
  font-size: var(--fivef-font-size-xs) !important;
}
