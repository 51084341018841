.fivef-datepicker {
  display: flex;
  overflow: hidden;

  &--form-field {
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
  }

  /**
   * Table cell styling. Requires a static width of 150px.
   */
  &--theme-table-cell {
    width: 140px;
  }

  .mat-mdc-input-element {
    padding-top: 1px;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
    padding-left: 10px !important;
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 !important;
    display: flex !important;
  }
}
