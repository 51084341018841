
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.five-f-mat-tab,
.five-f-mat-button-toggle-group {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  mat-button-toggle {
    margin-right: 15px;
    padding: 0 !important;
    min-width: 0 !important;
    height: 48px;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: top;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  a.mat-mdc-tab-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dvtx-fivef-blue-300;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0 !important;
    min-width: 0 !important;
    &:first-child {
      margin-left: 0;
    }

    .mat-icon {
      margin-right: 10px;
    }

    &:active, &:visited, &:focus {
      color: $dvtx-fivef-blue-500;
    }
  }

  .mat-mdc-tab-nav-bar {
    padding: 0 15px;
    border-bottom: 0;
  }

  .mat-mdc-tab-nav-bar.mat-primary {
    background-color: transparent;
  }

  &.mat-mdc-tab-nav-bar.mat-primary,
  &.mat-tab-nav-bar.mat-primary {
    background-color: transparent !important;
  }

  .mat-mdc-tab-group ::ng-deep .mat-mdc-tab-header {
    background-color: transparent !important;
    padding-bottom: 0;
  }

  &.mat-mdc-tab-group ::ng-deep .mat-mdc-tab-header,
  &.mat-tab-group ::ng-deep .mat-tab-header {
    background-color: transparent !important;
  }

  .mat-mdc-tab-group .mat-mdc-tab-header {
    background-color: transparent !important;
    padding-bottom: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label {
      padding: 0;
      margin-left: 15px;
      margin-right: 15px;

      &:first-child {
        margin-left: 0;
      }
    }

    &.mat-mdc-tab-group .mat-mdc-tab-header,
    &.mat-tab-group .mat-tab-header {
      background-color: transparent !important;
    }

    .mat-ink-bar {
      background-color: $dvtx-fivef-blue-500; // $dvtx-action;
    }
  }

  .mat-mdc-tab-group.mat-primary .mat-ink-bar,
  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $dvtx-fivef-blue-500; // $dvtx-action;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label,
  .mat-mdc-tab-link {
    &.cdk-program-focused {
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      &:not(.mat-tab-disabled) {
        background-color:transparent !important;
      }
    }
  }
}

/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//.mat-mdc-tab-header {
//  padding: $content-gap;
//  border-bottom: none;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
//.mat-tab-label {
//  font-size: 1rem;
//  min-width: initial !important;
//
//  &:first-child {
//    padding-left: 0;
//  }
//}
//
//.mat-ink-bar {
//  height: 4px !important;
//}
//
///* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
//.mat-tab-label-active {
//  opacity: 1 !important;
//}
//
//
///* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
//.mat-tab-body-content {
//  transform: none !important;
//}
//
//.mat-mdc-form-field {
//  display: block !important;
//}
//
//.contact-form {
//  .mat-mdc-tab-header {
//    padding: 0 24px;
//    background-color: $dvtx-bg;
//  }
//
//  .mat-mdc-tab-body {
//    padding: 0;
//  }
//
//  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
//  .mat-tab-body-content {
//    overflow-x: hidden;
//  }
//}

