.fivef-comments {
  /**
   * Keeps editor at the top sticky.
   * Contains a slightly transparent background effect to let comments scroll by
   * in a visible way.
   */
  &--editor-wrapper {
    position: sticky;
    top: 0;
    z-index: 100000;
    background: var(--fivef-color-surface-primary-transparent);
  }

  &--comment-listing {
    margin-top: var(--fivef-spacing-m);
    margin-bottom: var(--fivef-spacing-m);
  }

  &--comment-item {
    padding: 0 var(--fivef-spacing-l) var(--fivef-spacing-xl) 0;
  }
}
