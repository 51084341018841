.fivef-session-password-reset {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-width: 500px;
  max-width: 620px;
  padding-top: 8rem;

  // TODO: This is not the correct link variable!
  a {
    color: var(--five-f-link-color);
  }

  a:hover {
    color: var(--five-f-link-color-hover);
  }

  &--form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /**
   * Title.
   */
  &--headline {
    align-self: center;
    margin-bottom: 4rem;
  }

  /**
   * Request send button area.
   */
  &--button-container {
    display: flex;
    justify-content: end;

    .fivef-session-password-reset--button {
      width: 40%;
      height: 38px !important;
    }
  }

  /**
   * Back link to login page.
   */
  &--back-link {
    // Indents the back link for more harmonic layout.
    margin: 0 var(--fivef-spacing-xs);
  }
}
