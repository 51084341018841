///* Material dialogs override.
// *
// * Author: Abdallah Salem <a.salem@5fsoftware.de>
// * @import "./src/assets/styles/override/mat_dialog";
// */
//
//@import "../utils/var";
//@import "../utils/mixins";
//
//:host {
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
// */
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
// */
//  .mat-dialog-inner-wrapper {
//    display: block;
//    width: 900px;
//
//    //&.dynamic-width) {
//    //  width: auto;
//    //}
//
//    max-width: 100%;
//    ::ng-deep {
//      .mat-mdc-dialog-container {
//        display: inline;
//      }
//    }
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
// */
//  .mat-dialog-inner-wrapper.mat-dialog-dynamic-width {
//    width: auto;
//  }
//}
//
//::ng-deep {
//  .mat-mdc-dialog {
//    &-header {
//      padding: 1rem 2rem !important;
//      padding-right: calc(2rem + 40px) !important;
//      height: 40px;
//      box-sizing: content-box;
//      display: flex;
//      align-items: center;
//      background: $dvtx-cool-grey-25;
//      .mat-mdc-dialog-title {
//        font-size: 1.15rem !important;
//        font-weight: 400;
//      }
//      /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
// */
//      .mat-dialog-close {
//        top: 1rem !important;
//        right: 1rem !important;
//      }
//    }
//
//    &-content {
//      .mat-expansion-panel {
//        box-shadow: none !important;
//        padding: 0 !important;
//        &:not(:first-child) {
//          border-top: 1px solid $dvtx-cool-grey-100;
//        }
//        &-header {
//          &[aria-disabled="true"] {
//            color: inherit;
//          }
//        }
//        &-header {
//          position: relative;
//          &:hover {
//            background: none !important;
//          }
//          .mat-expansion-indicator {
//            position: absolute;
//            left: 0px;
//            top: 0;
//            bottom: 0;
//            height: 24px;
//            margin: auto;
//          }
//        }
//      }
//    }
//
//    &-actions {
//      border-top: 1px solid $dvtx-cool-grey-100 !important;
//      flex: 0 0 auto;
//      margin: 0 !important;
//      padding: 1rem 2rem !important;
//    }
//  }
//}
//
///**
// * Classes to style the left dialog border.
// *
// * Usage:
// * SCSS:
// *     @import "./src/lib/styles/base/var_legacy";
// *     @import "./src/lib/styles/base/mixins";
// *     @import "./src/lib/styles/override/mat_dialog";
// * Dialog:
// * <div class="mat-dialog-inner-wrap">
// *   <h2 class="mat-dialog-header">
// *     <h2 mat-dialog-title>...</h2>
// *   </h2>
// *
// *   <div mat-dialog-content>
// *   // ...
// *   </div>
// *
// *   <div class="mat-dialog-footer d-flex justify-content-end">
// *     <div class="d-flex justify-content-end w-100">
// *       // ...
// *     </div>
// *   </div>
// * </div>
// * Component:
// * Example variant 1:
// * openDialog() {
// *   this._dialog.open(...DialogComponent, {
// *     panelClass: ['dialog-lg', 'five-f-dialog-border-color'],
// *     disableClose: true,
// *     data: {
// *       process: this._process
// *     }
// *   });
// * }
// * Example variant 2:
// * openDialog() {
// *   this._dialog.open(...DialogComponent, {
// *     panelClass: ['dialog-lg', 'five-f-dialog-border-color', 'five-f-dialog-border-primary'],
// //*     disableClose: true,
// *     data: {
// *       process: this._process
// *     }
// *   });
// * }
// */
//::ng-deep .five-f-dialog-border-color {
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
// */
//  .mat-dialog-inner-wrap {
//    border-left: 10px solid;
//    border-left-color: $dvtx-fivef-blue-500;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
// */
//  &-primary .mat-dialog-inner-wrap {
//    border-left-color: $dvtx-action-blue !important;
//  }
//}

/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//@import "../../../utils/var";
//@import "../../../utils/mixins";
//
//// .cdk-global-overlay-wrapper {
////   @media (min-width: 960px)  {
////     padding: 0 60px;
////   }
//// }
//
//.mat-mdc-dialog-container {
//  position: relative;
//  margin: 0 auto;
//  padding: 0!important;
//  width: 100%;
//  min-width: 80vw;
//
//  @media (min-width: 768px)  {
//    min-width: 600px;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
//  .mat-dialog-header {
//    position: relative;
//    padding: 2rem 4.286rem 2rem 2rem;
//    background-color: white !important;
//
//    .step-wrap {
//      margin: 2.143rem 0 0 0;
//      padding: 0;
//    }
//  }
//
//  .mat-mdc-dialog-title {
//    margin-bottom: 0;
//    font-size: 1.5rem;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
//  .mat-dialog-close {
//    background-color: $dvtx-bg;
//    position: absolute;
//    top: 10px;
//    right: 10px;
//    color:$dvtx-cool-grey-500;
//    &:hover {
//      background-color: $dvtx-danger;
//      color:white;
//    }
//  }
//
//  .mat-mdc-dialog-content {
//    margin: 0;
//    padding: 2rem;
//
//    .fa-exclamation-circle {
//      font-size: 1.5rem;
//    }
//
//    .mat-mdc-dialog-actions:last-child {
//      margin-bottom: 0;
//    }
//  }
//
//  .mat-mdc-list-item {
//    display: block;
//    border-bottom: 1px solid $dvtx-fivef-blue-25;
//
//    a {
//      display: block;
//      padding: $content-gap 2rem;
//      background-color: $dvtx-white;
//
//      &:hover {
//        background: $dvtx-bg;
//      }
//    }
//
//    h3 {
//      font-weight: 500;
//    }
//
//    &:last-child {
//      border-bottom: none;
//    }
//
//    *:last-child {
//      margin-bottom: 0;
//    }
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
//  .mat-dialog-footer {
//    padding: 0.7143rem 2rem;
//
//    .mat-mdc-dialog-actions {
//      margin-bottom: 0;
//    }
//
//    .btn {
//      flex: 0 1 240px;
//    }
//  }
//
//  .mat-mdc-list {
//    .mat-mdc-list-item {
//      font-size: 1rem;
//      padding: 14px;
//      border-bottom: 1px solid $dvtx-fivef-blue-25;
//      &.active {
//        background-color: $dvtx-bg;
//        color: $dvtx-action;
//      }
//    }
//    .mat-item-icon {
//      position: absolute;
//      top: 0;
//      &.mat-Suffix {
//        right: -0.5em;
//      }
//      &.mat-Auffix {
//        left: 0;
//      }
//
//    }
//  }
//}
//
//.dialog-sm {
//  width: 500px !important;
//
//  .mat-mdc-dialog-container {
//    min-width: inherit;
//  }
//
//  @media (min-width: 768px)  {
//    min-width: 500px;
//  }
//}
//
//.dialog-md {
//  width: 640px !important;
//
//  .mat-mdc-dialog-container {
//    min-width: inherit;
//  }
//}
//
//.dialog-lg {
//  width: 780px !important;
//
//  .mat-mdc-dialog-container {
//    min-width: inherit;
//  }
//}
//
//.dialog-xl {
//  width: 960px !important;
//
//  .mat-mdc-dialog-container {
//    min-width: inherit;
//  }
//}
//
//.dialog-confirmation {
//  /* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.*/
//  .mat-dialog-footer .btn {
//    flex: 0 1 180px!important;
//  }
//
//}
//
//.dialog-contact {
//
//  .mat-mdc-dialog-content {
//    padding: 24px;
//  }
//}

