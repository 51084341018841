/**
 * Icon input layout from master-data form.
 */
.ff-icon-input {
  display: flex;
  align-items: center;
  padding: var(--fivef-spacing-s) 0;

  > mat-icon {
    color: var(--fivef-color-text-tertiary);
  }

  /**
   * Standard icon passing for direct form fields or elements with
   * ff-icon-input--content class.
   */
  .ff-icon-input--content,
  > .mat-mdc-form-field {
    display: flex;
    width: 100%;
    margin: 0 var(--fivef-spacing-m);
  }

  /**
   * Icon context class for further paragraph below icon setup
   * with same indentation.
   */
  &--indent-paragraph {
    margin: 0 var(--fivef-spacing-m) 0 38px;
  }
}

/**
 * Info icon with themes based color, e.g. for info tooltip beside elements.
 */
.ff-info-icon {
  color: var(--fivef-info-color-icon);
}

/**
 * Styling for icon form input layouts.
 * See CreateEmployeeDialogComponent for an example of usage
 * (employee creation dialog inside employee table view).
 * Refactoring from CreateEmployeeDialogComponent,
 * MembershipInvitationComponent.
 */
.ff-icon-content {
  display: flex;
  margin-bottom: var(--fivef-spacing-s);

  & > .mat-icon {
    width: 36px;
  }

  .row {
    width: 100%;
    padding-left: 36px;

    [class^=col-] {
      box-sizing: border-box;
    }
  }

  & > mat-icon {
    &:not(:empty) {
      margin-top: var(--fivef-spacing-xxs);

      // Align well with checkboxes and material padding.
      margin-left: var(--fivef-spacing-xs);
    }

    flex: 0 0 auto;
    color: var(--fivef-color-text-tertiary);
    margin-right: var(--fivef-spacing-xs);
  }

  > .ff-icon-content--content {
    display: flex;
    flex: 1 1 auto;
    min-width: auto;
    gap: var(--fivef-spacing-s);
  }
}

/**
 * Variant with larger icon spacing to align better
 * in the middle of inputs.
 */
.ff-icon-content.ff-icon-content--floating-labels {
  & > mat-icon {
    &:not(:empty) {
      margin-top: var(--fivef-spacing-m);
    }
  }
}
