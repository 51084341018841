.ff-drag-n-drop {
  background-color: var(--fivef-upload-background-color);
  border: 1px dashed var(--fivef-upload-border-color);
  width: 100%;
  text-align: center;
  transition: var(--fivef-color-transition-m);

  &:hover {
    background-color: var(--fivef-upload-background-color-hover);
    border: 1px solid var(--fivef-upload-border-color-hover);
    color: var(--fivef-upload-color-hover);

    mat-icon {
      color: var(--fivef-upload-color-hover);
    }
  }

  &--icon {
    // font-size: $base-size * 3;
    color: var(--fivef-color-text-secondary);

    .mat-icon {
      font-size: inherit;
      width: auto;
      height: auto;
    }
  }

  &--button {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    button {
      border: 1px solid var(--fivef-upload-button-border-color);
      // margin-bottom: $content-gap*0.5;
      white-space: normal;
    }

    p {
      font-size: 1.286rem;
      color: var(--fivef-upload-button-color);
    }
  }

  &--lock {
    background-color: transparent;
    border: 1px solid transparent;
    width: 100%;
    text-align: center;
  }
}

