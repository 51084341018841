.fivef-icon {
  display: flex;

  // Displaces tabler icons. Seems not to have any side effects.
  // align-items: center;

  color: var(--five-f-icon-button-color);

  &--xs {
    mat-icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
      line-height: 18px;

      & svg {
        font-size: 18px;
        height: 18px;
        width: 18px;
        line-height: 18px;
      }
    }
  }

  &--sm {
    mat-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
      line-height: 20px;

      svg {
        font-size: 20px;
        height: 20px;
        width: 20px;
        line-height: 20px;
      }
    }
  }


  &--md {
    mat-icon {
      font-size: 24px;
      height: 24px;
      width: 24px;
      line-height: 24px;

      svg {
        font-size: 24px;
        height: 24px;
        width: 24px;
        line-height: 24px;
      }
    }
  }

  &--lg {
    mat-icon {
      font-size: 32px;
      height: 32px;
      width: 32px;
      line-height: 32px;

      svg {
        font-size: 32px;
        height: 32px;
        width: 32px;
        line-height: 32px;
      }
    }
  }

  &--xl {
    mat-icon {
      font-size: 48px;
      height: 48px;
      width: 48px;
      line-height: 48px;

      svg {
        font-size: 48px;
        height: 48px;
        width: 48px;
        line-height: 48px;
      }
    }
  }
}

.mat-mdc-icon-button {
  .fivef-icon {
    &--xs {
      padding-left: 7px;
    }

    &--sm {
      padding-left: 5px;
    }
  }

  &:hover {
    color: var(--fivef-color-action);
  }
}
