.fivef-login-theme-fivef-default {
  @import "session";
  @import "carousel";
  @import "registration";
  @import "tfa";


  // TODO: Why do we have this sort of override?
  //       It makes it again hard to find where something is defined and it is not generic.
  .fivef-session-login,
  .fivef-session-tfa,
  .fivef-session-reset,
  .fivef-session-reset-form,
  .fivef-session-registration {
    display: flex;
    padding: 14px;
    flex-direction: column;
    width: 100%;
    align-items: center;
    max-width: 620px;

    &--email-button-inside-textfield {
      display: none;
    }

    &--form {
      display: flex;
      flex-direction: column;
      width: 80%;
    }

    &--welcome-logo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    &--logo {
      max-width: 100%;
      max-height: 92vh;
      min-height: 92vh;
      width: 100vh;
      background-size: contain;
      background-repeat: no-repeat;
      zoom: 4%;
      align-self: center;
    }

    &--headline {
      align-self: center;
      text-align: center;
      margin-top: 13px;
      margin-bottom: var(--fivef-spacing-m);
    }

    &--message {
      align-self: center;
      margin-top: 13px;
    }

    &--label {
      margin-bottom: 8px;
    }

    &--email {
      padding: 8px;
      margin-bottom: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }

    &--password {
      padding: 8px;
      margin-bottom: 16px;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
    }

    &--password-icon {
      position: absolute;
      right: 1.6rem;
      bottom: 1.7rem;
      color: var(--fivef-color-dark-grey-900);
    }

    &--password-icon-info {
      position: absolute;
      right: -2.6rem;
      bottom: 1.7rem;
    }

    &--button {
      background-color: white;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      border: none;
      border-radius: 20px;
      cursor: pointer;
    }

    &--button-container {
      display: contents;
    }

    &--rest-password-link {
      padding-right: 2rem;
      padding-left: 2rem;
      margin: 0;
    }

    &--error-message {
      font-size: .9rem;
      color: var(--fivef-color-warn);
      margin-bottom: 2rem;
    }

    &--system-maintenance {
      color: #007cff;
      margin: 10px 6px;
      font-size: 9pt;
      right: 0;
    }
  }

  .fivef-session-reset-form {
    &--password-error-validation {
      margin-top: -8px;

      &--success {
        color: var(--fivef-color-success);
      }

      &--warning {
        color: var(--fivef-color-status-open);
      }

      &--error {
        color: var(--fivef-color-warn);
      }
    }

    &--wrong-password-error {
      margin-top: 15px;
      color: $dvtx-danger;
    }
  }

  .fivef-session-registration-form {
    &--password-error-validation {
      &--success {
        color: var(--fivef-color-success);
      }

      &--warning {
        color: var(--fivef-color-status-open);
      }

      &--error {
        color: var(--fivef-color-warn);
      }
    }

    &--wrong-password-error {
      color: $dvtx-danger;
    }
  }

  .fivef-session-registration-complete {
    width: 100%;
    display: flex;
    padding: 3rem;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;

    &--checklist-item {
      padding: 10px 0;
    }

    &--checklist-icon {
      font-size: 32px;
    }

    &--checklist-icon-success {
      color: var(--fivef-color-success);

    }

    &--in-progress {
      color: var(--fivef-color-status-in-progress);
    }

    &--center {
      display: flex;
      align-self: center;
    }

  }
}
