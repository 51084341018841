@import 'src/lib/styles/fivef/mixins/menu';

.fivef-reaction {
  &--button {
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0 !important;
    // align button content with top content.
    padding-left: 0;

    color: var(--fivef-color-text-tertiary);

    .mat-icon {
      font-size: 1.4rem;
      height: 1.4rem;
      width: 1.4rem;
      z-index: 1;
    }
  }

  &--button:hover {
    color: var(--fivef-color-surface-brand);
  }

  &--button--title {
    font-size: var(--fivef-font-size-xs);
    color: var(--fivef-color-text-tertiary);
    margin-left: var(--fivef-spacing-xxs);
  }

  &--menu {
    @include fivef-menu;

    &--reaction-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &--reaction-item {
    }
  }
}


.fivef-reactions {
  display: flex !important;

  &--button {

  }
}


.fivef-reactions--button {
  &.fivef-reactions--red {
    .mat-icon {
      color: var(--fivef-color-reaction-heart);
    }
  }

  &.fivef-reactions--blue {
    .mat-icon {
      color: var(--fivef-color-reaction-thump-up);
    }
  }

  &.fivef-reactions--dark-blue {
    .mat-icon {
      color: var(--fivef-color-reaction-smiley-bg);
    }
  }

  &.fivef-reactions--smiley {
    .mat-icon {
      color: var(--fivef-color-reaction-smiley-bg);
    }

    .fivef-reactions--smiley-bg {
      position: absolute;
      width: 16px;
      left: 17px;
      height: 14px;
      background-color: var(--fivef-color-reaction-smiley);
      z-index: 0;
      border-radius: 50%;
    }
  }
}
