@import 'src/lib/styles/fivef/mixins/button';
@import 'src/lib/styles/fivef/mixins/menu';

.fivef-comment {
  position: relative;
  z-index: 0;

  &--container {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
  }

  &--avatar {
  }

  &--content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-left: 1rem;
    background-color: var(--fivef-color-surface-primary);
    border-radius: var(--fivef-border-radius-m);
    box-shadow: -5px 10px 15px 5px #c9c9c9 !important;
    padding: 0;
    z-index: 1;

    // Left triangle of the chat bubble.
    &:before {
      background-color: var(--fivef-color-surface-primary);
      content: '';
      position: absolute;
      top: 1rem;
      left: -7px;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      transform: rotate(45deg);
      transform-origin: center center;
      z-index: 1;
    }
  }

  &--header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    padding: var(--fivef-spacing-xxs) var(--fivef-spacing-m) 0 var(--fivef-spacing-m);

    &--author-info {
      flex-wrap: wrap;
      color: var(--fivef-color-text-secondary);
      font-size: var(--fivef-font-size-s);
    }
  }

  &--action-button {
    @include fivef-button-s;
  }

  &--action-menu {
    @include fivef-menu;

    ul {
      margin: 0;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--fivef-spacing-s);

        mat-icon {
          margin-right: var(--fivef-spacing-xs);
        }
      }
    }
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    word-break: break-word;

    padding: 0 var(--fivef-spacing-m) var(--fivef-spacing-s) var(--fivef-spacing-m);

    &--reply {
      &--title {
        color: var(--fivef-comment-reply-title-color);
        font-style: italic;
        font-size: var(--fivef-font-size-s);
      }

      &--content {
        border-left: 4px solid var(--fivef-comment-reply-border-color);
        background-color: var(--fivef-comment-reply-bg-color);
        padding: var(--fivef-spacing-xxs) var(--fivef-spacing-xxs) var(--fivef-spacing-xxs) var(--fivef-spacing-s);
        margin-bottom: var(--fivef-spacing-xxs);
        border-radius: var(--fivef-border-radius-xs);
      }
    }

    img {
      max-width: 100%;
    }

    p {
      font-size: inherit !important;
    }
  }

  &--footer {
    border-top: 1px solid var(--fivef-comment-separator-color);
    padding: var(--fivef-spacing-xxs) var(--fivef-spacing-m);

    &--stats {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
