/**
 * fivef margin and padding generators for generic spacing classes.
 *
 * Example application: .ff-mt-xxs
 */
$spacing-levels: (zero, xxs, xs, s, m, l, xl, xxl);
$sides: (top, bottom, left, right);

@each $space in $spacing-levels {
  @each $side in $sides {
    .ff-m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: var(--fivef-spacing-#{$space});
    }

    .ff-p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: var(--fivef-spacing-#{$space});
    }
  }
}

/**
 * Vertical and horizontal space classes:
 *
 * Examples: ff-mx-xl, ff-py-xxs, ff-gap-m
 */
@each $space in $spacing-levels {
  .ff-mx-#{$space} {
    margin-left: var(--fivef-spacing-#{$space});
    margin-right: var(--fivef-spacing-#{$space});
  }

  .ff-my-#{$space} {
    margin-top: var(--fivef-spacing-#{$space});
    margin-bottom: var(--fivef-spacing-#{$space});
  }

  .ff-px-#{$space} {
    padding-left: var(--fivef-spacing-#{$space});
    padding-right: var(--fivef-spacing-#{$space});
  }

  .ff-py-#{$space} {
    padding-top: var(--fivef-spacing-#{$space});
    padding-bottom: var(--fivef-spacing-#{$space});
  }

  .ff-gap-#{$space} {
    gap: var(--fivef-spacing-#{$space});
  }
}
