/**
 * Workflow toolbar action menu.
 */
.dvtx-grant-thornton-sync-action-menu {
  hr {
    margin: 0 !important;
  }

  &--superscript {
    color: var(--fivef-color-text-tertiary);
    padding-left: 1rem;
  }

  &--menu {
    max-width: 600px !important;
    min-width: 500px;
    padding: var(--fivef-spacing-xxs) var(--fivef-spacing-xs);
  }

  &--headline {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }

  &--icon {
    width: 46px;
  }

  &--container {
    cursor: pointer;
    display: flex;
    width: 100%;
    max-width: 500px;
    min-width: 400px;
    overflow: hidden;
    align-items: center;
    flex: 1 1 100%;
    padding: 0 1rem;
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow: hidden;
    gap: 3px;
  }

  &--title,
  &--subtitle {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: 0.9rem;
  }

  &--label {
    margin-bottom: 0 !important;
  }

  &--auto-sync-section {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem 0 53px;
    color: var(--fivef-color-text-tertiary);
  }

  &--badge {
    padding: 0 7px;
    border-radius: 7px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--channel {
      background-color: rgb(114,87,153);
      color: white;
    }

    &--folder {
      background-color: rgb(185,171,204);
    }
  }
}
