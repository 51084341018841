.fivef-process-status {
  $fivef-blue-300: #7b8490;

  display: flex;
  align-items: center;
  color: $fivef-blue-300;
  // Default size.
  font-size: var(--fivef-font-size-l);
  flex-wrap: wrap;

  // Icon default size.
  mat-icon {
    width: var(--fivef-font-size-l);
    height: var(--fivef-font-size-l);
    line-height: var(--fivef-font-size-l);
    font-size: var(--fivef-font-size-l);
    color: $fivef-blue-300;
  }

  &--substatus {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}
