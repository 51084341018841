.fivef-file-button {
  &--container {
    padding: 0.5rem 0.5rem;
    margin: 0 0;
    height: 100%;
    width: 100%;

    border: 1px solid var(--fivef-color-border-secondary);
    border-left: 6px solid var(--fivef-color-border-secondary);
    min-width: 180px;
    cursor: pointer;
    min-height: 50px;
    margin-bottom: 3px;
    padding-top: 3px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--fivef-border-radius-m);

    a {
      color: var(--fivef-color-text-primary);

      &:hover {
        color: var(--fivef-color-action);
      }
    }

    &:hover {
      color: var(--fivef-color-action);
      border-left: 6px solid var(--fivef-color-action);
    }

    .fivef-file-button--content-wrapper {
      display: flex;
      align-items: center;
      width: calc(100% - 87px);
    }

    .fivef-file-button--content {
      display: flex;
      flex-direction: column;
      justify-items: center;
      width: calc(100% - 24px);
      margin-left: var(--fivef-spacing-s);
      margin-right: var(--fivef-spacing-s);

      .h4 {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 8px;
      }
    }

    .fivef-file-button--action-button {
      padding-left: var(--fivef-spacing-s);
      display: flex;
      align-items: center;
    }

    /**
     * Coloring of border and text.
     * Currently the support type is without border at third party external view and timeline event.
     * The second used type is derived from the CAC and the appendix instructions at the top
     * of the recipients dashboard or CAC wizard.
     */
    &.fivef-file-button--color {
      &-default {
        border: 1px solid transparent;
        background-color: var(--fivef-color-surface-primary);
        box-shadow: 0 4px 7px -2px #c9c9c9;
        border-collapse: collapse;
      }

      &-default:hover {
        border: 1px solid var(--fivef-color-action);
        box-shadow: 0 12px 12px -12px #c9c9c9;
      }

      &-audit-instruction {
        // border-left: 6px solid #00CA5C;
        border: 1px solid transparent;
        background-color: var(--fivef-upload-background-color);
        box-shadow: 0 4px 4px -4px #c9c9c9;
        border-collapse: collapse;
      }

      &-audit-instruction:hover {
        color: var(--fivef-color-action);
        box-shadow: 0 12px 12px -12px #c9c9c9;
      }
    }
  }
}
