.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: var(--fivef-color-text-inverse) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: var(--fivef-color-text-inverse) !important;
}

/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//@import "../../../layout/var";
//@import "../../../layout/mixins";
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.mat-checkbox-layout {
//  margin-bottom: 0;
//  max-width: 100%;
//}
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.mat-checkbox-label {
//  font-family: "Roboto", sans-serif;
//  font-size: 1rem;
//  line-height: 1.5;
//  color: $dvtx-fivef-blue-300;
//  max-width: 100%;
//  width: 100%;
//  @include text-crop;
//}
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.mat-checkbox-frame {
//  background-color: $dvtx-white;
//  border: 1px solid $dvtx-fivef-blue-100 !important;
//}
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
//.mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background-color: $dvtx-action;
//  border: 1px solid $dvtx-action;
//}
//
///*sec-checkbox*/
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.sec-checkbox.mat-checkbox-checked .mat-checkbox-frame {
//  border: none !important;
//}
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.sec-checkbox .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
//.sec-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background {
//  background-color: transparent;
//  border: none !important;
//}
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.sec-checkbox .mat-checkbox-checkmark-path {
//  stroke-width: 4.66667px !important;
//  stroke: $dvtx-action !important;
//}
//
///*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
//.sec-checkbox .mat-checkbox-label {
//  font-family: "Roboto", sans-serif;
//  font-size: 1rem;
//  line-height: 1.5;
//  color: $dvtx-fivef-blue-500;
//}
