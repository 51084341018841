/**
 * Defaults of the password reset form.
 */
.fivef-session-password-reset-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 500px;
  max-width: 620px;
  padding-top: 8rem;

  &--form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  // TODO: This is not the correct link variable!
  a {
    color: var(--five-f-link-color);
  }

  a:hover {
    color: var(--five-f-link-color-hover);
  }

  /**
   * Title.
   */
  &--headline {
    align-self: center;
    margin-bottom: 4rem;
  }

  /**
   * Request send button area.
   */
  &--button-container {
    display: flex;
    justify-content: end;
    width: 100%;

    .fivef-session-password-reset-form--button {
      width: 40%;
      height: 38px !important;
    }
  }

  /**
   * Validation containers and colors.
   */
  &--password-error-validation,
  &--wrong-password-error {
    min-height: 2rem;
    padding: var(--fivef-spacing-s);
  }

  &--password-error-validation {
    &--success {
      color: var(--fivef-color-success);
    }

    &--warning {
      color: var(--fivef-color-status-open);
    }

    &--error {
      color: var(--fivef-color-warn);
    }
  }

  &--wrong-password-error {
    color: var(--fivef-color-warn);
  }
}
