.fivef-session-registration {
  display: flex;
  padding: 14px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-width: 500px;
  max-width: 620px;
  padding-top: 8rem;

  &--form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /**
   * Title.
   */
  &--headline {
    display: flex;
    align-self: center;
    align-items: center;
    margin-bottom: 4rem;
  }

  /**
   * Section of invalid token message.
   */
  &--invalid-token {
    padding: 3rem;
    text-align: center;
  }
}
