.fivef-session-registration {
  &--invalid-token {
    padding: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;

    &--inner {
      display: flex;
      flex-direction: column;
    }
  }
}
