/**
 * Search result table of the page header search.
 */
.fivef-page-header-search-results {
  &--container {
    max-height: 450px;
    overflow: auto;
  }

  &--fivef-color-bar {
    position: absolute;
    bottom: 2px;
    top: 2px;
    border-radius: 2px;
    width: 4px;
    left: 0;
  }

  &--footer {
    display: flex;
    justify-content: space-between;
  }

  &--table {
    width: 100%;
    line-height: 1.3;
    background: none;
    zoom: 90%;

    .mat-mdc-header-row,
    .mat-mdc-row {
      border: none;
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
      flex: 0.4px;
      height: 40px;
    }

    // Except last column handling.
    //.mat-mdc-cell:not(:last-child) {
    //}

    .mat-mdc-row {
      min-height: 0 !important;
      margin-bottom: 1px;
      padding: 0;
      background: var(--fivef-color-surface-primary);
      cursor: pointer;

      .mat-mdc-cell {
        background: white;
      }

      &.active,
      &:hover {
        background-color: var(--fivef-color-surface-primary-highlight);
        transition: background-color 0.1s ease-in-out;

        .mat-mdc-cell {
          background-color: var(--fivef-color-surface-primary-highlight);
        }
      }
    }

    .cdk-column {
      &-icon {
        flex: 0 0 52px;
        padding: 0 1rem;
      }

      &-project {
        font-weight: 400;
        justify-content: flex-start;
        padding: 0 1rem;
        min-width: 180px;
        flex-grow: 1;
        position: relative;
        align-items: center;
      }

      &-type {
        font-weight: 100;

        justify-content: flex-start;
        padding: 0 1rem;
        min-width: 150px;
        max-width: 230px;
        flex-grow: 1;
      }

      &-view {
        font-weight: 100;
        flex: 0 0 150px;
        padding: 0 1rem;
        justify-content: flex-start;
      }
    }
  }
}
