:root {
  /*
   * 1rem = 16px Setup.
   *  // Spacing
   *  --fivef-spacing-xxs: 0.25rem; // 4px
   *  --fivef-spacing-xs: 0.5rem; // 8px
   *  --fivef-spacing-s: 0.75rem; // 12px
   *  --fivef-spacing-m: 1rem; // 16px
   *  --fivef-spacing-l: 1.5rem; // 24px
   *  --fivef-spacing-xl: 2rem; // 32px
   *  --fivef-spacing-xxl: 3rem; // 48px
   *
   *
   *  // Border Radius
   *  --fivef-border-radius-xxs: 0.125rem; // 2px
   *  --fivef-border-radius-xs: 0.25rem; // 4px
   *  --fivef-border-radius-s: 0.375rem; // 6px
   *  --fivef-border-radius-m: 0.5rem; // 8px
   *  --fivef-border-radius-l: 0.75rem; // 12px
   *  --fivef-border-radius-xl: 1rem; // 16px
   *  --fivef-border-radius-xxl: 1.5rem; // 24px
   */

  // 1rem = 14px setup.
  // Spacing
  --fivef-spacing-zero: 0;
  --fivef-spacing-xxs: 0.28571429rem; // 4px
  --fivef-spacing-xs: 0.571428571rem; // 8px
  --fivef-spacing-s: 0.857142857rem; // 12px
  --fivef-spacing-m: 1rem; // 14px
  --fivef-spacing-l: 1.714285714rem; // 24px
  --fivef-spacing-xl: 2rem; // 32px
  --fivef-spacing-xxl: 3.428571429rem; // 48px


  // Border Radius
  --fivef-border-radius-xxs: 0.125rem; // 2px
  --fivef-border-radius-xs: 0.25rem; // 4px
  --fivef-border-radius-s: 0.375rem; // 6px
  --fivef-border-radius-m: 0.5rem; // 8px
  --fivef-border-radius-l: 0.75rem; // 12px
  --fivef-border-radius-xl: 1rem; // 16px
  --fivef-border-radius-xxl: 1.714285714rem; // 24px

  /**
   * Max-width of content containers for readability and UX.
   */
  --fivef-content-container-width: 800px;
}
