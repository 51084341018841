.fivef-simple-resource-indicator {
  &--counter {
    position: relative;
    width: 30px !important;
    height: 30px !important;

    i {
      font-size: 10px;
      position: absolute;
      margin: auto;
      height: 10px;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      z-index: 1;
      background: var(--fivef-indicator-background-color);
      color: var(--fivef-indicator-color);
      border-radius: 50%;
      min-width: 10px;
      padding: 2px;
      box-sizing: content-box;
      right: 0;
      bottom: 0px;
    }

    &--no-content {
      mat-icon {
        color: var(--five-f-color-disabled);
      }
    }
  }
}
