/**
 * Global sidebar -> menu bar defaults.
 */
.fivef-sidebar-navigation {
  &--link-listing {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none
  }

  &--link-listing > li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative
  }
}

.fivef-main-app--context-navigation {
  .fivef-sidebar-navigation--wrapper {
    padding-left: var(--fivef-spacing-m);
  }
}
