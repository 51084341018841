/**
 * 5F label styling.
 *
 * NOTE: Changes on label items also affect the active filter bar.
 */
.fivef-label {
  display: inline-block;

  &--container {
    padding: 4px 6px;
    border-radius: 12px !important;
    font-size: 12px;
    line-height: 10px;
    font-weight: 400;
    height: 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    min-width: 20px;

    &:hover .fivef-label--remove-action {
      opacity: 1;
      pointer-events: auto;
    }

    .mat-icon {
      font-size: 14px;
      width: 14px;
      height: 14px;
      display: flex;
    }
  }

  &--icon {
    margin-right: var(--fivef-spacing-xxs);
  }

  &--remove-action {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    width: 14px;
    border-radius: 50%;
    position: absolute;
    right: 3px;
    background: inherit;

    &--icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      margin: 0;
    }
  }

  &--disabled {
    cursor: default;
  }
}
