/**
 * Session login overrides.
 */
.fivef-session-login {
  // Remove spacing on top to the header.
  padding-top: 0;

  // Disables the login headline:
  // Text is implemented by tenant content.
  &--headline {
    display: none;
  }

  // Creates a padding for the form inside the container.
  &--form {
    width: 80%;
  }

  // The design contains a full width login button.
  &--button-container {
    justify-content: flex-start;

    .fivef-session-login--button {
      width: 100%;
    }
  }

  &--welcome-message {
    margin-bottom: 4rem;
  }
}
