.five-f-settings-container {
  width: 100% !important;
  margin-top: 1.5rem !important;
  margin-left: 1rem !important;

  &.five-f-block {
    display: block;
  }

  &.five-f-settings-pull-up {
    // If containers already implements a right aligned search bar,
    // it can be pulled up into the nav bar. This is just a
    // work arround for a general concept later.
    // This is a marker class as well to find these acandidates later.
    margin-top: -40px !important;
  }
}
