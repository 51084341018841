/**
 * 5F Carousel.
 *
 * Overrides bootstrap carousel defaults.
 */
.fivef-carousel {
  flex: 1;

  /**
   * The default dot indicators.
   * The default indicators are a bit big and bold so we reduce the size.
   */
  .carousel-indicators.fivef-carousel--carousel-indicators li {
    max-width: 7px;
    height: 7px;
  }

  /**
   * Carousel indicator dots.
   * Example taken from 5F default at override/bootstrap_carousel.
   *
   * &--carousel-indicators {
   *   li {
   *     max-width: 7px;
   *     height: 7px;
   *   }
   * }
   */

  &--wrapper,
  &--inner,
  &--item {
    height: 100%;
  }

  &--content-area {
    position: relative;

    &--hint {
      display: none;
    }
  }

  &--highlight-content-area {
    border: 1px solid var(--fivef-color-action);

    .fivef-carousel--content-area--hint {
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      color: var(--fivef-color-action);
    }
  }

  &--slide-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &--container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 100%;
    flex-direction: column;
    object-fit: contain;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--carousel-control {
    .mat-icon {
      font-size: 40px;
    }

    /**
     * Next/previous links. Unused in this style.
     * &--previous, &--next {}
     */
  }
}
