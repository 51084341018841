/**
 * Old generic override of ordered list.
 * Seems only to be used inside third party code of Atmina.
 * Prefixed to not pollute global tag styling.
 */
.fivef__legacy ol {
  padding-left: $content-gap*2;

  @include mq-sm-down {
    padding-left: $content-gap;
  }

  li + li {
    margin-top: 5px;
  }

  &.alphabet-list {
    list-style-type: lower-alpha;
  }

  &.demical {
    list-style-type: decimal;
  }

  &.disc-list {
    list-style: disc;
  }

  &.number-list {
    list-style-type: none;

    & > li {
      position: relative;

      &:before {
        content: attr(data-number);
        font-weight: bold;
        display: inline-block;
        width: 1.5rem;
        position: absolute;
        left: -$content-gap*2;

        @include mq-sm-down {
          left: -25px;
        }
      }
    }

    &.normal {
      & > li {
        &:before {
          font-weight: normal;
        }
      }
    }
  }
}
