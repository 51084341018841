@import 'src/lib/styles/fivef/mixins/menu';

.fivef-process-timeline-filter-menu {
  &--button {
    > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--count-indicator {
    display: flex;

    .mat-badge-content {
      right: -4px !important;
      top: -2px !important;
      height: 16px;
      width: 16px;
      line-height: 16px;
      font-weight: 400;
      background: var(--five-f-filter-badge-background-color);
      color: white;
    }

    &.zero .mat-badge-content {
      visibility: hidden
    }
  }

  &--menu {
    @include ff-context-menu;

    &--updates-listing {
      padding: 0 !important; // !important to be removed
      width: 300px;
      overflow: auto;
      opacity: 0;

      @media (max-width: 550px) {
        width: calc(100vw - 30px);
      }

      &.opened {
        animation: fivef-open-menu-slowly 0.2s ease-in-out 0.05s forwards;
      }

      button {
        width: 100%;
        padding: 0.6rem;
        text-align: left;
        cursor: pointer;
        font-size: 1rem;

        &:disabled {
          cursor: default;

          &:hover {
            background: white;
          }
        }

        mat-icon {
          margin-right: 0.5rem;
        }
      }

      &::before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
      }
    }
  }
}

@keyframes fivef-open-menu-slowly {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

