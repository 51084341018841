@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * Autocomplete input stylings.
 * Used at process and client autocomplete of the inbox.
 */
.fivef-autocomplete {
  display: block;
  transition: background 0.2s ease-in-out;
  overflow: hidden;

  &--wrapper {
    display: flex;
    width: 100%;
    flex-direction: column
  }

  &--display-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    position: absolute;
  }

  &--display-container-title {
    @include fivef-text-truncate;
  }

  &--prefix-icon {
    width: 30px;
    color: var(--fivef-color-button-secondary);
    margin-right: var(--fivef-spacing-xs);
  }

  &--mat-option {
    width: 100% !important;
    overflow: hidden !important;
    height: auto;
    line-height: 1em !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    padding-left: 5px !important;

    // Inner wrapper must be overflow hidden to support title truncation.
    .mdc-list-item__primary-text {
      width: 100%;
      overflow: hidden;
    }
  }

  &--content-container {
    border-left-width: 5px;
    border-left-style: solid;
    border-left-color: transparent;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    // Min height to not cut the indicator and icon on missing client.
    min-height: 40px;
    display: flex;
    align-items: center;
  }

  &--title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  &--title,
  &--subtitle {
    color: var(--fivef-color-text-primary);;
    display: inline !important;
    @include fivef-text-truncate;

    font-size: var(--fivef-font-size-m);
    line-height: 1.3;

    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
  }

  &--subtitle {
    font-size: var(--fivef-font-size-s);
    font-weight: 300;
  }

  &--load-more-trigger {
    width: 100%;
    height: 1px;
    display: flex;
    justify-content: center;
  }
}
