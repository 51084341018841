// Fix mat-sidenav drawer at left side.
// Scrolls content otherwise.
.mat-drawer-inner-container {
  display: flex;
  overflow: hidden !important;
  overflow-x: hidden !important;
}

// This rule prevents an entirely removed sidenav
// which would hide the toggle.
.mat-drawer[style*="visibility: hidden"] {
  display: block !important;
}

.dvtx-app-sidebar {
  overflow: hidden;
}

/**
 * Main sidebar navigation.
 */
.fivef-main-app--navigation-wrapper {
  z-index: 1030;
  position: relative;
  width: 70px;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  min-width: 70px;
  max-width: 70px;
  background: var(--fivef-page-header-background-color);
  border-right: 1px solid var(--fivef-page-header-border-color);
}

.fivef-main-app--navigation {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 10vh);
  padding-top: 8px;

  // Main navigation entries, e.g. dashboard, workflows.
  // Class for custom themes.
  &--main-items {
  }

  // Secondary navigation items, e.g. FAQ.
  // Class for custom themes.
  &--secondary-items {
  }
}

/**
 * Secondary left sidebar for context.
 */
.fivef-main-app--context-navigation {
  flex: auto;
}

/**
 * Page sidebar component layout.
 */

.fivef-page-sidebar {
  &--container {
    transition: all 0.3s ease 0s;
    box-shadow: unset; // var(--fivef-sidebar-box-shadow);
    background-color: var(--fivef-color-sidebar-surface);
    height: 100%;
    position: relative;
    overflow: auto;
    padding-top: 22px;
    // Boilerplate for screen size dependent logic. Currently unused, kept for
    // future reminder on existing use cases of discussion.
    //@include mq-xl-down {
    //  padding-top: 120px;
    //}
    //
    //@include mq-lg-down {
    //  padding-top: 60px;
    //}
    //
    //@include mq-md-down {
    //  padding-top: 60px;
    //}
    //
    //@include mq-sm-down {
    //  padding-top: 20px;
    //}

    hr {
      margin: var(--fivef-spacing-m) 0;
    }
  }

  /**
   * Process sidebar view.
   * Container navigation and below tree views.
   */
  &--process-view {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 100%;
  }

  /**
   * Sidebar widget for project structure and documents.
   */
  &--tree-widget {
    display: flex;
    flex: 1 1 fit-content;
    height: 100%;
    overflow: hidden
  }

  /**
   * Right context-sidebar title setup.
   */
  &--title {
    font-size: var(--fivef-font-size-xl);
    //padding-top: var(--fivef-spacing-l);
    padding-bottom: var(--fivef-spacing-m);
  }

  /**
   * Alternative sidebar content container implementation to allow inner scrolling inside
   * page sidebar (overflow hidden).
   *
   * Also introduces sticky title behavior.
   */
  &--scrollable-container {
    display: block;
    height: 100%;
    //overflow-x: auto;

    /**
     * Sidebar title sticky at the top with slight transparency when items scroll under.
     * To be used inside scrollable containers.
     */
    .fivef-page-sidebar--sticky-title {
      background-color: var(--fivef-color-sidebar-scroll-background);
      position: sticky;
      top: 0;
      z-index: 1000;
    }
  }

  /**
   * Specialation of the sidebar.
   * Setup: All fixed but inside a scrollable tree.
   */
  &--folder-tree-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding-top: var(--fivef-spacing-m);
    padding-left: var(--fivef-spacing-m);

    .fivef-page-sidebar--title {
      padding-top: 0;
    }

    .fivef-folder-tree {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }
}

/**
 * Toggle button of the sidebar.
 */
.fivef-page-sidebar-toggle {
  position: absolute;
  right: -10px;
  top: 10px;
  transition: all 0.2s;
  z-index: map-get($z-index-set, z-quickstart);

  &--hide {
    visibility: hidden;
  }

  &--show {
    visibility: visible;
    animation: fivef_effect_sidebar_fade_in 0.4s
  }
}

@keyframes fivef_effect_sidebar_fade_in {
  0% {
    opacity: 0;
    transform: scale(0)
  }

  100% {
    opacity: 1;
    transform: scale(1)
  }
}

/**
 * Active resize border.
 */
.fivef-page-sidebar-resizer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 25px;
  // Please note that i realized that -webkit-fill-available is not working on firefox
  // height: -webkit-fill-available;
  height: 100%;
  z-index: 2;
  cursor: col-resize;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-left: 1px solid var(--fivef-page-header-border-color);

  &:hover {
    border-left: 1px solid var(--fivef-color-sidebar-resize-handle);
  }

  &--handle {
  }

  &--hide {
    cursor: default !important;

    &:hover {
      border-left: none !important
    }
  }
}
