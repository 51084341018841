/**
 * Indicator animation with running dots.
 */
@keyframes fivef-loading-indicator--animation {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.fivef-loading-indicator {
  &--cover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.81);
    padding: 0 !important;
  }

  span {
    animation-name: fivef-loading-indicator--animation;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: .2s;
  }

  span:nth-child(3) {
    animation-delay: .4s;
  }

  &--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--fivef-color-text-tertiary);
  }

  &--font-size {
    &-s {
      font-size: var(--fivef-font-size-s);
    }

    &-m {
      font-size: var(--fivef-font-size-m);
      margin-bottom: var(--fivef-spacing-xxs);
    }

    &-l {
      font-size: var(--fivef-font-size-l);
      margin-bottom: var(--fivef-spacing-xxs);
    }
  }

  &--progressbar {
    width: 100%;

    .fivef-loading-indicator--wrapper {
      justify-content: flex-start;
      padding-left: var(--fivef-spacing-xs);
    }
  }

  &--progressbar--progressbar {
    // Angular overrides simple height definitions (important required).
    height: 3px !important;

    // Rounded ends.
    border-radius: var(--fivef-loading-indicator-progressbar-border-radius);
  }

  &--spinner-only {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: -1;

    &--loader {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      height: 100px;
      position: relative;
      width: 100px;
    }

    &--path-loader {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }

    @-webkit-keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes rotate {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @-webkit-keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
      }
    }
    @-webkit-keyframes color {
      100%, 0% {
        stroke: #3c3c3c;
      }
      40% {
        stroke: #0090cf;
      }
      66% {
        stroke: #008744;
      }
      80%, 90% {
        stroke: #8a8a8a;
      }
    }
    @keyframes color {
      100%, 0% {
        stroke: #3c3c3c;
      }
      40% {
        stroke: #0090cf;
      }
      66% {
        stroke: #008744;
      }
      80%, 90% {
        stroke: #8a8a8a;
      }
    }
  }
}
