/**
 * Label group including selector.
 * The container must grow to show as much labels as space is available.
 *
 * NOTE: Changes on label items also affect the active filter bar.
 */
.fivef-labels {
  display: flex;
  flex-grow: 1;
  align-items: center;

  &--container {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }

  .fivef-label {
    margin-right: var(--fivef-spacing-xxs);
  }

  &--show-more {
    cursor: pointer;
    color: var(--fivef-color-surface-inverse);
    border: 1px solid var(--fivef-color-surface-inverse);
  }
}
