/**
 * Fivef Alert box.
 * Supports text input or content by ng-content.
 */
.fivef-alert {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  min-width: 300px;
  max-width: 800px;
  align-items: center;

  &--wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    padding: var(--fivef-spacing-xs) var(--fivef-spacing-m);
    margin: 0;
    background: none;
    border-radius: var(--fivef-border-radius-m);
    border: 1px solid;
  }

  &--icon {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: var(--fivef-spacing-m);
  }

  &--content {
    display: flex;
    align-items: center;
    line-height: 1;
    flex: 1 1 auto;
  }

  &--color {
    &-success {
      border-color: var(--fivef-alert-color-success);
      color: var(--fivef-alert-color-success) !important;
      .fivef-alert--icon > mat-icon {
        color: var(--fivef-alert-color-success);
      }
    }

    &-warn {
      border-color: var(--fivef-alert-color-warn);
      color: var(--fivef-alert-color-warn) !important;
      .fivef-alert--icon > mat-icon {
        color: var(--fivef-alert-color-warn);
      }
    }

    &-info {
      border-color: var(--fivef-alert-color-info);
      color: var(--fivef-alert-color-info) !important;
      .fivef-alert--icon > mat-icon {
        color: var(--fivef-alert-color-info);
      }
    }

    &-default {
      border-color: var(--fivef-alert-color-default);
      color: var(--fivef-alert-color-default) !important;
      .fivef-alert--icon > mat-icon {
        color: var(--fivef-alert-color-default);
      }
    }
  }

  &--border-color {
    &-success {
      border-color: var(--fivef-alert-color-success);
      color: var(--fivef-alert-color-default) !important;
      .fivef-alert--icon > mat-icon {
        // color: var(--fivef-info-color-icon) !important;
        color: var(--fivef-alert-color-success);
      }
    }

    &-warn {
      border-color: var(--fivef-alert-color-warn);
      color: var(--fivef-alert-color-default) !important;
      .fivef-alert--icon > mat-icon {
        // color: var(--fivef-info-color-icon) !important;
        color: var(--fivef-alert-color-warn);
      }
    }

    &-info {
      border-color: var(--fivef-alert-color-info);
      color: var(--fivef-alert-color-default) !important;
      .fivef-alert--icon > mat-icon {
        // color: var(--fivef-info-color-icon) !important;
        color: var(--fivef-alert-color-info);
      }
    }

    &-default {
      border-color: var(--fivef-alert-color-default);
      color: var(--fivef-alert-color-default) !important;
      .fivef-alert--icon > mat-icon {
        // color: var(--fivef-info-color-icon) !important;
        color: var(--fivef-alert-color-default);
      }
    }
  }
}
