// .fivef-btn
.fivef-btn {
  background-color: var(--fivef-color-button-primary);
  color: var(--fivef-color-button-text);
  border: none;
  border-radius: var(--fivef-border-radius-m);
  padding: var(--fivef-spacing-s) var(--fivef-spacing-m);
  cursor: pointer;

  &:hover {
    background-color: var(--fivef-color-button-primary-hover);
  }

  &:disabled {
    background-color: var(--fivef-color-button-disabled);
    color: var(--fivef-color-button-text-disabled);
  }

  &-secondary {
    background-color: var(--fivef-color-button-secondary);
    color: var(--fivef-color-button-text);
    border: none;
    border-radius: var(--fivef-border-radius-m);
    padding: var(--fivef-spacing-s);
    cursor: pointer;

    &:hover {
      background-color: var(--fivef-color-button-secondary-hover);
    }

    &:disabled {
      background-color: var(--fivef-color-button-disabled);
      color: var(--fivef-color-button-text-disabled);
    }
  }

  &-icon {
    background-color: transparent;
    color: var(--fivef-color-button-text-invert);
    border: none;
    border-radius: var(--fivef-border-radius-xl);
    padding: var(--fivef-spacing-s);
    vertical-align: center;
    cursor: pointer;

    &:hover {
      background-color: var(--fivef-color-button-icon-hover);
    }

    &:disabled {
      color: var(--fivef-color-button-icon-text-disabled);
    }
  }
}
