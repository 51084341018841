/**
 * Overrides touch / hover behavior that stops scrolling when in trees or virtual scroll menus.
 * https://github.com/angular/components/issues/4892
 */
//.mat-tooltip-trigger {
//  touch-action: auto !important;
//}
//
//[mattooltip] {
//  touch-action: auto !important;
//}
