/**
 * Sidebar toggle button.
 */
.fivef-sidebar-toggle {
  background-color: white;
  border-radius: 50%;
  display: flex;

  // mat-elevation-z8
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  button {
    height: 20px;
    width: 20px;
    line-height: 20px;
    border: 0;
    outline: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background-color: var(--fivef-color-action);

    .fivef-sidebar-toggle--icon {
      color: var(--fivef-color-text-inverse);
    }
  }

  &--icon {
    color: var(--fivef-color-text-tertiary);
    position: relative;
    left: 0;
    top: 3px;
    font-size: 18px;
  }
}
