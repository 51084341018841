/**
 * Styles migrated and prefixed.
 * All occurances removed.
 * The diff table is used at notifications, at the third party activity log (only styles)
 * at the CAV version history.
 */
.fivef-diff-table--table {
  background-color: transparent;
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;

  & th,
  & td {
    padding: var(--fivef-spacing-xs);
  }

  & th:first-child,
  & td:first-child {
    padding-left: 0;
  }

  & td:nth-child(even) {
    border-top: 1px solid var(--fivef-color-separater);
  }

  & th {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.8571em;
    font-weight: 500;
    word-wrap: break-word;
  }

  & td {
    &.license-td {
      min-width: 10rem;
    }

    &.username-td {
      min-width: 12rem;
    }
  }

  //& tr:not(.bordless-tr) {
  //  td {
  //    border-top: 1px solid $dvtx-cool-grey-50;
  //  }
  //
  //  &:first-child {
  //    td {
  //      border-top: 0 none;
  //    }
  //  }
  //}

  //&.diff-table {
  //  background-color: transparent !important;
  //
  //  @include mq-xs-down {
  //    & th {
  //      display: none;
  //    }
  //
  //    & td {
  //      display: block;
  //
  //      &:first-child {
  //        padding: $content-gap*0.5;
  //      }
  //
  //      &:before {
  //        content: attr(data-th)": ";
  //        display: inline-block;
  //        width: 6.5em;
  //      }
  //    }
  //  }
  //}

  span[class^="diff"] {
    padding: 0 3px;
  }

  .diff {
    // &__description {
    //   background-color: var(--fivef-color-action-light);
    // }

    &__removed,
    &__removed-header {
      text-align: center;
    }

    //&__removed {
    //  text-decoration: line-through;
    //}

    &__added {
      text-align: center;
    }

    &__to_arrow {
      display: flex;
      justify-content: center;
    }

    &__background {
      &-red {
        background-color: var(--fivef-color-warn-light);
      }

      &-green {
        background-color: var(--fivef-color-success-light);
      }
    }
  }

  //& th {
  //  background-color: var(--fivef-color-surface-primary);
  //}

  .diff-table-variable {
  }

  .diff-table-former-value {
    width: 30%
  }

  .diff-table-to-arrow {
    width: 20px;
  }

  .diff-table-new-value {
    width: 30%
  }
}
