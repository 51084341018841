/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//@import "../../../layout/var";
//@import "../../../layout/mixins";
//
//.mat-sort-header-container {
//  position: relative;
//  padding-right: 25px;
//}
//
//.mat-sort-header-stem,
//.mat-sort-header-pointer-left,
//.mat-sort-header-pointer-right {
//  display: none;
//}
//
//.mat-sort-header-sorted p {
//  color: $dvtx-blue-600!important;
//}
//
//.mat-sort-header-container:before,
//.mat-sort-header-container:after,
//.mat-sort-header-arrow:before,
//.mat-sort-header-arrow:after {
//  position: absolute;
//  right: 0;
//  top: 50%;
//  display: block;
//  width: 20px;
//  text-align: center;
//  font: normal 8px/1 FontAwesome;
//  color:$dvtx-fivef-blue-300;
//}
//
//.mat-sort-header-arrow:before,
//.mat-sort-header-arrow:after {
//  color: #fff;
//}
//
//.mat-sort-header-container:before,
//.mat-sort-header-arrow:before {
//  margin-top: -8px;
//  content: "\f077";
//}
//
//.mat-sort-header-container:after,
//.mat-sort-header-arrow:after{
//  margin-top: -2px;
//  content: "\f078";
//}
//
//.mat-sort-header-sorted .mat-sort-header-container:before,
//.mat-sort-header-sorted .mat-sort-header-container:after {
//  display: none;
//}
//
//.mat-sort-header-arrow {
//  position: absolute!important;
//  top: 50%!important;
//  margin: -10px 0 0 0!important;
//  right: 0;
//  width: 20px!important;
//  height: 20px!important;
//  background: $dvtx-blue-600;
//  border-radius: 50%;
//  transform: none!important;
//}
//
//.mat-sort-header-asc:before {
//  display: none;
//}
//
//.mat-sort-header-desc:after {
//  display: none;
//}

