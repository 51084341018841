.fivef-session {
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 91vh;
    background-size: cover;
  }

  &--main {
    max-width: 98%;
    min-width: 75%;
    max-height: 83%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 !important;
    border: 1px solid #e7e7e7;
    box-shadow: 0 0 6rem 0 rgba(0, 0, 0, 0.288);
    border-radius: 25px;
    flex-direction: row-reverse;
  }

  &--navigator {
    flex: 1 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &--language-switcher {
    display: none;
  }

  &--logo-header {
    display: none;
  }

  // Secion inside session containing the carousel.
  &--carousel {
    flex: 1 !important;
    z-index: 1;
    min-width: 700px;
  }
}
