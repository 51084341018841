@import "src/lib/styles/fivef/mixins/disable_input_hints_wrapper";

/**
 * Contact autocomplete CVA input.
 */
.fivef-contact-autocomplete {
  display: inline-block;
  width: 100%;

  &--form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;

    .mat-form-field {
      width: 100%;
    }
  }

  @include fivef-disable-input-hints-wrapper();
}
