.fivef-artifact-listing-item {
  display: block;
  padding: 2px 0;
  height: 48px;
  position: relative;

  // Main container
  &--container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;

    // Left border for Collecto alike look 'n feel.
    .fivef-artifact-listing-item--left-border-color {
      position: absolute;
      // GIve it 2px space to the top to fit better with document icon button.
      top: 2px;
      left: 0;
      bottom: 0;
      width: 4px;
      border-radius: 2px;
    }

    // Content container
    .fivef-artifact-listing-item--container {
      width: 100%;
      overflow: hidden;
      display: flex;

      // Leading document icon. Reduced to 32px to not use too much space on small screens beside filename.
      .fivef-artifact-listing-item--icon {
        vertical-align: middle;
        width: 32px;
        line-height: 32px;
        height: 32px;
      }

      // Content is container width of 100% minus width of leading icon.
      .fivef-artifact-listing-item--content-container {
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
      }
    }
  }

  &--download-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 1;

    .fivef-loading-indicator--wrapper {
      color: var(--fivef-color-secondary);
    }

    &--background {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--fivef-color-surface-primary);
      opacity: 0.8;
      z-index: 0;
    }
  }
}
