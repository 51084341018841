/**
 * Mat Icon Button re-size.
 */

// Reduces the new mat icon button size from 48/12px to 40/6px (size/padding).
.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
}

/**
 * Reduced height from 36px to 32px to align nice with 5F
 * input field design.
 */
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 32px;
}

.mdc-button {
  white-space: nowrap;
}

.mat-mdc-button.mat-unthemed:not(:disabled) {
  color: var(--fivef-color-button-secondary);
}

//.mat-mdc-icon-button[disabled][disabled] {
//  --mdc-icon-button-disabled-icon-color: var(--fivef-color-input-background-disabled);
//}

.mat-mdc-button:not(:disabled),
.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button {
  // The button default is gray.
  color: var(--fivef-color-button-secondary);
  --mdc-button-icon-color: var(--fivef-color-button-secondary);
  --mdc-icon-button-icon-color: var(--fivef-color-button-secondary);

  /**
   * Gray color setup like basic.
   * color="gray" is deprecated. Use without instead.
   */
  &.mat-gray {
    --mdc-icon-button-icon-color: var(--fivef-color-button-secondary);
    color: var(--fivef-color-button-secondary);

    &:hover,
    &:focus {
      --mdc-icon-button-icon-color: var(--fivef-color-action);
      color: var(--fivef-color-action);
    }
  }

  /**
   * Blue hover effect for basic buttons.
   */
  &:hover:not(.mat-primary):not(.mat-accent):not(.mat-warn),
  &:focus:not(.mat-primary):not(.mat-accent):not(.mat-warn) {
    --mdc-icon-button-icon-color: var(--fivef-color-action);
    color: var(--fivef-color-action);
  }
}

.mat-mdc-button:not(:disabled),
.mat-mdc-button {
  &.mat-warn {
    --mdc-icon-button-icon-color: var(--fivef-color-button-secondary);
    //color: var(--fivef-color-button-secondary);

    &:hover,
    &:focus {
      --mdc-icon-button-icon-color: var(--fivef-color-warn);
      color: var(--fivef-color-warn);
      // color: var(--mdc-text-button-label-text-color, inherit);
    }
  }
}

.mat-mdc-raised-button,
.mat-mdc-icon-button {
  &.mat-warn {
    --mdc-icon-button-icon-color: var(--fivef-color-button-secondary);
    //color: var(--fivef-color-button-secondary);

    &:hover,
    &:focus {
      --mdc-icon-button-icon-color: var(--fivef-color-warn);
      color: var(--fivef-color-text-inverse);
      // color: var(--mdc-text-button-label-text-color, inherit);
    }
  }
}

mat-icon.mat-warn {
  --mat-icon-color: var(--fivef-color-warn);
  color: var(--mat-icon-color);
}

.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: var(--fivef-color-warn);
}

.mat-mdc-icon-button.mat-warn:focus,
.mat-mdc-icon-button.mat-warn:hover {
  --mdc-icon-button-icon-color: var(--fivef-color-warn);
  color: var(--fivef-color-warn);
}

.mat-mdc-icon-button.mat-accent:focus,
.mat-mdc-icon-button.mat-accent:hover {
  --mdc-icon-button-icon-color: #00c853;
  color: var(--mdc-icon-button-icon-color);
}
