.ff-stroked-toolbar-button {
  padding: 0px 10px;
  line-height: 28px;
  color: var(--fivef-color-button-secondary);
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);

  mat-icon {
    font-size: 16px;
    line-height: 15px;
    height: 16px;
    width: 16px;
    margin-right: 3px;
    color: var(--fivef-color-button-secondary);
    transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    color: var(--fivef-color-action);

    mat-icon {
      color: var(--fivef-color-action);
    }
  }
}
