.ff-image-preview {
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;
  border: 1px solid var(--fivef-color-text-secondary);
  border-radius: var(--fivef-border-radius-s);
  overflow: hidden;
  object-fit: contain;
  margin-top: var(--fivef-spacing-m);

  img {
    max-height: 200px;
    max-width: 200px;
    object-fit: contain;
  }
}
