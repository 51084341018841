// @deprecated Can be removed when instant-message panel is removed.
@import "../base/var_legacy";
@import "../base/mixins";

%box {
  border: 1px solid $dvtx-cool-grey-100;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

@each $class, $color in $bg-colors {
  .#{$class} {
    background: $color !important;
  }
}
