html,
body {
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  background-color: var(--fivef-color-app-background);
  color: var(--fivef-color-text-primary);
  min-width: 320px;
  overflow-anchor: none;
}

#wrapper,
#fivef-app {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.wrapper,
.fivef-app--wrapper {
  padding-top: 75px;
  height: 100%;

  // @include mq-xl-down {
  //   padding-top: 75px;
  // }

  // @include mq-lg-down {
  //   padding-top: 61px;
  // }

  // @include mq-md-down {
  //   padding-top: 61px;
  // }

  // @include mq-sm-down {
  //   padding-top: 61px;
  // }
  // @include mq-lg-up {
  //   padding-top: 75px;
  // }
}

hr {
  margin: var(--fivef-spacing-l) 0;
}
