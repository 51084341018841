@import "src/lib/styles/fivef/mixins/disable_input_hints_wrapper";

/**
 * 5F toolbar layout definitions.
 * TODO: Convert to ff-class.
 */
.fivef-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  flex-grow: 1;
  gap: var(--fivef-spacing-xxs);

  &--space-between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }

  &--start {
    justify-content: flex-start;
  }

  /**
   * Add a padding around the toolbar items.
   */
  &--padded-items {
    padding: 0 var(--fivef-spacing-xs);
  }

  /**
   * Adds a standard content spacing below.
   */
  &--content-spacing {
    margin-bottom: var(--fivef-spacing-xs);
  }

  &--content-spacing-s {
    margin-bottom: var(--fivef-spacing-s);
  }

  &--content-spacing-m {
    margin-bottom: var(--fivef-spacing-m);
  }

  /**
   * Adds a standard content spacing below.
   */
  &--content-spacing--end {
    margin-right: var(--fivef-spacing-xs);
  }

  // The 5F default for form-fields is 100%.
  // For the toolbar we reduce it to a reasonable size.
  .mat-mdc-form-field {
    max-width: 300px;
  }

  // Removes the bottom wrapper for info content below the input
  // from mat-form-field in toolbars. This wrapper prevents, that the form
  // is aligned with buttons.
  @include fivef-disable-input-hints-wrapper();

  /**
   * 5F search input with width expansion effect on focus.
   */
  // <5F search input>
  .mat-mdc-form-field.fivef-toolbar--search {
    transition: max-width 250ms ease-in-out;

    // this is added because when clicking on the clear button it is changing width
    // and not able to clear the text
    transition-delay: 0.3s; /* Wait 0.3s before shrinking */
  }

  // .fivef-search .mat-mdc-form-field.fivef-toolbar--search.mat-focused,
  .mat-mdc-form-field.fivef-toolbar--search.mat-focused {
    max-width: 500px;
  }

  // </5F search input>
}
