@import "src/lib/styles/fivef/mixins/disable_input_hints_wrapper";

$filter-container-content-indent: 120px;

/**
 * Filters container with icon and dialog definition.
 */
.fivef-filters-container {
  /**
   * Icon definition for the embedment. Includes a rewrite of the badge
   * placement.
   */
  .fivef-filters-container--count-indicator {
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-badge-content {
      right: -4px !important;
      top: -2px !important;
      height: 16px;
      width: 16px;
      line-height: 16px;
      font-weight: 400;
      background: var(--five-f-filter-badge-background-color);
      color: var(--fivef-color-surface-primary);
    }

    &--zero .mat-badge-content {
      visibility: hidden
    }

    // Reposition the badge to the left if the label shown at the right side.
    &--badge-left {
      .mat-badge-content {
        left: 14px;
        top: -6px !important;
      }

      // Keep some distance to avoid the badge overlapping the filter label.
      mat-icon {
        margin-right: 0.25rem;
      }
    }
  }
}

/**
 * Filter dialog definition.
 * ATTENTION: This class is evaluated inside the dropdown content component.
 */
.fivef-filters-dropdown-menu {
  + .cdk-overlay-connected-position-bounding-box {
    width: 95% !important; // added for filters dropdown for small screens
    padding-bottom: 50px !important;

    .cdk-overlay-pane {
      max-width: 100% !important;
      right: 0 !important;

      @include mq-md-down {
        position: fixed;
        top: ($filter-container-content-indent * 0.25) !important;
        bottom: ($filter-container-content-indent * 0.25) !important;
        left: ($filter-container-content-indent * 0.25) !important;
        right: ($filter-container-content-indent * 0.25) !important;
      }

      @include mq-sm-down {
        position: fixed;
        top: ($filter-container-content-indent * 0.125) !important;
        bottom: ($filter-container-content-indent * 0.125) !important;
        left: ($filter-container-content-indent * 0.125) !important;
        right: ($filter-container-content-indent * 0.125) !important;
      }

      .mat-mdc-menu-panel {
        margin: 0 !important;
        width: 100% !important;
        max-width: none !important;
        padding: 0 !important;

        .mat-mdc-menu-content {
          padding: 16px !important;
          overflow: auto;
          height: 100% !important;

          .five-f-dropdown-content-inner-content {
            padding: 0 !important;
            flex-direction: column !important;

            .filters-container .col-xs-12 {
              flex: 0 0 240px;
              max-width: 100%;
              width: 240px;
            }

            dvtx-api-autocompleted-input-select .dvtx-api-select {
              width: 240px;
              max-width: 100%;
            }

            @include mq-xs {
              .filters-container .col-xs-12 {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

/**
 * Dialog content with different filter listing groups.
 */
.fivef-filter-dialog--content {
  display: flex;
  flex-direction: row;
  gap: var(--fivef-spacing-s);

  //display: grid;
  //grid-auto-columns: minmax(0, 1fr);
  //grid-auto-flow: column;

  @include mq-lg-up {
    flex-wrap: nowrap;
  }

  //> div {
  //  @include mq-lg-up {
  //    flex-shrink: 1;
  //    // width: 20% !important;
  //  }
  //  @include mq-md-down {
  //    margin: 0.25rem 0;
  //  }
  //}
}

/**
 * The filter listing group.
 */
.fivef-filter-listing {
  display: flex;
  flex-direction: column;
  // flex-grow: 1;

  flex: 0 0 240px;
  max-width: 100%;
  width: 240px;

  .cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: column;
  }

  /**
   * Headline and badge of each item container.
   */
  &--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
    margin-bottom: 0.25rem;
    height: 22px;

    .mat-badge {
      .mat-badge-content {
        position: relative;
        top: auto;
        right: auto;
        background: var(--five-f-filter-badge-background-color);
        color: var(--fivef-color-surface-primary);
        font-weight: 400;
      }
    }
  }

  /**
   * The filter listing container.
   */
  &--container {
    display: flex;
    flex-direction: column;
    background: none;
    box-shadow: none;
    border: none;
    max-height: 280px;
    overflow: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 0 var(--fivef-spacing-xs);
  }

  /**
   * The filter item.
   */
  &--item {
    // Without max width the filterdialog breaks on long item titles
    // TODO: Prepare individual maxwidth dependent on breakpoints
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    text-align: left;
    max-width: 100%;
    margin: 0.25rem 0 !important;
    padding: 0.75rem;
    border-radius: var(--fivef-border-radius-m);
    box-shadow: none;
    border: 1px solid var(--five-f-filter-border-color);
    background-color: var(--fivef-color-surface-primary);

    &:hover {
      cursor: pointer;
      background: none;
      border: 1px solid var(--five-f-filter-border-color-hover);
    }

    &:active, &:focus {
      background: none;
    }

    &.active {
      color: var(--five-f-filter-button-color-active);
      background: var(--five-f-filter-button-background-color-active);
    }

    span.text-truncate {
      width: 100%;
    }

    .mat-icon + span.text-truncate {
      width: calc(100% - 24px - 0.25rem);
    }

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    .fivef-avatar-icon-placeholder {
      margin-left: 0 !important;
      margin-right: 0.5rem !important;
    }

    fivef-avatar + span.text-truncate {
      width: calc(100% - 24px - 0.5rem);
    }
  }

  button {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
  }
}

/**
 * Alternative implementation for the colored filter items e.g. the statuses.
 */
.fivef-filter-listing--container.fivef-filter-listing--colored .fivef-filter-listing--item {
  background-color: var(--fivef-color-surface-primary);

  &:disabled {
    cursor: not-allowed !important;
    color: var(--fivef-color-surface-primary) !important;
    background-color: var(--five-f-border-color-disabled) !important;
    border-color: var(--five-f-background-color-disabled) !important;
  }
}

/**
 * Filter definitions for the inbox.
 */
.fivef-notification-listing-filters--container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: var(--fivef-spacing-s);

  &--filters {
    display: flex;

    // Disable the hint area on the filter inputs.
    @include fivef-disable-input-hints-wrapper();
  }

  &--search {
    display: flex;
    flex-grow: 1;
  }
}
