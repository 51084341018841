.five-f-indicator {
  position: relative;

  &--unread-count-icon-indicator {
    position: absolute;
    bottom: 14px;
    right: 0px;
    height: 6px;
    width: 6px;
    padding: 2px;
    box-sizing: content-box;
    background-color: var(--fivef-indicator-background-color-unread);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
    border-radius: 50%;
  }

  &--unread-count-indicator {
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    bottom: -7px;
    right: -7px;
    height: 10px;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    color: var(--fivef-indicator-color-unread);
    border-radius: 50%;
    background-color: var(--fivef-indicator-background-color-unread);
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
  }

  &--md {
    height: 1.2rem;
    width: 1.2rem;
    padding: 2px;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.mat-mdc-icon-button.five-f-indicator .five-f-indicator--unread-count-icon-indicator {
  bottom: 1px;
  right: 3px;
}

/**
 * See also
 * - dvtx-collecto-resource-indicator
 */
.five-f-resource-indicators {
  flex: 0 0 30px;
  justify-content: center;
  display: inline-flex;
}

.five-f-resource-indicator {
  position: relative;
  cursor: pointer;

  .mat-icon {
    opacity: 0.6;
    margin-left: 4px;
  }

  i {
    font-size: 10px;
    position: absolute;
    margin: auto;
    height: 10px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    z-index: 1;
    background: var(--fivef-indicator-background-color);
    color: var(--fivef-indicator-color);
    border-radius: 50%;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
