/**
 * Defaults of the password reset form.
 */
.fivef-session-password-reset-form {
  justify-content: center;
  padding-top: 0;

  // Creates a padding for the form inside the container.
  &--form {
    width: 80%;
  }

  &--button-container {
    justify-content: flex-start;

    .fivef-session-password-reset-form--button {
      width: 100%;
    }
  }
}
