@import "src/lib/styles/fivef/components/box";

.fivef-settings-panel {
  cursor: pointer;

  .listing-box__box-header .title-icon {
    display: flex;

    .mat-icon {
      color: var(--fivef-settings-panel-icon-color);
      height: 4rem;
      width: 4rem;
    }
  }

  .listing-box__box-header .box-header-title {
    .title {
      color: var(--fivef-settings-panel-color);
    }

    .subtitle{
      font-size: 1rem;
    }

    .subtext {
      color: var(--fivef-settings-panel-subtitle-color);
    }
  }

  /**
   * Color profiles.
   * Currently differentiated: user profile, organization settings, and tenant administration.
   */
  &--profile {
    background-color: var(--fivef-settings-panel-profile-default-background-color);
    color: var(--fivef-settings-panel-profile-default-color);
    border-radius: var(--fivef-settings-panel-border-radius);
    transition: var(--fivef-color-transition-m);

    &:hover {
      background-color: var(--fivef-settings-panel-profile-default-background-color-hover);
      color: var(--fivef-settings-panel-profile-default-color-hover);
      box-shadow: var(--fivef-settings-panel-box-shadow-hover);
    }

    &-user {
      background-color: var(--fivef-settings-panel-profile-user-background-color);
      color: var(--fivef-settings-panel-profile-user-color);

      &:hover {
        background-color: var(--fivef-settings-panel-profile-user-background-color-hover);
        color: var(--fivef-settings-panel-profile-user-color-hover);
      }
    }

    &-tenant {
      background-color: var(--fivef-settings-panel-profile-tenant-background-color);
      color: var(--fivef-settings-panel-profile-tenant-color);

      &:hover {
        background-color: var(--fivef-settings-panel-profile-tenant-background-color-hover);
        color: var(--fivef-settings-panel-profile-tenant-color-hover);
      }
    }
  }
}

.disabled .fivef-settings-panel--profile {
  background-color: var(--fivef-settings-panel-profile-disabled) !important;
}

.disabled .fivef-settings-panel--profile,
.fivef-settings-panel.disabled {
  pointer-events: none;
}
