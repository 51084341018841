/**
 * 5F color picker at settings and Collecto.
 * Refactored from five-f library and unified with duplications.
 */
.fivef-color-picker--handle {
  position: relative;
  display: flex;
  align-items: center;
  overflow: visible;

  // Embedment styles: Icon only or stroked button style
  &--icon-style {
    outline: none;
    border: 0;
    cursor: pointer;
    flex-grow: 1;

    // Creates a round shape for the mat-ripple effect on click.
    border-radius: 50%;

    .mat-icon {
      font-size: 18px;
      width: 18px;
      height: 18px;
    }
  }

  &--button-style {
    width: 40px;
    height: 40px;

    .mat-mdc-outlined-button {
      width: 40px;
      min-width: 40px;
      padding: 0;

      // Reposition of centered color icon inside button.
      padding-left: 11px;
    }
  }

  &--native-dialog {
    position: absolute !important;
    visibility: hidden !important;
  }
}

.five-f-color-picker-dropdown--menu {
  box-shadow: 0 10px 20px -10px #c9c9c9 !important;
  border-radius: 2px !important;
  max-height: calc(100% - 20px) !important;
  max-width: 100%;
  min-width: 240px;
  padding: 0.25rem 1rem !important;
  overflow: hidden !important;

  .mat-mdc-menu-content {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    max-width: 320px;
    padding-bottom: 1rem !important;
    overflow: hidden;

    ul.five-f-color-picker-dropdown--inner-content {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;

      > li {
        height: 40px;
        display: inline-flex;
        align-items: center;

        > span {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: rgba(255, 255, 255, 0.15);
            }
          }
        }
      }
    }
  }
}
