/**
 * Process timeline container aka project room.
 */
.fivef-process-timeline {
  &--container {
    padding-left: 15px;
    display: inline-block;
    width: 100%;
  }

  &--item {
    &:after {
      content : '';
      display : table;
      clear   : both;
    }

    padding-bottom: 15px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  /**
   * Message editor.
   */
  &--editor {
    width: 100%;
    display: inline-block;
  }

  &--editor-actions {
    display: flex;
    justify-content: flex-end;
  }

  &--label {
    font-weight: 600;
    color: var(--fivef-color-text-tertiary);
  }

  &--resource-link {
    color: var(--fivef-color-text-tertiary);
    font-weight: 600;

    &:hover {
      color: var(--fivef-color-action);
    }
  }

  &--comment-export-dialog {
    &--select-all-container {
      padding-left: 15px;
    }
  }

  &--label-zero-spacing {
    padding-bottom: 0 !important;
  }
}
