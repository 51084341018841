@import './src/lib/styles/base/var_legacy';
@import './src/lib/styles/base/mixins';

// Fix the height on scrolling in combination with
// disabled elements outside of the viewport.

.fivef-documents-table {
  width: 100%;

  &--body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &--tr {
    height: auto !important;
    margin-bottom: 4px;
    padding: 0;
    background: white;
    display: flex;
    border-bottom: none;
    border-top: none;
    border-left: 2px solid white;
    border-right: 2px solid white;
    min-height: 81px;

    &:HOVER, &.active {
      background: lighten($dvtx-cool-grey-50, 3%) !important;
    }

    > td {
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
    }
  }

  &--checkbox-td {
    flex: 0 0 50px;
    padding: 0 !important;
    border-right: 1px solid $dvtx-cool-grey-50;

  }

  &--icon-td {

    padding: 0 !important;
    border-right: 1px solid $dvtx-cool-grey-50;
    flex: 0 0 40px;

    .dvtx-artifact-icon {
      vertical-align: middle;
      width: 32px;
      line-height: 32px;
      height: 32px;
    }
  }

  &--file-info-td {
    border-right: 1px solid $dvtx-cool-grey-50;
    flex: 1 0 400px;
    padding: 5px;


    @include mq-xs-down {
      position: relative;
    }
  }

  &--actions-td {
    flex: 0 1 auto;
    max-width: calc(280px + 1rem); // 280px are the max width because we have maximum 7 icons and each has 40px width, the 1rem is for the padding
    padding: 0 0.5rem !important;
  }


  &--document-info-height {
    overflow: hidden;
    width: calc(100% - 20px);
  }

  &--with-added-label-height {
    height: 71px;
  }

  &--doc-info-details {
    font-size: 0.9rem;
    color: $dvtx-cool-grey-300;

    span:not(:first-of-type)::before {
      content: '•';
      margin: 0.2rem 0.3rem;
    }
  }

  .created--tasks--count .count--indicator {
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    position: absolute;
    bottom: 6px;
    right: 4px;
    height: 10px;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    background: $dvtx-blue-200;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &--max-subtitle-length {
    max-width: 160px;
  }

  &--filename {
    mat-icon {
      font-size: 1.3rem;
      height: 100%;
    }
  }

  .five-f-resource-indicator {
    i {
      bottom: 6px;
      right: 4px;
    }
  }
}


::ng-deep {
  .documents-table {
    .value--placeholder {
      cursor: pointer;
    }
  }

  .collector-documents-view {
    .mat-mdc-row {
      border-bottom: none;
      border-top: none;
      border-left: 2px solid white;
      border-right: 2px solid white;
    }
  }

  .mat-column-fileInfo {
    flex: 1 0 400px;
  }

  .mat-column-actions {
    -webkit-box-flex: 1;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    max-width: calc(280px + 1rem); // 280px are the max width because we have maximum 7 icons and each has 40px width, the 1rem is for the padding
    padding: 0 0.5rem !important;
  }
}
