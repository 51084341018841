.fivef-login-theme-fivef-white {
  // Add a white color to the background of the material fields.
  .mat-mdc-text-field-wrapper {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 4px;
  }

  /**
  * Pure theme standard overrides.
  */
  .fivef-session-login,
  .fivef-session-tfa,
  .fivef-session-password-reset,
  .fivef-session-password-reset-form,
  .fivef-session-registration {
    min-width: 600px;
    max-width: 600px;

    // background-color: rgb(245 245 245 / 96%);
    border-radius: 7px;
    padding: var(--fivef-spacing-l);

    &--welcome-logo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .fivef-session {
    /**
     * Container setup for optional background picture.
     */
    &--container {
      height: 100vh !important;
      background-size: cover;
      align-items: flex-start;
    }

    // Enables the alternative language switcher at the bottom of the inner frame.
    &--alternative-language-switcher {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: var(--fivef-spacing-xxl) auto;
    }

    /**
     * Main container setup with rounded corners, and background color.
     * Content is left, carousel slideshow is shown on the right side.
     */
    &--main {
      // padding-top: 8rem;
      margin-top: 10rem;
      border-radius: 10px;
    }
  }

  /**
   *
   */
  .fivef-app--wrapper {
    padding-top: 0 !important;
  }

  .fivef-page-header--container {
    background-color: transparent !important;
  }
}
