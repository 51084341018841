.quickstart {
  position: fixed;
  top: 100px;
  right: 40px;
  z-index: map-get($z-index-set, z-quickstart);

  @include mq-lg-down {
    top: 100px;
  }

  @include mq-md-down {
    right: 20px;
  }

  @include mq-sm-down {
    bottom: initial;
    right: 15px;

    .quickstart-list-holder {
      display: flex;

      .quickstart-btn {
        margin-left: 0.5rem;
      }
    }
  }

  .quickstart-list {
    //@include mq-sm-down {
    //  display: none;
    //}
  }

  .quickstart-mob-icon {
    //@include mq-md-up {
    //  display: none;
    //}
  }
}
