/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
.pac-container {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  width: auto !important;
}
