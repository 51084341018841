/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//@import "../../../utils/var";
//@import "../../../utils/mixins";
//
//.mat-mdc-radio-group {
//  display: flex;
//  flex-wrap: wrap;
//
//  .mat-mdc-radio-button {
//    margin: 0 5px 0 0;
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    .mat-radio-outer-circle {
//      border-color: $dvtx-cool-grey-100;
//      border-width: 1px!important;
//    }
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//  .mat-radio-label {
//    font-size: 1rem;
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    .mat-radio-label-content {
//      width: 100%;
//      @include text-crop;
//    }
//  }
//}
//
//.full-named {
//  .mat-mdc-radio-group {
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    .mat-radio-label {
//      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//      .mat-radio-label-content {
//        @include mq-sm-up {
//          max-width: initial;
//        }
//      }
//    }
//  }
//}
//
//.mat-mdc-radio-button {
//
//  &.mat-accent {
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    .mat-radio-inner-circle {
//      background-color: $dvtx-action;
//      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//      .mat-radio-checked & {
//        border-color: $dvtx-action;
//      }
//    }
//    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
//    .mat-radio-label {
//      display: flex;
//      align-items: center;
//      &-content {
//        align-items: center;
//        display: flex;
//        .mat-icon {
//          margin-right: 0.5rem;
//        }
//      }
//    }
//  }
//}
