.fivef-session-registration-form {
  width: 100%;
  display: flex;
  padding: 3rem 3rem 0 3rem;
  flex-direction: column;

  &--form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &--name-wrapper {
    display: flex;
    gap: var(--fivef-spacing-m);
  }

  &--email,
  &--firstname,
  &--lastname {
    flex-direction: column;
    margin-bottom: var(--fivef-spacing-l);
  }

  // TODO: This is not the correct link variable!
  a {
    color: var(--five-f-link-color);
  }

  a:hover {
    color: var(--five-f-link-color-hover);
  }

  &--password-wrapper {
    display: flex;
    align-items: center;
  }

  &--password-policy-info {
    color: var(--fivef-info-color-icon);
    margin-left: var(--fivef-spacing-m);
  }

  &--password-error-validation,
  &--wrong-password-error {
    min-height: 2rem;
    display: flex;
  }

  &--password-error-validation {
    &--success {
      color: var(--fivef-color-success);
    }

    &--warning {
      color: var(--fivef-color-status-open);
    }

    &--error {
      color: var(--fivef-color-warn);
    }
  }

  &--wrong-password-error {
    color: var(--fivef-color-warn);
  }
}
