@import "src/lib/styles/fivef/mixins/disable_input_hints_wrapper";

/**
 * Disables the hint area below mat-input/mat-select if unsused, to allow
 * centered / middle aligned styling with labels and other elements aside.
 */
.ff-disable-form-hint-area,
.fivef-disable-input-hints-wrapper {
  /**
   * Removes the hints and error indication area of mat-input
   * below the input field.
   */
  @include fivef-disable-input-hints-wrapper();
}
