/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//@import "src/lib/styles/base/var_legacy";
//@import "src/lib/styles/base/mixins";
//
//.mat-mdc-select {
//  margin-top: 2px;
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  .mat-select-trigger {
//    width: 100%;
//    padding: 0;
//    height: auto !important;
//    min-width: 1px;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  .mat-select-value {
//    padding: 10px 0;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  .mat-select-placeholder {
//    color: $dvtx-fivef-blue-300;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  .mat-select-underline {
//    background: $dvtx-fivef-blue-300;
//  }
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//  &.mat-select-white {
//    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//    .mat-select-value {
//      color: $dvtx-white;
//    }
//    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
//    .mat-select-arrow {
//      color: $dvtx-white;
//    }
//    /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
//    /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
//    & + .mat-input-placeholder-wrapper {
//      color: $dvtx-white;
//      /* TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version. */
//      .mat-input-placeholder {
//        color: $dvtx-white;
//      }
//    }
//  }
//}
