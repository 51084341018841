@import 'src/lib/styles/fivef/mixins/truncate';
@import 'src/lib/styles/fivef/mixins/link';

.fivef-cms-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 150px;
  width: 150px;
  border-radius: var(--fivef-border-radius-xs);
  border: 1px solid var(--fivef-color-text-secondary);
  background-repeat: no-repeat;
  background-size: cover;

  &--container {
    height: 150px;
    width: 150px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  &--title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    @include fivef-text-truncate;
  }

  &--actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &--add-button {
    @include fivef-link-highlight;
  }

  &--add-button:hover {
    cursor: pointer;
    color: var(--fivef-color-action);
    border: 1px solid var(--fivef-color-action);

    mat-icon {
      color: var(--fivef-color-action);
    }
  }
}
