.five-f-beta-feature-button-tag {
  position: absolute;
  bottom: -1px;
  right: -3px;
  opacity: 0.9;
  color: var(--five-f-filter-beta-tag-color);
  background-color: var(--five-f-filter-beta-tag-background-color);
  padding: 1px 2px;
  border-radius: 3px;
  border-collapse: collapse;
  font-size: 9px;
  box-shadow: 0 4px 4px -4px #c9c9c9 !important;
}
