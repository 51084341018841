/**
 * Standard <HR> tag styling.
 */
.fivef-divider {
  margin-bottom: var(--fivef-spacing-m);
  margin-top: var(--fivef-spacing-m);

  background: var(--color-border-secondary);

  &-* {
    background: var(--color-border-secondary);
  }

  &-l {
    margin-bottom: var(--fivef-spacing-l);
    margin-top: var(--fivef-spacing-l);
  }

  &-xl {
    margin-bottom: var(--fivef-spacing-xl);
    margin-top: var(--fivef-spacing-xl);
  }
}
