/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//.mat-mdc-card {
//
//  & .mat-mdc-card-header .mat-mdc-card-title {
//    background-color: $dvtx-bg;
//    font-size: 1rem;
//    padding: 1.5em $content-gap;
//    margin-bottom: 0;
//
//    &.flex {
//      display: flex;
//      flex-wrap: wrap;
//    }
//
//    & h1,
//    & h2,
//    & h3 {
//      margin: 0;
//      line-height: 1.1;
//    }
//  }
//
//  & .mat-mdc-card-content {
//    padding: $content-gap;
//
//    &:first-child {
//      padding-top: $content-gap*2;
//    }
//
//    &:only-child {
//      padding-top: $content-gap;
//    }
//    /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
//    &.mat-card-table-content {
//      padding: 0;
//    }
//
//    & .mat-mdc-card-footer {
//      margin: 0;
//    }
//  }
//
//  & > .mat-mdc-card-actions {
//    margin-left: 0;
//    margin-right: 0;
//    padding: $content-gap;
//  }
//
//  .mat-mdc-radio-group .mat-mdc-radio-button {
//    margin-top: 0.5rem;
//    margin-bottom: 0.25rem;
//  }
//
//  &.p-0 {
//    & .mat-mdc-card-footer {
//      margin: 0;
//
//      .mat-mdc-card-actions {
//        margin: 0;
//        padding: $content-gap;
//      }
//    }
//  }
//
//  &.card-content-devided {
//    padding: 0;
//
//    & .mat-mdc-card-content {
//      border-bottom: 1px solid $dvtx-fivef-blue-25;
//    }
//
//    & .mat-mdc-card-footer {
//      margin: 0;
//    }
//  }
//
//  &.card-aside {
//    padding: 0;
//
//    &:not([class*=mat-elevation-z]) {
//      @include box-shadow();
//    }
//
//    .mat-mdc-card-title {
//      background-color: $dvtx-bg;
//      font-size: 1rem;
//      padding: 1.5em $content-gap;
//      margin-bottom: 0;
//    }
//
//    & .mat-mdc-card-content {
//      padding: $content-gap;
//      border-bottom: 1px solid $dvtx-fivef-blue-25;
//    }
//
//    .mat-mdc-list {
//      .mat-mdc-list-item {
//        font-size: 1rem;
//        padding: $content-gap;
//        border-bottom: 1px solid $dvtx-fivef-blue-25;
//        &.active {
//          background-color: $dvtx-bg;
//          color: $dvtx-action;
//        }
//      }
//      .mat-item-icon {
//        position: absolute;
//        top: 0;
//        &.mat-Suffix {
//          right: -0.5em;
//        }
//        &.mat-Auffix {
//          left: 0;
//        }
//
//      }
//    }
//
//    & .mat-mdc-card-footer {
//      margin: 0;
//    }
//  }
//
//  .mat-mdc-table {
//    margin-bottom: $content-gap;
//    max-width: 100%;
//    overflow-x: auto;
//    -ms-overflow-style: -ms-autohiding-scrollbar;
//
//    @include mq-lg-down {
//      width: 100%;
//    }
//
//    .mat-mdc-row,
//    .mat-mdc-header-row {
//      &:last-child {
//        border-color: transparent;
//      }
//      @include mq-lg-down {
//        min-width: 760px;
//      }
//    }
//
//    .mat-mdc-header-row {
//      background-color: $dvtx-bg;
//      border-color: transparent;
//      padding: 0 $content-gap;
//
//      .mat-mdc-header-cell {
//        background-color: $dvtx-bg;
//        padding: $content-gap*0.5;
//        &:first-child {
//          padding-left: 0;
//        }
//      }
//    }
//
//    .mat-mdc-row {
//      padding: 0 $content-gap;
//
//      .mat-mdc-cell {
//        padding: $content-gap*0.5;
//        &:first-child {
//          padding-left: 0;
//        }
//
//        .dvtx-datapicker {
//          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//          .mat-form-field-wrapper {
//            // padding-bottom: 0;
//          }
//        }
//      }
//    }
//  }
//}
