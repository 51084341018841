.fivef-session-tfa {
  display: flex;
  padding: 14px;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-width: 500px;
  max-width: 620px;
  padding-top: 8rem;

  &--headline {
    align-self: center;
    text-align: center;
    margin-top: 13px;
    margin-bottom: var(--fivef-spacing-m);
  }

  .mat-horizontal-stepper-header-container {
    position: relative;
    top: -2rem;
    flex-wrap: wrap;
  }

  .mat-step-header:hover:not([aria-disabled]) {
    background-color: unset !important;
  }

  .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused {
    background-color: unset !important;
  }

  .mat-horizontal-content-container {
    overflow: visible !important;
    padding: 0 !important;
  }

  .mat-horizontal-stepper-header {
    padding: 0 !important;
  }

  &--container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: var(--fivef-spacing-m);
  }

  &--actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--auth-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
  }

  &--sms-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 30px;
    border-left: solid 0.2px #ccc;
  }

  &--code {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }

  &--qrcode {
    background-color: #fff;
    border: 1px solid #a7adb5;
    border-radius: 10px;
    padding: 8px;
    margin-bottom: var(--fivef-spacing-m);
  }

  &--telephone {
    .phone-number {
      margin-left: 10px;
      width: 220px;
    }
  }

  &--button-width {
    width: 226px;
  }
}
