/**
 * Workflow wizard sync settings.
 */
.fivef-grant-thornton-sync-settings {
  &--headline {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 0 !important;
  }

  &--icon {
    width: 46px;
  }

  &--container {
    cursor: pointer;
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    flex: 1 1 100%;
  }

  &--content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow: hidden;
    gap: 3px;
  }

  &--title,
  &--subtitle {
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    font-size: 0.9rem;
  }

  &--label {
    margin-bottom: 0 !important;
  }

  &--badge {
    padding: 0 7px;
    border-radius: 7px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--channel {
      background-color: rgb(114,87,153);
      color: white;
    }

    &--folder {
      background-color: rgb(185,171,204);
    }
  }
}
