/**
 * @deprecated.
 *
 * Use new 5F typogrphy setup instead.
 * These are very basic stylings so these are kept in base/
 */
@import '../base/var_legacy';
@import '../base/mixins';

$text-color: $dvtx-fivef-blue-500;
$text-line-height: 1.5;

$blockquote-color: $dvtx-fivef-blue-500;

$textinput-color: $dvtx-fivef-blue-300;

$lead-font-size: $base-size * 1.5;

$tooltip-font-size: $base-size * 1;

$lead-color: $dvtx-corporate-A500;

.text-mutted {
  color: $dvtx-fivef-blue-300;
}

html {
  font-size: $base-size;
}

p {
  font-size: 1rem;
  line-height: $text-line-height;

  &.bold {
    font-weight: 700;
  }

  &.text-mutted {
    color: $dvtx-fivef-blue-300;
  }

  &.dvtx-action {
    color: $dvtx-action;
  }

  &.dvtx-warning {
    color: $dvtx-danger;
  }

  &.dvtx-danger {
    color: $dvtx-warning;
  }

  &.label {
    font-family: inherit;
    font-size: 0.8125rem; // (13px)
    line-height: 1.35; // (18px)
    color: $text-color;
  }

  &.blockquote {
    font-style: italic;
    color: $blockquote-color;
  }

  &.textinput {
    line-height: $text-line-height;
    color: $textinput-color;
  }

  &.lead {
    font-size: $lead-font-size;
    color: $lead-color;
  }
}

.blockquote {
  font-style: italic;
  line-height: $text-line-height;
  color: $blockquote-color;
}

.textinput {
  line-height: $text-line-height;
  color: $textinput-color;
}

.lead {
  font-size: $lead-font-size;
  color: $lead-color;
}

h1, .h1 {
  font-size: 2rem;
  line-height: 1.1;
  font-weight: normal;

  &.super-title {
    font-size: 2.5rem;
    line-height: 1.08;
  }
}

h2, .h2 {
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: normal;

  @include mq-sm-down {
    font-size: 1.5rem;
  }

  &.subline {
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.3333;
  }
}

h3, .h3 {
  font-size: 1.25rem !important;
  line-height: 1.2;
  color: $text-color;
  font-weight: normal;
}

h4, .h4 {
  font-size: 1.1rem;
  line-height: 1.1;
  font-weight: normal;
}

h5, .h5 {
  font-size: 1rem;
  line-height: 1;
  font-weight: normal;
}

.text-crossed {
  text-decoration: line-through;
}

.mat-mdc-tooltip {
  font-size: $tooltip-font-size;
  font-weight: 400;
}

.dvtx-monotype,
.dvtx-tt,
.dvtx-mono {
  font-family: "Lucida Console", monospace;
}

.five-f-link {
  color: $dvtx-cool-grey-400;
  &:visited,
  &:active,
  &:link {
    color: $dvtx-cool-grey-400;
  }
  &:hover {
    color: $dvtx-fivef-blue-500;
    text-decoration: underline;
  }
  &.disabled {
    color: $dvtx-cool-grey-100;
  }
}

.five-f-section {
  margin-bottom: 3rem;
  &-title {
    margin-bottom: 1rem;
    color: $dvtx-fivef-blue-300;
    &-text {
      margin: 0;
      line-height: 42px;
      position: relative;
      color: inherit;
      &.underline:after {
        content: "";
        height: 0px;
        width: 100%;
        border-bottom: 2px solid $dvtx-fivef-blue-300;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &.leftline {
        padding-left: 1rem;
        &:before {
          content: "";
          height: 100%;
          width: 0;
          border-left: 5px solid $dvtx-fivef-blue-300;
          position: absolute;
          left: 0;
          margin: auto;
        }
      }
    }
    &.text-primary {
      color: $dvtx-blue-600;
      .five-f-section-title-text {
        &.underline:after {
          content: "";
          border-bottom: 2px solid $dvtx-blue-600;
        }

        &.leftline {
          &:before {
            border-left: 5px solid $dvtx-blue-600;
          }
        }
      }
    }
    &.text-danger {
      color: $dvtx-danger;
      .five-f-section-title-text {
        &.underline:after {
          content: "";
          border-bottom: 2px solid $dvtx-danger;
        }

        &.leftline {
          &:before {
            border-left: 5px solid $dvtx-danger;
          }
        }
      }
    }


  }
}

.no-background {
  background: none !important;
}

code {
  color: $dvtx-action;
  background-color: $dvtx-fivef-skyblue-100;
}
