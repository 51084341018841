.fivef-message-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  overflow: visible;

  &--error-container {
  }

  .fr-box.fr-basic.fr-top .fr-wrapper .fr-element.fr-view {
    overflow-y: auto;
    // height: -webkit-fill-available;
  }

  // Removes the dropdown triangle of font size. It does not fit nice with small icons and overlaps the icon.
  .fr-toolbar .fr-command.fr-btn.fr-dropdown:after, .fr-popup .fr-command.fr-btn.fr-dropdown:after, .fr-modal .fr-command.fr-btn.fr-dropdown:after {
    display: none;
  }

  .fr-box.fr-basic .fr-element {
    font-family: var(--fivef-font-family-display);
    color: var(--fivef-color-text-primary);
    font-size: var(--fivef-font-size-m);
    line-height: unset;
    padding: var(--fivef-spacing-s);
  }

  .fr-box.fr-basic.fr-top .fr-wrapper {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: var(--fivef-border-radius-s);
    transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: var(--fivef-color-input-background);
    border: 1px solid transparent;

    &:hover {
      background-color: var(--fivef-color-input-background-hover);
    }

    &:focus {
      //border: 1px solid var(--fivef-color-action);
      // background-color: var(--fivef-color-input-background);
      background-color: var(--fivef-color-input-background);
      border: 1px solid var(--fivef-color-input-background-focus);
    }

    p {
      font-size: inherit !important;
    }

    // Adds a min-height. Only one line should be shown if not in edit mode.
    .fr-element.fr-view {
      min-height: 40px !important;
      transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);

      &:focus {
        min-height: 100px !important;
      }
    }

    .fr-quick-insert {
      display: none;
    }

    .fr-placeholder {
      color: var(--fivef-color-input-placeholder);
      margin: var(--fivef-spacing-s) !important;
      padding: 0 !important;
    }
  }

  &--content-changed {
    // Removes the initial min-heght for a more compact appearance in non-editing mode
    // after content was changed.
    .fr-box.fr-basic.fr-top .fr-wrapper,
    .fr-box.fr-basic.fr-top .fr-wrapper .fr-element.fr-view,
    .fr-box.fr-basic.fr-top .fr-wrapper .fr-element.fr-view:focus {
      min-height: 100px !important;
      height: auto;

      // Removes the inner Froala overflow/max-height setup in edit mode.
      max-height: unset !important;
    }
  }

  // Froala smaller button row, smaller buttons
  .fr-btn-grp {
    // Removes outer margins.
    margin: 0;
  }

  // Resizes the inner SVG images and removes the margins.
  .fr-toolbar .fr-command.fr-btn i,
  .fr-toolbar .fr-command.fr-btn svg,
  .fr-popup .fr-command.fr-btn i,
  .fr-popup .fr-command.fr-btn svg,
  .fr-modal .fr-command.fr-btn i,
  .fr-modal .fr-command.fr-btn svg {
    margin: 5px 5px;
    width: 20px;
  }

  // End: Froala smaller button row, smaller buttons

  &--actions {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
}
