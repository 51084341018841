
/**
 * Global material input overrides.
 * Goal: New fresh layout:
 * - dense
 * - without underline,
 * - light background
 * - focus and hover effect
 * - rounded corners.
 * - based on fivef-vars.
 */

// Scale form fields to their container.
// E.g. login input default behavior.
.mat-mdc-form-field {
  width: 100%;
}

// <Prefix behavior>.
// Attention: MatAffix directive must be replaced when icon is used as input prefix.
.mat-mdc-form-field .mat-mdc-form-field-icon-prefix {
  display: flex;
  padding: 0 var(--fivef-spacing-xs);

  // The default mini-fab is too large. Reduction to 32px x 32px square.
  .mat-mdc-mini-fab {
    width: 32px;
    height: 32px;

    // The default mini-fab drags in a mat-elevation. This is removed to make
    // it flat and more consistend inside the form.
    box-shadow: unset;
  }

  // The default mat-icon-button is reduced as mini-fab for consistent behavior
  // as suffix icon based action.
  .mat-mdc-icon-button.mat-mdc-button-base {
    height: 32px;
    width: 32px;
    padding: 0;
  }

  > mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
    padding: 0;
  }
}
// </Prefix behavior>.


// <Suffix behavior>.
// Example: Right aligned login enter button inside mat-form-field at login page.
.mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
  padding: 0 var(--fivef-spacing-xs);

  // The default mini-fab is too large. Reduction to 32px x 32px square.
  .mat-mdc-mini-fab {
    width: 32px;
    height: 32px;

    // The default mini-fab drags in a mat-elevation. This is removed to make
    // it flat and more consistend inside the form.
    box-shadow: unset;
  }

  // The default mat-icon-button is reduced as mini-fab for consistent behavior
  // as suffix icon based action.
  .mat-mdc-icon-button.mat-mdc-button-base {
    height: 32px;
    width: 32px;
    padding: 0;
  }

  > mat-icon {
    height: 20px;
    width: 20px;
    font-size: 20px;
    padding: 0;
  }
}
// </Suffix behavior>.

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: var(--fivef-spacing-xxs);
  padding-bottom: var(--fivef-spacing-xxs);
}

// Standard background, background on hover and at focus mode.
.mat-mdc-form-field-focus-overlay {
  // Original test value.
  // background-color: var(--fivef-input-color-background-focus);
  // opacity: 0.04 !important;
  background-color: var(--fivef-color-input-background) !important;
  opacity: 1 !important; // 0.04 !important;
}

// The overlay is controlling the background color.
// The original setup is working with a background color and
// is changing the opacity for hover and focus. The default opacity
// is 0 (no background).
// This works for inputs and mat selects/dropdowns.
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  // opacity: 0.08 !important; // Original test value
  background-color: var(--fivef-color-input-background-hover);
  opacity: 1 !important;
}

// Focus variant of the form field.
.mat-mdc-form-field:focus .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  // opacity: 0.12 !important; // Original test value
  background-color: var(--fivef-color-input-background-focus);
  opacity: 1 !important;
}

// Reduces the bottom space below input text and bottom border.
.mat-mdc-form-field-infix {
  min-height: 32px;
}

// Form field shape with rounded corners.
.mdc-text-field--filled {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;

  border-top-left-radius: var(--fivef-border-radius-xs) !important;
  border-top-right-radius: var(--fivef-border-radius-xs) !important;
  border-bottom-right-radius: var(--fivef-border-radius-xs) !important;
  border-bottom-left-radius: var(--fivef-border-radius-xs) !important;
}

// Disabled state of the mat-input.
.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: var(--fivef-color-input-background-disabled) !important;
  opacity: 1 !important;
}

// Removed underline.
.mdc-line-ripple {
  display: none;
}

/**
 * Show red ripple on invalid fields.
 */
.mat-form-field-invalid .mdc-line-ripple {
  display: block;
}
