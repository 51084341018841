@import 'src/lib/styles/fivef/mixins/truncate';

fivef-avatar,
fivef-avatar-shape {
  display: inline-block;

  // Required padding to embed the hook in overflow hidden mode.
  padding: 3px;

  @include fivef-text-truncate();
}

.fivef-avatar {
  display: flex;
  align-items: center;

  &--user-info {
    margin-left: var(--fivef-spacing-xs);
    @include fivef-text-truncate();

    &--name {
      @include fivef-text-truncate();
      font-weight: 400;
      margin-bottom: 0;
    }

    &--email {
      @include fivef-text-truncate();
      font-size: var(--fivef-font-size-xs);
      margin-bottom: 0;
      font-weight: 300;
    }
  }

  &-icon-placeholder {
    position: relative;
  }

  .mat-mdc-fab:not(button) {
    cursor: default;
  }

  .fivef-avatar-icon {
    padding: 2px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: var(--fivef-avatar-box-shadow);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
      overflow: hidden;
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .mat {
      &-button-wrapper {
        background: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-content: center;
        line-height: 1;
      }

      &-icon {
        height: auto;
        width: auto;
        margin: auto;
      }
    }

    [class^=mat] {
      font-size: inherit;
      color: inherit;
    }
  }

  .fivef-avatar-size-xxs {
    width: 18px;
    height: 18px;
    font-size: .6rem;
    font-weight: 700;
  }

  .fivef-avatar-size-xs{
    width: 22px;
    height: 22px;
    font-size: .7rem;
    font-weight: 700;
  }

  .fivef-avatar-size-sm {
    width: 32px;
    height: 32px;
    font-size: 1rem;

  }
  .fivef-avatar-size-md {
    width: 42px;
    height: 42px;
    font-size: 1.3rem;
  }
  .fivef-avatar-size-lg {
    width: 56px;
    height: 56px;
    font-size: 2.3rem;
  }
  .fivef-avatar-size-xl {
    width: 128px;
    height: 128px;
    font-size: 2.3rem;
  }

  p, h4 {
    line-height: normal !important;
  }
}

mat-icon.fivef-avatar-verification--icon {
  position: absolute;
  margin: 0px;
  bottom: -0.25rem;
  right: -0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--fivef-avatar-status-shape);

  .fivef-avatar-xs & {
    height: 1rem;
    width: 1rem;
    font-size: 1rem !important;
  }

  .fivef-avatar-sm & {
    height: 1.25rem;
    width: 1.25rem;
    font-size: 1.25rem !important;
  }

  .fivef-avatar-md & {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem !important;
  }

  .fivef-avatar-lg & {
    height: 1.75rem;
    width: 1.75rem;
    font-size: 1.75rem !important;
  }

  .fivef-avatar-xl & {
    height: 1.75rem;
    width: 1.75rem;
    font-size: 1.75rem !important;
  }

  &.fivef-avatar-member-check {
    color: var(--fivef-avatar-color-status-member);
  }

  &.fivef-avatar-check {
    color: var(--fivef-avatar-color-status-verified);
  }
}
