/**
 * @deprecated
 * Former version from vendors/angular-material/components
 * Kept for documentation until fully migrated.
 */
//@import "../../../utils/var";
//@import "../../../utils/mixins";
//
//.mat-mdc-input-element {
//  height: auto;
//  border-bottom:none!important;
//}
