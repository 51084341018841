.fivef-session-registration-complete {
  width: 100%;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;

  &--checklist-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  &--checklist-icon {
    margin-right: var(--fivef-spacing-m);
  }

  &--checklist-icon-success {
    color: var(--fivef-color-success);

  }

  &--in-progress {
    color: var(--fivef-color-status-in-progress);
  }

  &--center {
    display: flex;
    align-self: center;
  }

  &--button {
    background-color: var(--fivef-color-action) !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border: none;
    cursor: pointer;
    color: var(--fivef-color-surface-primary) !important;

    &:disabled {
      background-color: var(--fivef-color-fivef-cool-gray-300) !important;
    }
  }
}
