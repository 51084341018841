/**
 * Spinner as loading indicator inside a mat button.
 * Use diameter 16.
 * Note: NG >= 16 does not support inner wrappers otherwise
 * the prefix directive is not evaluated.
 *
 * Example
 *   <button mat-button
 *           [color]="'primary'"
 *           [disabled]="!process || !process?.status?.isClosed() || !process?.canBeReopened"
 *           (click)="openReopenProcessDialog()">
 *     <mat-spinner *ngIf="submitOnGoing" matPrefix color="primary" class="fivef-button-spinner"
 *                  diameter="16"></mat-spinner>
 *     <mat-icon *ngIf="!submitOnGoing" matPrefix>settings_backup_restore</mat-icon>
 *     {{ 'WORKFLOWS.REOPEN_WORKFLOW_ACTION' | translate }}
 *   </button>
 */
.mat-mdc-progress-spinner.fivef-button-spinner {
  display: inline-block;
  top: 3px;
  height: 16px;
  width: 16px;
}
