@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * 5F line item.
 * Left horizontal color bar (optional), beside an optional icon or image and the content.
 * See also the directives fivefLineItemTitle, -Subtitle and -Icon for
 * dynamically added classes to given content. See directive classes below.
 */
.fivef-line-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  // Set a min-height to have a slightly overlapping sidebar in single content line context
  // e.g. at partnerlinks. The left bar should appear aigned for larger contexts
  // like processes or comments.
  min-height: 40px;

  overflow: hidden;
  margin-bottom: var(--fivef-spacing-s);
  position: relative;
  cursor: pointer;

  [fivef-line-item-action] {
    display: none;
  }

  // Background highlighting on mouse over.
  &:hover {
    background-color: var(--fivef-color-surface-tertiary);

    [fivef-line-item-action] {
      display: inline-block;
    }
  }

  // Line item icon directive.
  &-icon {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // Line item title directive.
  &-title {
    font-size: var(--fivef-font-size-l);
    font-weight: 500;
    line-height: normal;
    @include fivef-text-truncate;
  }

  // Line item sub title directive.
  &-subtitle,
  &-subtitle fivef-process-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--fivef-font-size-xs) !important;
    line-height: normal;
    color: var(--fivef-color-text-tertiary);
    @include fivef-text-truncate;

    mat-icon {
      font-size: var(--fivef-font-size-xs) !important;
      line-height: var(--fivef-font-size-xs) !important;
      width: var(--fivef-font-size-xs) !important;
      height: var(--fivef-font-size-xs) !important;
    }
  }

  // Left color bar. Full height.
  &--color {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-radius: 1.5px;
  }

  // Color bar spacer shifting the content to the right.
  &--color-spacer {
    margin-right: var(--fivef-spacing-m);
  }

  // The icon or base base styling (outer container).
  &--icon {
    height: 24px;
    // Style fix: Optical ilusion:
    // To get a square for the visual we need to give it more width then height.
    width: 28px;
    overflow: hidden;
    border-radius: var(--fivef-border-radius-s);
    margin-right: var(--fivef-spacing-s);
    background-color: white;
  }

  &--content {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    @include fivef-text-truncate;
  }
}
