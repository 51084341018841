.fivef-session-login {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-width: 500px;
  max-width: 620px;
  padding-top: 8rem;

  &--form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  // TODO: This is not the correct link variable!
  a {
    color: var(--five-f-link-color);
  }

  a:hover {
    color: var(--five-f-link-color-hover);
  }

  /**
   * "Login" title at header and TFA state headline.
   */
  &--headline,
  &--headline-tfa {
    align-self: center;
    margin-top: var(--fivef-spacing-xs);
    margin-bottom: var(--fivef-spacing-xxl);
  }

  &--headline {
    margin-bottom: 2rem;
  }

  /**
   * Tenant login welcome message above login.
   */
  &--welcome-message {
    align-self: center;
    padding: 0 var(--fivef-spacing-xs);
    margin-bottom: 2rem;
  }

  /**
   * Alternative logo above login.
   * Hidden by default and activated by theme.
   */
  &--welcome-logo {
    display: none;
  }

  /**
   * E-Mail form field.
   * The input itself can be directed by > input
   * The wrapper encloses the input form field area.
   */
  &--email {
    &-wrapper {}
  }

  &--readonly-email {
    margin: 20px var(--fivef-spacing-xs);

    a {
      float: right;
    }
  }

  /**
   * Password form field.
   * The input itself can be directed by > input
   * The wrapper encloses the input form field area.
   */
  &--password {
    &-wrapper {}

    &-icon {
      color: var(--fivef-color-dark-grey-900);
    }
  }

  /**
   * Password reset area below login.
   * Contains also the imprint link for tenant login setups.
   * Error messages are below.
   */
  &--reset-password-link {
    padding: var(--fivef-spacing-xs);
  }

  /**
   * Error message area below login and password reset.
   */
  &--error-message {
    max-width: 500px;
    display: flex;
    justify-content: flex-start;
    font-size: .9rem;
    color: var(--fivef-color-warn);
    margin-bottom: 2rem;
  }

  &--button-container {
    display: flex;
    justify-content: end;

    .fivef-session-login--button {
      margin-top: var(--fivef-spacing-m);
      width: 40%;
      height: 38px !important;
    }

    /**
     * Alternative e-email check button below form.
     */
    .fivef-session-login--alternative-sign-in-button {
      display: none;
    }
  }

  /**
   * System maintenance container at login.
   */
  &--system-maintenance {
    color: var(--fivef-color-info);
    margin: 10px 6px;
    font-size: 9pt;
    right: 0;
  }

  &--tfa-container {
    padding: 0 3rem;
  }
}
