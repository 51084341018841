.fivef-csv-import-dropzone {
  background-color: var(--fivef-upload-background-color);
  display: block;

  &:hover {
    color: var(--fivef-color-text-upload-hover);

    button {
      color: var(--fivef-color-text-upload-hover);
    }
  }

  &--drag-n-drop-icon {
    font-size: 2rem;
    line-height: 1;
  }

  &--drag-n-drop-btn {
    button {
      border: 1px solid var(--fivef-color-border-primary);
      margin-bottom: 7.5px;
      white-space: normal;
      background: transparent;
      cursor: pointer;
      padding: var(--fivef-spacing-s);
    }

    button:hover {
      border-color: transparent;
      background-color: var(--fivef-upload-background-color-hover);
    }
  }

  &--side-note {
    font-size: 10px !important;
    line-height: 1;
    margin-top: -0.8rem;
  }
}
