@import 'src/lib/styles/fivef/mixins/menu';

/**
 * Action menu, e.g. for process state or external link settings view.
 * Setup: Two lines with subtext.
 */
.fivef-process-state-actions {
  &--button-loading {
    margin-right: var(--fivef-spacing-xs);
  }

  &--menu {
  }
}
