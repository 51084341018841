.fivef-status-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--fivef-spacing-xxs) 0;

  &--icon {
    display: flex;
    margin-right: var(--fivef-spacing-xs);
  }

  &--title {
    font-size: var(--fivef-font-body-l);
  }
}
