/**
 * Generic upload dropzone.
 * Contains two appearances: Widget (project room, sidebar) and cell (Collecto, documents listing).
 *
 * See also layout class ff-drag-n-drop.
 */
.fivef-upload {
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &--outer-cell-wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    width: 100%;
    min-width: 200px;
    max-width: 400px;

    // The height is retrieved from the download items.
    // Height is required if the upload button is in a new row.
    height: 65px;
  }

  &--icon {
    margin: var(--fivef-spacing-m) auto var(--fivef-spacing-s) auto;

    mat-icon {
      font-size: 40px;
    }
  }

  &--dropzone {
    padding: 0;
    height: 100%;

    &.fivef-upload--file-over {
      border: 1px solid var(--fivef-upload-border-color-hover) !important;
    }

    &--disabled {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: var(--fivef-spacing-m) 0 var(--fivef-spacing-l) 0;
      color: var(--fivef-color-text-disabled);

      mat-icon {
        margin-right: var(--fivef-spacing-m);
      }
    }
  }

  &--info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--fivef-spacing-m);

    mat-icon {
      margin-left: var(--fivef-spacing-xs);
      color: var(--fivef-info-color-icon);
    }
  }

  &--appearance-widget {
    width: 100%;

    .fivef-upload--dropzone {
      padding: 0 0 var(--fivef-spacing-l);
    }

    .fivef-upload--file-upload-label {
      position: relative;
      font-size: 14px;
      cursor: pointer;
    }

    .btn-outline {
      padding: 10px;
      border: 1px solid var(--fivef-upload-button-border-color);
      transition: 100ms linear;

      .fivef-upload--button-outside {
        position: absolute;
        left: -9999px;
        visibility: hidden;
      }

      &:hover {
        background-color: var(--fivef-upload-button-background-color-hover);
        border-color: var(--fivef-upload-button-border-color-hover);
        color: var(--fivef-upload-button-color-hover);
      }
    }
  }

  &--appearance-cell {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex: 1 1 100%;

    .fivef-upload--file-upload-label {
      position: relative;
      font-size: 14px;
      cursor: pointer;
    }

    .btn-outline {
      padding: 10px;
      border: 1px solid var(--fivef-color-border-primary);
      transition: 100ms linear;

      .fivef-upload--button-outside {
        position: absolute;
        left: -9999px;
        visibility: hidden;
      }

      &:hover {
        background-color: var(--fivef-upload-background-color-hover);
        border-color: transparent;
        color: var(--fivef-color-text-upload-hover);
      }
    }

    .btn-outline-lock {
      padding: 10px;
      border: 1px solid var(--fivef-color-border-primary);
      transition: 100ms linear;

      .fivef-upload--button-outside {
        position: absolute;
        left: -9999px;
        visibility: hidden;
      }
    }

    .fivef-upload--file-upload-label {
      margin: 0;
      width: 100%;
      padding: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 100%;
      border: none !important;
    }

    .fivef-upload--loading-state {
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      background: var(--fivef-color-border-disabled);
      z-index: 1;
    }
  }

  /**
   * Progress section of the upload with cancel button.
   * Height is animated from 0 to 40px when uploading.
   */
  &--progress-bar {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: height 0.25s ease-in;
    padding: 0 var(--fivef-spacing-m);

    &--uploading {
      height: 40px;
    }

    mat-progress-bar {
      height: 3px !important;

      // Rounded ends.
      border-radius: var(--fivef-loading-indicator-progressbar-border-radius);
    }
  }
}
