.fivef-rich-text {
  &--readonly-container {
    border-radius: var(--fivef-border-radius-s);
    background-color: var(--fivef-color-input-background);
    transition: background-color .3s 0s cubic-bezier(0.4, 0, 0.2, 1);

    &--editor {
      min-height: 2rem;
      padding: var(--fivef-spacing-xxs);
      transition: padding .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      background-color: var(--fivef-input-color-background);
      border-radius: var(--fivef-border-radius-s);
      cursor: text;
    }

    &--editor:hover {
      padding: var(--fivef-spacing-xs) var(--fivef-spacing-s);
    }
  }
}
