.ff-content-container-width {
  max-width: var(--fivef-content-container-width);
}

.ff-section-title {
  margin-bottom: var(--fivef-spacing-l);
}

.ff-content {
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: var(--fivef-spacing-l);
  gap: var(--fivef-spacing-s);

  &-fill {
    display: flex;
    flex: 1 1 100%;
  }

  &-s {
    width: 200px;
  }

  &-column {
    display: flex;
    flex-direction: column;
  }

  &-align-center {
    align-items: center;
  }

  &-2 {
    // Every contained element of DOM 50%;
    > * {
      display: flex;
      flex-direction: column;
      width: 50%;
    }
  }

  &-3 {
    display: flex;
    width: 100%;
    flex-direction: row;

    > * {
      display: flex;
      flex-direction: column;
      width: 33%;
    }
  }
}

.ff-content > .ff-content-row {
  display: flex;
  flex-direction: row;
}
