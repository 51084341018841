.fivef-session-password-reset {
  padding-top: 0;

  // Put a padding inside the form for the container.
  &--form {
    width: 80%;
  }

  /**
   * Request send button area.
   * Make the button full width according design.
   */
  &--button-container {
    display: flex;
    justify-content: flex-start;

    // Create a margin to the back link because of the full width button.
    margin-bottom: var(--fivef-spacing-m);

    .fivef-session-password-reset--button {
      width: 100%;
    }
  }
}
