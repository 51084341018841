.fivef-template-tag {
  border-radius: 11px !important;
  font-size: 11px;
  line-height: 10px;
  font-weight: 400;
  height: 18px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  color: var(--fivef-color-text-inverse);
  background-color: var(--fivef-color-teal);
  opacity: 0.9;
  padding: var(--fivef-spacing-xxs) var(--fivef-spacing-m);
  margin: 0 var(--fivef-spacing-s);
}
