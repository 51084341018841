.five-f-elevation-z {
  &-0 {
    box-shadow: 0 0 0 !important;
  }
  &-1 {
    box-shadow: 0 4px 4px -4px #c9c9c9 !important;
  }
  &-2 {
    box-shadow: 0 8px 8px -8px #c9c9c9 !important;
  }
  &-3 {
    box-shadow: 0 12px 12px -12px #c9c9c9 !important;
  }
  &-4 {
    box-shadow: 0 16px 16px -16px #c9c9c9 !important;
  }
  &-5 {
    box-shadow: 0 20px 20px -20px #c9c9c9 !important;
  }
}
