/**
 * Transparent overlay for menus.
 * Used at message editor and reactions/seen by.
 */
.cdk-overlay-transparent-auto-size-backdrop {
  width: auto !important;
  height: auto !important;
  left: auto !important;
  right: auto !important;
  top: auto !important;
  bottom: auto !important;
}
