.third-party-header-status-color-success {
  color: var(--fivef-color-success-alt);
}

.third-party-header-status-color-in-progress {
  color: var(--fivef-color-status-in-progress);
}

.third-party-header-status-color-open {
  color: var(--fivef-color-warn-alt);
}

.third-party-header-status-color-danger {
  color: var(--fivef-color-warn);
}

.third-party-status-color-success {
  background-color: var(--fivef-color-success-alt);
  color: white;

  &:hover {
    background-color: var(--fivef-color-success-alt);
  }
}

.third-party-status-color-in-progress {
  background-color: var(--fivef-color-status-in-progress);
  color: white;

  &:hover {
    background-color: var(--fivef-color-status-in-progress);
  }
}

.third-party-status-color-open {
  background-color: var(--fivef-color-status-open);
  color: white;

  &:hover {
    background-color: var(--fivef-color-status-open);
  }
}

.third-party-status-color-danger {
  background-color: var(--fivef-color-warn-alt);
  color: white;

  &:hover {
    background-color: var(--fivef-color-warn-alt);
  }
}
