* {
  scrollbar-width: thin !important;
  scrollbar-color: #cccccc white !important;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

*::-webkit-scrollbar-track {
  background: none !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #cccccc !important;
  border-radius: 6px !important;
  border: 3px solid white !important;

  &:hover {
    background-color: $dvtx-cool-grey-300 !important;
  }
}
