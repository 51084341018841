/**
 * Theme overrides of the main session container.
 */
.fivef-session {
  // Enables the carousel.
  &--carousel {
    display: flex;
    flex: 1;
    z-index: 1;
    min-width: 700px;
  }

  /**
   * Content area.
   */
  &--content {
    flex: 0.7;
    margin: 3rem 0;
    min-width: 530px;
  }

  // Enables the alternative language switcher at the bottom of the inner frame.
  &--alternative-language-switcher {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: var(--fivef-spacing-xxl) auto;
  }

  // Disable the page header.
  &--page-header {
    display: none;
  }

  // Moves the logo from the left (default) to the right side (theme design).
  &--logo-header {
    display: flex;
    justify-content: end;
    margin-right: var(--fivef-spacing-m);
  }

  // Main container override to span full height without banner.
  &--container {
    height: 100vh !important;
    background-size: cover;
  }

  /**
   * Main container setup with rounded corners, and background color.
   * Content is left, carousel slideshow is shown on the right side.
   */
  &--main {
    max-width: 98%;
    min-width: 75%;
    max-height: 83%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    flex-direction: row-reverse;

    background-color: rgba(35, 50, 70, 0.96); // var(--fivef-color-fivef-blue-900);
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 0 0 6rem 0 rgba(0, 0, 0, 0.288);
  }
}
