@import "src/lib/styles/fivef/mixins/menu";
@import "src/lib/styles/fivef/mixins/truncate";

.fivef-avatar-group {
  &--listing {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }

  &--list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    // Shrink avatars width. Put them closer together.
    margin-left: -7px;
  }

  &--more-indicator {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--fivef-color-button-secondary);
    border-radius: 50%;
    color: var(--fivef-color-button-text);
    font-size: 10px;
    cursor: pointer;
  }

  &--menu {
    max-height: 200px;
    overflow: auto;
    overflow-x: hidden;

    @include fivef-menu();
    @include fivef-text-truncate();

    &--listing {
      display: flex;
      flex-direction: column;
      margin: 0;
      width: 100%;
    }

    &--list-item {
      width: 100%;
      padding: var(--fivef-spacing-xxs) 0;
      @include fivef-text-truncate();
    }
  }
}
