.fivef-node-column-settings {
  &--draggable-list {
    max-width: 100%;
    border: solid 1px var(--fivef-color-border-secondary);
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
    overflow: hidden;
  }

  &--draggable-item {
    display: flex;
    padding: 5px;
    border-bottom: solid 1px var(--fivef-color-border-secondary);
    color: var(--fivef-color-text-primary);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
  }

  &--visibility {
    min-width: 280px;
  }

  &--title-container {
    display: flex;
    align-items: center;
  }

  &--icon {
    color: var(--fivef-color-button-secondary);
  }

  &--title {
    color: var(--fivef-color-text-primary);
    display: flex;
    align-items: center;
  }

  &--subtitle {
    color: var(--fivef-color-text-tertiary);
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
    background: white;
  }

  // Example for placeholder.
  //.cdk-drag-placeholder {
  //  opacity: 0;
  //  background-color: red;
  //}

  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  &--draggable-item:last-child {
    border: none;
  }

  &--draggable-list.cdk-drop-list-dragging .fivef-node-column-settings--draggable-item:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}
