@use '@angular/material' as mat;
/**
 * Copyright 5FSoftware GmbH 2017
 *
 * Author: Andreas Baier.
 *
 */

/**
 * Reboot and tag normalization.
 * Attention: style normalizations from main files are loaded first (angular.json).
 */
@import "lib/styles/base/normalize";

/**
 * Variables and mixins.
 *
 * The util/ folder gathers all scss tools and helpers we’ll use across the project. Got a function? A mixin?
 * Put it in there. This folder also contains var.scss file (sometimes _config.scss) which holds all global variables
 * for the project (for typography, color schemes, and so on)
 */
@import "lib/styles/base/var_legacy";
@import "lib/styles/base/mixins";


/**
 * Angular Material IO
 *
 * Custom angular material io configuration.
 */
@import "lib/styles/base/config";

/**
 * Material overrides
 */
@import "lib/styles/override/index";

/*
 * Wysiwyg / Froala.
 */
@import "../node_modules/froala-editor/css/froala_editor.pkgd.min.css";
@import "../node_modules/froala-editor/css/froala_style.min.css";


/*
 * Flag icon css
 */
$flag-icon-css-path: '~flag-icon-css/flags' !default;
@import "flag-icon-css/sass/flag-icon";

/*
 * BASE
 *
 * The base/ folder holds what we might call the boilerplate stuff for your project. In there, you might find the reset
 * (or Normalize.css, or whatever), probably some stuff dealing with typography, and, depending on the project, maybe
 * some other files.
 *
 * NOTE: Legacy files are listed before current official ones to now override concerns.
 */
@import "lib/styles/base/color_legacy";
@import "lib/styles/base/color";
@import "lib/styles/base/color_dark";
@import "lib/styles/base/link";
@import "lib/styles/base/layout";
@import "lib/styles/base/spacing_legacy";
@import "lib/styles/base/spacing";
@import "lib/styles/base/scrollbars";
@import "lib/styles/base/typography_legacy";
@import "lib/styles/base/typography";

/**
 * Legacy styling of old components.
 */
@import "lib/styles/legacy/index";

/*
 * 5F UI lib default styles.
 */
@import "lib/styles/fivef/index";

/**
 * 5F session themes.
 */
@import "lib/styles/themes/session/index";

/*
 * Print overrides
 */
@import "lib/styles/base/print";

/*
 * 5F platform themes.
 */
@import "lib/styles/themes/platform/index";
