@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * Menu item styling.
 * Example implementations can be found at the main action menu and workflow
 * starter menu inside projects.
 */
.fivef-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 600px;
  padding: var(--fivef-spacing-xs) var(--fivef-spacing-m);
  background-color: var(--fivef-color-surface-primary);

  // transition: background-color 0.1s ease-in, background-color 0.3s ease-out;
  transition: all .3s 0s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  border-radius: var(--fivef-border-radius-s);

  &:hover {
    background-color: var(--fivef-color-surface-primary-highlight);
  }

  &--icon {
    margin-right: var(--fivef-spacing-m);
  }

  .fivef-button-spinner.fivef-menu-item--icon {
    width: 22px;
    height: 22px;
  }

  &--content {
    display: flex;
    flex-direction: column;
    @include fivef-text-truncate;
  }

  &--title {
    color: var(--fivef-color-text-primary);
    display: block;
    @include fivef-text-truncate;
  }

  &--subtitle {
    color: var(--fivef-color-text-tertiary);
    font-size: var(--fivef-font-size-xs) !important;
    display: block;
    @include fivef-text-truncate;
  }

  &--disabled,
  &--disabled .fivef-menu-item--title,
  &--disabled .fivef-menu-item--subtitle,
  &--disabled .fivef-menu-item--icon {
    pointer-events: none;
    color: var(--fivef-color-text-disabled);
  }
}
