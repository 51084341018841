/**
 * Smaller material icon button for buttons as actions beside inputs or other active elements.
 * Examples: Process settings > general details, fivef-info and fivef-input-label.
 */
.ff-icon-sm.mat-mdc-icon-button {
  --mdc-icon-button-state-layer-size: 32px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 0;

  mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
    line-height: 20px;

    svg {
      font-size: 20px;
      height: 20px;
      width: 20px;
      line-height: 20px;
    }
  }
}
