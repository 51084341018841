@import "../../base/var_legacy";
@import "../../base/mixins";

/**
 * Contains the setting boxes at organization and personal settings.
 * See also shared/modules/dashboard-panels
 * TODO: Refactor/remove.
 */
.listing-box {
  margin-bottom: $content-gap*2;
  background: none;
  box-shadow: none;
  &.card {
    box-sizing: content-box;
  }
  &__box-header {
    background-color: $dvtx-bg;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: $content-gap*2 $content-gap;
    // min-height: 125px;

    &.light {
      .title-icon {
        .mat-icon {
          color: rgba(255, 255, 255, 0.75);
        }
      }

      .box-header-title {
        color: rgba(255, 255, 255, 0.75);
      }
    }

    &.dark {
      .title-icon {
        .mat-icon {
          color: rgba(30, 30, 30, 0.75);
        }
      }

      .box-header-title {
        color: rgba(30, 30, 30, 0.75);
      }
    }

    &-graph {
      padding-top: $content-gap;
      padding-bottom: $content-gap;
    }

    .title-icon {
      margin-right: $content-gap;
      .mat-icon {
        color: rgba(255, 255, 255, 0.75);
        font-size: 4rem;
        max-width: 4rem;
        height: auto;
        width: auto;
      }
    }

    .box-header-title {
      color: rgba(255, 255, 255, 0.75);

      .events-amount {
        font-size: 4rem;
        line-height: 1.1;
        font-weight: 300;
      }

      .simple-panel-title {
        font-size: 1.25rem;
      }

      .header-select {
        flex: 0 0 100px;
        width: 100px;
      }
    }

    .header-image {
      margin-top: $content-gap;
      flex: 1 1 auto;
      width: 100%;

      .for-img {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 100%;
          height: auto;
        }
      }
    }
  }

  &__box-body {
    &__item {
      cursor: pointer;
      padding: $content-gap;
      border-bottom: 1px solid $dvtx-fivef-blue-25;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover,
      &:focus {
        background-color: $dvtx-bg;
      }

      &-nodata {
        cursor: default;

        &:hover,
        &:focus {
          background-color: inherit;
        }
      }

      .link-sign {
        margin-left: $content-gap;
      }

      h3 {
        font-weight: 500;
      }

      .listing-status {
        .mat-icon {
          font-size: 1.143rem;
          height: auto;
          width: auto;
          vertical-align: sub;
        }
      }
    }

    &__box-graph {
      overflow: hidden;
      padding-top: $content-gap;
      padding-bottom: $content-gap;

      @include mq-md-up {
        padding: $content-gap $content-gap;
      }
    }
  }

  &__box-footer {
    display: flex;
    justify-content: flex-end;

    .box-footer-btn {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 30px 25px;
      font: 18px/24px Roboto, "Helvetica Neue", sans-serif;
      color: $dvtx-fivef-blue-300;
      cursor: pointer;

      &:hover {
        background: #f5f5f5;
      }
    }
  }
}

.box-graph {
  padding: 1.25rem;
}
