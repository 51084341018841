// angular-upgrade - froala css changes\
.fr-toolbar.fr-top {
  border-radius: 0px !important;
}

.fr-toolbar {
  border: none !important;
  background-color: transparent !important;
}

.fr-second-toolbar {
  display: none !important;
  background-color: transparent !important;
}

.fr-box.fr-basic .fr-wrapper {
  border: 0px !important;
}

.fr-popup {
  z-index: 99999999 !important;
}

// froala mention feature
.fr-tribute {
  color: $dvtx-blue-600;
}

.tribute-container {
  ul {
    padding: 3px !important;
    zoom: 85%;
  }

  position: absolute;
  box-shadow: 0 4px 4px 0px #c9c9c9;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
}

.mention-check-icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.mention-verification {
  position: absolute;
  margin: 0px;
  margin-top: -12px;
  left: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(circle, white 0%, white 50%, transparent 50%, transparent 100%);

  &.member-check {
    color: $dvtx-fivef-blue-500;
  }

  &.check {
    color: $dvtx-success;
  }
}

[data-mention-avatar]:before {
  content: attr(data-mention-avatar);
  border-radius: 50%;
  padding: 2px;
  overflow: hidden;
  box-shadow: 0 0 6px -3px #8a8a8a;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  background-color: rgb(0, 144, 207);
  border-color: rgb(0, 144, 207);
}

.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background-color: white;
}

.tribute-container li {
  padding: 5px 5px;
  cursor: pointer;
}

.tribute-container li.highlight,
.tribute-container li:hover {
  background: $dvtx-fivef-blue-100;
}

.tribute-container li span {
  font-weight: bold;
}

.tribute-container li.no-match {
  cursor: default;
}

.tribute-container .menu-highlighted {
  font-weight: bold;
}

