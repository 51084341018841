@import 'src/lib/styles/fivef/mixins/button';
@import 'src/lib/styles/fivef/mixins/menu';
@import 'src/lib/styles/fivef/mixins/truncate';

.fivef-item-selector {
  overflow: hidden;

  &--button-wrapper {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
  }

  &--button-title {
    display: inline-block;
    color: var(--fivef-color-text-primary);
    @include fivef-text-truncate();

    &:hover {
      color: var(--fivef-color-action);
    }
  }

  &--button-inverted {
    @include fivef-button-inverted();
  }

  &--menu {
    height: 360px;

    // Give this menu more width for showing names.
    width: 320px;

    @include fivef-menu();

    &--search {
      height: 32px;
    }

    &--listing {
      margin: 0;
      padding-right: var(--fivef-spacing-xs);
    }

    &--list-item {
      display: flex;
      flex: 1 1 100%;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      width: 100%;
      overflow: hidden;

      @include fivef-text-truncate();

      &--message {
      }

      &--action {
        padding: 0 0 0 var(--fivef-spacing-xs);
      }

      &--removable,
      &--selectable {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 var(--fivef-spacing-xs);

        @include fivef-text-truncate();
      }

      &--client {
        display: flex;
        justify-content: flex-start;
        @include fivef-text-truncate();

        > .fivef-icon {
          margin-right: var(--fivef-spacing-s);
        }

        &--title {
          font-weight: 400;
          margin-bottom: 0;
          display: flex;
          flex-direction: column;

          @include fivef-text-truncate();
        }

        &--name {
          margin-bottom: 0;
          display: block;

          @include fivef-text-truncate();
        }

        &--number {
          display: block;
          font-size: var(--fivef-font-size-xs);
          margin-bottom: 0;
          font-weight: 300;

          @include fivef-text-truncate();
        }
      }
    }
  }
}
