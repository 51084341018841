/**
 * Too generic classname.
 * TODO: Discover where used then remove.
 */
.content {
  max-width: 960px;
  margin: 0 auto;

  @include mq-sm-up {
    padding-top: 80px;
  }

  @include mq-md-up {
    padding-top: 50px;
  }

  @media (min-width: 1441px) {
    padding-left: 350px;
    padding-top: 0;
    max-width: calc(960px + 400px);
  }
}
