/**
 * Smaller suffix icon or icon button.
 * 1. resize of icon itself.
 * 2. Button resize including the touch target (ripple, active touch size).
 */
.fivef-suffix-button.mat-icon,
.fivef-suffix-button mat-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mat-mdc-icon-button.fivef-suffix-button,
.mat-mdc-icon-button.fivef-suffix-button .mat-mdc-button-touch-target {
  width: 22px;
  height: 22px;
  font-size: 18px;
  padding: 0;
}
