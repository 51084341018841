@import "src/lib/styles/base/mixins";
@import "src/lib/styles/fivef/mixins/action_footer";

/**
 * Mat dialog normalization.
 */
.mat-mdc-dialog-surface,
.mat-mdc-dialog-container,
.mat-mdc-dialog-container .mdc-dialog__surface,
.mat-mdc-dialog-surface .mdc-dialog__surface {
  // Note if the value is set, then the animation of mat-dialog will show a white background and rendering
  // on top the real dialog.
  border-radius: unset !important; // var(--fivef-border-radius-m) !important;
  background-color: unset !important; // var(--fivef-color-surface-primary) !important;
}

.fivef-dialog {
  display: flex;
  flex-direction: column;
  min-width: 580px;
  max-width: 100%;
  max-height: 80vh;
  height: auto;
  overflow: hidden;

  // Switch min-width on small screens.
  @media (max-width: 600px) {
    min-width: 280px;
  }


  border-radius: var(--fivef-border-radius-l);
  border-left: 10px solid var(--fivef-color-surface-primary);

  // New attempt with more space: left increased to l, h3 -> h2, border radius -> l.
  padding: var(--fivef-spacing-m) var(--fivef-spacing-l) var(--fivef-spacing-xs) var(--fivef-spacing-l);

  &-no-padding {
    padding: 0;
    border-left-width: unset;
    border-left-style: unset;
  }

  &--fullscreen {
    width: 100vw;
    height:  100vh;
    max-width: 100vw;
    max-height: 100vh;
  }

  background-color: var(--fivef-color-surface-primary);
  // Taken from mat dialog.
  box-shadow: var(--fivef-box-shadow-m);

  &--header {
    display: flex;
    justify-content: space-between;
    // Header content padding.
    padding-bottom: var(--fivef-spacing-l);

    &-title {
      display: flex;
      flex-direction: column;
    }

    &--subtitle {
      display: block;
      font-size: var(--fivef-font-size-s);
    }
  }

  &--content {
    // Basis content styling. Mainly taken form mat-dialog-content
    // class.
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;
    max-width: 100%;
    height: 100%;
    pointer-events: auto;
    overflow: auto;
    overflow-x: hidden;
    // minimum padding should be added to prevent unnecessary scrollbar
    padding: 6px;
    outline: 0;
    transition: height 0.15s ease-out;
  }

  &--footer {
    @include fivef-action-footer();
  }
}

/**
 * Utility dialog with less size.
 * Used at folder creation, deletion and renaming.
 *
 * Context: Minimal information (e.g. header is also question).
 */
.fivef-dialog.fivef-dialog--utility-dialog {
  max-width: 500px;
  min-width: unset;
}
