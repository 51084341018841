.fivef-carousel {
  &--inner {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  &--container {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 83vh;
    min-width: 100%;
    flex-direction: column;
    object-fit: contain;
  }

  &--carousel-control {
    .mat-icon {
      font-size: 40px;
    }

    /**
     * Next/previous links. Unused in this style.
     * &--previous, &--next {}
     */
  }

  /**
   * Carousel indicator dots.
   * Example taken from 5F default at override/bootstrap_carousel.
   *
   * &--carousel-indicators {
   *   li {
   *     max-width: 7px;
   *     height: 7px;
   *   }
   * }
   */
}
