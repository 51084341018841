@import 'src/lib/styles/fivef/mixins/menu';

.fivef-seenby {
  &--button {
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;
    border: none;
    background-color: transparent;

    color: var(--fivef-color-text-tertiary);

    .mat-icon {
      font-size: 1.4rem;
      height: 1.4rem;
      width: 1.4rem;
    }
  }

  &--button--active .mat-icon {
    color: var(--fivef-color-seenby);
    cursor: pointer;
  }

  &--button:hover {
    color: var(--fivef-color-surface-brand);
  }

  &--button--title {
    font-size: var(--fivef-font-size-xs);
    color: var(--fivef-color-text-tertiary);
    margin-left: var(--fivef-spacing-xxs);
  }

  &--menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include fivef-menu;
    gap: var(--fivef-spacing-s);
  }
}
