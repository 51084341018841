/**
 * Task container for scoped tasks.
 * Examples: Collecto, CAC.
 * The container has a simple ul/li based layout with a content editable
 * for fast task creation.
 *
 * Used at sidebars.
 */
.fivef-node-tasks {
  display: block;
  width: 100%;

  h3 {
    margin-top: var(--fivef-spacing-l);
    margin-bottom: var(--fivef-spacing-m);
  }

  /**
   * Adhoc input select. Used at tasks listing at the bottom and node sidebar tasks listing.
   */
  &--create-section {
    display: flex;
    align-items: center;

    // fixed height as placeholder in ngViewport context.
    height: 32px;
  }

  /**
   * Selector of standard or approval task.
   */
  &--type-selector {
    max-width: 130px;
    // Keep spacing to input on right and small indentation on left for nicer
    // appearance in listing.
    margin: 0 var(--fivef-spacing-xs) 0 var(--fivef-spacing-xxs);

    // Removes the new material pseudo checkbox.
    .mat-pseudo-checkbox {
      display: none;
    }
  }

  /**
   * Content editable for task creation.
   */
  &--task-input {
    max-width: 400px;
  }

  /**
   * Top right task call to action.
   */
  &--action-button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--fivef-spacing-m);
  }

  /**
   * Task listing.
   */
  &--container {
    ul {
      li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 2px;
        position: relative;
        border-left: 3px solid
      }

      li.fivef-node-tasks--closed {
        border: 1px solid var(--fivef-color-success);
        border-left: 3px solid var(--fivef-color-success);
      }
    }

    /**
     * Column setup.
     */
    .fivef-node-tasks {
      &--icon {
        color: var(--fivef-color-text-secondary);
      }

      &--columns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 420px;
        height: 40px;
      }

      &--status {
        width: 150px;
        height: 100%;
      }

      &--priority {
        width: 150px;
      }

      &--actions {
        width: 120px;
      }

      &--task-type {
        width: 100px;
        margin-right: 1rem;
      }
    }
  }
}
