@import 'src/lib/styles/fivef/mixins/truncate';

/**
 * Global table base styling.
 */
table.fivef-table {
  width: 100%;
  border-collapse: collapse;
}

.fivef-table {
  width: 100%;

  .cdk-column {
    // Status cell of process listing.
    // Filling without padding.
    &-status,
    &-priority {
      padding: 0;
    }
  }

  table, thead, th {
    background-color: transparent;
    @include fivef-text-truncate;
  }

  th, tr {
    @include fivef-text-truncate;
  }

  tbody tr,
  .cdk-row {
    background-color: var(--fivef-color-surface-primary);

    // Row hover effect.
    &.active,
    &:hover {
      background-color: var(--fivef-color-surface-primary-highlight);
      transition: background-color 0.1s ease-in-out;

      td,
      .cdk-cell {
        background-color: var(--fivef-color-surface-primary-highlight);
      }
    }
  }
}
