.fivef-process-path-breadcrumps {
  display: block;
  max-width: 100%;
  overflow: hidden;
}

.fivef-process-path-breadcrumps {
  &--wrapper {
    display: flex;
    font-size: 12px;
    line-height: 12px;
    padding: 0;

    max-width: 100%;
    overflow: hidden;
  }

  &--project-structure-icon {
    color: var(--fivef-process-path-breadcrumps-structure-icon-color);
    height: 18px !important;
    width: 18px !important;
    margin-right: 10px;
    margin-left: 2px;
    margin-bottom: 3px;
  }

  a {
    padding: 0 5px 0 0;
    display: block;
    color: var(--fivef-process-path-breadcrumps-color);
    text-decoration: var(--fivef-process-path-breadcrumps-text-decoration);
    white-space: nowrap;
    align-items: center;
    font-size: 12px;
    line-height: 12px;

    mat-icon {
      color: var(--fivef-process-path-breadcrumps-icon-color);
      font-size: 14px;
      width: 15px;
      height: 16px;
      vertical-align: middle;
    }
  }

  a:hover {
    color: var(--fivef-process-path-breadcrumps-color-hover);
    text-decoration: none;

    mat-icon {
      color: var(--fivef-process-path-breadcrumps-icon-color-hover);
    }
  }

  &--breadcrump-container {
    //width: -moz-calc(100% - 80px);
    //width: -webkit-calc(100% - 80px);
    //width: -o-calc(100% - 80px);
    //width: calc(100% - 80px);
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    a {
      flex-shrink: 3;
      text-decoration: var(--fivef-process-path-breadcrumps-text-decoration);
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
      font-size: 14px;
      line-height: 14px;

      mat-icon {
        color: var(--fivef-process-path-breadcrumps-icon-color);
        font-size: 16px;
        width: 16px;
        height: 16px;
        line-height: 16px;
        margin-bottom: 3px;
        vertical-align: middle;
      }
    }

    a + a:before {
      color: var(--fivef-process-path-breadcrumps-color);
      content: "\025B8";
      padding-right: 6px;
      position: relative;
      top: -1px;
    }

    a:hover {
      color: var(--fivef-process-path-breadcrumps-color-hover);
      text-decoration: var(--fivef-process-path-breadcrumps-text-decoration-hover);
      overflow: visible;

      mat-icon {
        // color: var(--fivef-process-path-breadcrumps-icon-color-hover);
        color: unset;
      }
    }

    // Kept for later, currently unused.
    a.current {
      // color: $dvtx-action-blue;
      font-weight: 500;
      overflow: visible;

      mat-icon {
        color: var(--fivef-process-path-breadcrumps-icon-color-current);
      }
    }
  }
}

/**
 * Grouping header of timeline.
 */
.fivef-sticky-header-grouping {
  display: flex;
  align-items: center;
  // justify-content: center;
  overflow: hidden;
  margin-top: 0.5rem;
  background-color: rgb(245 245 245 / 90%);

  &--data {
    margin: 0 1rem;
    font-weight: 500;

    background-color: var(--fivef-process-path-breadcrumps-bg-color);
    padding: 1px 10px;
    border-radius: 10px;
  }

  hr {
    border-width: 2px;
    border-color: var(--fivef-process-path-breadcrumps-hr-color);
    min-width: 0;
    flex: 1 0 50%;
    margin: 0;

    &.dvtx-leading-line {
      flex: 0 0 39px;
    }
  }
}

.fivef-date-sticky-header-date-event {
  position:sticky;
  top: 0;
  z-index: 999;

  .fivef-sticky-header-grouping {
    padding-top: 3px;
  }
}
