.fivef-cac-entity-creation-dialog {
  &--row {
    display: flex;
    gap: var(--fivef-spacing-m);
    margin-bottom: var(--fivef-spacing-m);

    > div {
      flex-direction: column;
    }
  }

  &--color-select {
    display: flex;
    width: 44px;
    flex-direction: column;
  }

  &--country-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: var(--fivef-spacing-m);
  }

  &--country {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
