.fivef-artifact-listing {
  width: 100%;
  overflow: hidden;
  display: block;
}

.fivef-artifact-listing--cdk-virtual-scroll {
  // Disables scrolling if mouse is on top of container.
  // Moved to tag directly depending on member loading.
  // overscroll-behavior-y: contain;

  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
  }
}
