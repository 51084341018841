/**
 * Resource indicator counts of BOM nodes, e.g. at the Collecto listing.
 */
.fivef-node-resource-indicator {
  &--counter {
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    position: absolute;
    bottom: 6px;
    right: 4px;
    height: 10px;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    background: var(--fivef-indicator-background-color);
    color: var(--fivef-indicator-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &--unread-counter {
    font-size: 10px;
    line-height: 1;
    white-space: nowrap;
    position: absolute;
    bottom: 6px;
    right: 4px;
    height: 10px;
    min-width: 10px;
    padding: 2px;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--fivef-indicator-color-unread);
    background-color: var(--fivef-indicator-background-color-unread);
    display: inline-block;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
  }
}
