.fivef-card {
  display: flex;
  flex-direction: column;
  border-radius: var(--fivef-border-radius-m);
  box-shadow: var(--fivef-box-shadow-s);
  border: 1px solid var(--fivef-color-card-border);
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--fivef-color-text-primary);
  background-color: var(--fivef-color-card-background);

  // Left border color of card.
  // Border coloring like for projects is independent of
  // the used card theme.
  &--color {
    border-left-width: 5px;
    border-left-style: solid;
  }

  &--header {
    padding: var(--fivef-spacing-m);
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--fivef-color-card-divider);
  }

  &--icon-title {
    display: flex;
    align-items: center;
    gap: var(--fivef-spacing-s);
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--title {
    font: var(--fivef-font-heading-s);
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  &--icon {
    vertical-align: center;
  }

  &--content {
    padding: var(--fivef-spacing-m);
  }

  &--actions {
    display: flex;
  }
}
