@import "src/lib/styles/fivef/mixins/disable_input_hints_wrapper";

.fivef-tfa-factor-verification {
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    margin-bottom: var(--fivef-spacing-s);
  }

  &--preface {
    text-align: center;
  }

  &--input-container {
    display: flex;
    flex-flow: row nowrap;
  }

  // Wrapper around keeps space between code fields
  // and removes inner paddings.
  &--input-field {
    width: 25px !important;
    padding: 0 !important;
    margin: 0 var(--fivef-spacing-xs);
    border-radius: 5px;
    text-transform: uppercase;
    border: 1px solid var(--fivef-color-input-background-focus);

    // Removes the default paddings that moves the content out of sight.
    // Align content center. Paddings serve for views that do not support
    // centered text inside input.
    .mdc-text-field {
      font-size: 18px;
      padding: 3px 5px !important;

      input {
        text-align: center;
      }
    }

    @include fivef-disable-input-hints-wrapper();
  }
}
