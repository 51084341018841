/**
 * Dialog overrides in the context of the folder dialog.
 * Goal: Sticky toolbar, overflow scrolling at the tree.
 *       Corrected paddings to make the dialog dense (no header bottom spacing).
 */
.fivef-folder-tree-dialog--container {
  height: 600px;
  max-height: 70vh;

  .fivef-dialog-header {
    padding-bottom: 0;
  }

  .fivef-toolbar {
    flex-grow: 0;
  }

  .fivef-folder-tree {
    flex-grow: 1;
  }

  // Scrollable content with simple block -> overflow setup.
  .fivef-dialog--content {
    display: block;
    overflow: hidden;

    .fivef-tree {
      display: block;
      // Reduce the toolbar from the tree container for scrolling and visibility of last item.
      height: calc(100% - 40px);
      overflow: auto;
      flex-grow: 1
    }
  }
}
